<div class="row">
    <div class="offset-sm-3 col-sm-6">
        <legend>{{'add_device.new_device' | translate}}</legend>
        <hr>
        <div class="form-group">
            <label for="authenticationCode">{{'add_device.device_code' | translate}}</label>
            <input id="authenticationCode" type="text" class="form-control" [(ngModel)]="authenticationCode">
            <small id="emailHelp" class="form-text text-muted">{{'add_device.device_code_help' | translate}}</small>
        </div>
        <button type="button" class="btn btn-primary" (click)="addDevice(false)">{{'add_device.add' | translate}}
            <app-spinner [loading]="loading"></app-spinner>
        </button>
        &nbsp;
        <span *ngIf="this.authService.hasRole('admin')">
            <button type="button" class="btn btn-primary" (click)="addDevice(true)">Admin mode add device
                <app-spinner [loading]="loading"></app-spinner>
            </button>
        </span>
    </div>
</div>
