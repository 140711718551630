export class Message {
    public type: string
    public text: string
    public created: Date

    public constructor(type: string, text: string, created: Date) {
        this.type = type
        this.text = text
        this.created = created
    }
}
