import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, Inject } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Location, DatePipe } from '@angular/common'

import { Subscription } from "rxjs"
import { TimerObservable } from "rxjs/observable/TimerObservable"
import { BaseChartDirective } from 'ng2-charts'
import { TranslateService, LangChangeEvent } from '@ngx-translate/core'

import { AwsService } from '../../common/aws.service'
import { Chart } from './chart.model'
import { TimeRangeOption } from './time-range-option.model'
import { ThingService } from '../thing.service'
import { MessageService } from '../../messages/message.service'
import { AuthService } from '../../user/auth/auth.service'
import { LocalStorageService } from '../../local-storage/local-storage.service';
import { initTimestamp } from 'ngx-bootstrap/chronos/units/timestamp'

@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit, OnDestroy {
    @ViewChild(BaseChartDirective) chartDirective: BaseChartDirective
    @ViewChild('thingGraphTop') thingGraphTop: any
    @ViewChild('thingSensors') thingSensors: any

    // Listen route change
    private routeSubscription: Subscription
    // Listen for thing update events
    private thingSubscription: Subscription
    // Listen for language change
    private translateSubscription: Subscription

    // Selections made with url path (ngOnInit)
    public selectedThing: any
    public timeRange: TimeRangeOption

    // Chart height is controlled with some custom code
    public chartHeight: number = 1010

    // Chart view and scroll
    public liveMode: boolean
    public startMS: number
    public endMS: number
    // Date picker
    public datePickerVisible: boolean = false
    public datePickerDate: Date = new Date()
    public datePickerMaxDate: Date = new Date()

    // Spinner indicator
    public loading: boolean = false
    public showMessages: boolean = false
    // Date shown above graphs
    public currentDate: Date = new Date()
    // Time range options visible in UI, defines button label, url path parameter and time range
    public timeRangeOptions: TimeRangeOption[] = [
        //new TimeRangeOption('details.10_min', '10min', 10),
        new TimeRangeOption('details.hour2', 'hour2', 60 * 2),
        new TimeRangeOption('details.hour6', 'hour6', 60 * 6),
        new TimeRangeOption('details.hour12', 'hour12', 60 * 12),
        new TimeRangeOption('details.day', 'day', 60 * 24)
    ]

    // Messages
    public infoMessages: Array<object> = new Array()
    public infoMessages2: Array<object> = new Array()
    private messageHistoryData: any = {}
    private firstChartUpdate: boolean = true
    private chartMinTs: number = 0
    private chartLastTs: number = 0
    private oldCfg0: string = undefined
    private oldEvt0: string = undefined

    // Master data for charts
    private historyData: any = {}
    // Chart options (verbose) are hidden inside a class
    public chart: Chart = new Chart()
    // Translated labels
    private labelTranslations: any = {}
    
    public advancedMode: boolean = false
    public advancedModeEdit: boolean = false
    public normalModeEdit: boolean = false
    public eventViewMode: boolean = false
    public advancedModeWas: boolean = false
    public serviceMode: number = 0
    public lineChartLegendEnable: Map<number, boolean>

    public SEND_STATUS1_CONFIG_RECEIVED = 0x01
    public SEND_STATUS1_COMMUNICATION_TIMEOUT = 0x02
    public SEND_STATUS1_HEAT_FAULT= 0x04
    public SEND_STATUS1_SAFEMODE = 0x08
    public SEND_STATUS1_LIDTIMEOUT = 0x10
    public SEND_STATUS1_LIDPOWER = 0x20
    public SEND_STATUS1_HIGHTEMP = 0x40
    
    public SEND_STATUS2_ARMED = 0x0100
    public SEND_STATUS2_DISARMED = 0x0200
    public SEND_STATUS2_HEAT_ON = 0x0400
    public SEND_STATUS2_HEATSINK_FAN1 = 0x0800
    public SEND_STATUS2_HEATSINK_FAN2 = 0x1000
    public SEND_STATUS2_HEAT_ON_PREVENTED= 0x2000
    public SEND_STATUS2_NO_HEATERS_SELECTED	= 0x4000
    
    public SEND_STATUS3_PCB_TEMP_HIGH = 0x010000
    public SEND_STATUS3_HEATSHINK_TEMP_HIGH = 0x020000
    public SEND_STATUS3_STONE_TEMP_HIGHT = 0x040000
    public SEND_STATUS3_SET_FAN_SPEED = 0x080000
    public SEND_STATUS3_READ_FAN_SPEED = 0x100000

    public SERVICEMODE_ON = 0x01
    public SERVICEMODE_HEATER_TOGGLE = 0x02
    public SERVICEMODE_HEATER_ON = 0x03
    public SERVICEMODE_HEATER_OFF = 0x04
    public SERVICEMODE_FAN = 0x05
    public SERVICEMODE_HEATSINKFAN = 0x06
    public SERVICEMODE_CONTACTOR_ARM = 0x07
    public SERVICEMODE_CONTACTOR_DISARM = 0x08
    public SERVICEMODE_CONTACTOR_AUTO = 0x09
    public SERVICEMODE_SELF_TEST = 0x0a

    constructor(
        private awsService: AwsService,
        private thingService: ThingService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private translateService: TranslateService,
        private messageService: MessageService,
        private authService: AuthService,
        private datePipe: DatePipe,
        private localStorageService: LocalStorageService,
        @Inject('Window') private window: Window) { }

    ngOnInit() {
        console.log('details.ngOnInit things', this.thingService.things)
        
        this.advancedMode = this.authService.hasRole('adminedit') || this.authService.hasRole('adminview');
        this.advancedModeEdit = this.authService.hasRole('adminedit')
        this.eventViewMode = this.authService.hasRole('eventview')
        //this.advancedModeEdit = this.authService.hasRole('adminedit');
        //this.normalModeEdit = this.authService.hasRole('useredit');

        this.lineChartLegendEnable = new Map<number, boolean>()

        this.routeSubscription = this.route.params.subscribe(params => {
            console.log('Route param subscription triggered', params)
            this.selectedThing = this.thingService.getThingByName(params['thing'])
 
            //this.selectedThing.enhancedControls = false;
            //this.selectedThing.controls = false;

            let timeRange: string = params['timeRange']
            let matches: TimeRangeOption[] = this.timeRangeOptions.filter(option => option.param === timeRange)
            if (matches && matches.length >= 1) {
                this.timeRange = matches[0]
            } else {
                this.timeRange = this.timeRangeOptions[0]
            }

            let time: string = params['time']
            let now: number = new Date().getTime()
            this.endMS = time ? Number(time) : now
            if (this.endMS > now) {
                this.endMS = now
            }
            this.startMS = this.endMS - (this.timeRange.range * 60 * 1000)
            this.datePickerDate = new Date(this.endMS)
            this.liveMode = (time === undefined) ? true : false
            this.getHistoryData(this.selectedThing)
            if (this.eventViewMode) {
                this.getMessageHistoryData(this.selectedThing)
            }
        })

        this.thingSubscription = this.thingService.thingsObservable.subscribe(thing => {
            if (thing.name === this.selectedThing.name) {
                if (thing.stateObject && this.selectedThing.advanced_pack) {
                    if ((thing.stateObject['evt0'] && thing.stateObject['evt0'] != this.oldEvt0) || this.oldEvt0 == undefined) {
                        this.oldEvt0 = thing.stateObject['evt0']
                        if (this.eventViewMode) {
                            this.getMessageHistoryData(this.selectedThing)
                        }
                    }
                    if ((thing.stateObject['cfg0'] && thing.stateObject['cfg0'] != this.oldCfg0) || this.oldCfg0 == undefined) {
                        this.oldCfg0 = thing.stateObject['cfg0']
                        this.thingService.getThingConfiguration(thing.name)
                        .then((cfg) => {
                            if (cfg.data && cfg.data.payload) {
                                var cfgp = JSON.parse(cfg.data.payload);
                                if (cfgp.state && cfgp.state.reported) {
                                    Object.keys(cfgp.state.reported).forEach(item => {
                                        thing.stateObject[item] = cfgp.state.reported[item]
                                    });
                                }
                            }
                        })
                        .catch((error) => {
                            console.log('getThingConfiguration no permission', error)
                            this.oldCfg0 = ""
                        })
                    }
                }
                // Update thing state visible below the graphs
                this.selectedThing.stateObject = thing.stateObject
                // Update charts with latest value incrementally
                this.updateChartSingleValue(thing.stateObject, thing.updated)
            }
        })

        this.loadTranslations()

        // If language is changed, the chart labels must be manually updated
        this.translateSubscription = this.translateService.onLangChange.subscribe((event: any) => {
            this.labelTranslations['details.temperature'] = event.translations['details']['temperature']
            this.labelTranslations['details.set_temperature'] = event.translations['details']['set_temperature']
            this.labelTranslations['details.humidity'] = event.translations['details']['humidity']
            this.labelTranslations['details.index'] = event.translations['details']['index']
            this.labelTranslations['details.noise_level'] = event.translations['details']['noise_level']
            this.labelTranslations['details.roof_temperature'] = event.translations['details']['roof_temperature']
            this.labelTranslations['details.co2'] = event.translations['details']['co2']
            this.labelTranslations['details.co2short'] = event.translations['details']['co2short']
            this.labelTranslations['details.fan'] = event.translations['details']['fan']
            this.labelTranslations['details.pcb'] = event.translations['details']['pcb']
            this.labelTranslations['details.heatsink'] = event.translations['details']['heatsink']
            this.labelTranslations['details.stones'] = event.translations['details']['stones']
            this.labelTranslations['details.set_stones'] = event.translations['details']['set_stones']
            this.labelTranslations['details.heaters'] = event.translations['details']['heaters']
            this.labelTranslations['details.heateramps'] = event.translations['details']['heateramps']
            this.labelTranslations['details.cpu'] = event.translations['details']['cpu']
            this.labelTranslations['details.lidopen'] = event.translations['details']['lidopen']
            this.labelTranslations['details.chart_stove_state'] = event.translations['details']['chart_stove_state']
            this.labelTranslations['details.chart_stove_active'] = event.translations['details']['chart_stove_active']
            this.labelTranslations['details.chart_stove_heating'] = event.translations['details']['chart_stove_heating']
            // Labels in chart need to manually updated
            this.updateChart()
        })

        this.updateChartHeight(false)
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe()
        }
        if (this.thingSubscription) {
            this.thingSubscription.unsubscribe()
        }
        if (this.translateSubscription) {
            this.translateSubscription.unsubscribe()
        }
    }

    public toggleDatePicker(): void {
        this.datePickerVisible = !this.datePickerVisible
    }

    private loadTranslations(): void {
        let promises: Promise<any>[] = []
        promises.push(this.translate('details.temperature'));
        promises.push(this.translate('details.set_temperature'));
        promises.push(this.translate('details.humidity'));
        promises.push(this.translate('details.index'));
        promises.push(this.translate('details.noise_level'));
        promises.push(this.translate('details.roof_temperature'));
        promises.push(this.translate('details.co2'));
        promises.push(this.translate('details.co2short'));
        promises.push(this.translate('details.fan'));
        promises.push(this.translate('details.pcb'));
        promises.push(this.translate('details.heatsink'));
        promises.push(this.translate('details.stones'));
        promises.push(this.translate('details.set_stones'));
        promises.push(this.translate('details.heaters'));
        promises.push(this.translate('details.heateramps'));
        promises.push(this.translate('details.cpu'));
        promises.push(this.translate('details.lidopen'));
        promises.push(this.translate('details.chart_stove_state'));
        promises.push(this.translate('details.chart_stove_active'));
        promises.push(this.translate('details.chart_stove_heating'));
        Promise.all(promises).then((result) => {
            result.forEach((item) => {
                this.labelTranslations[item.key] = item.value
            })
        })
    }

    private translate(key: string, value?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            // TODO will these subscribe's leak memory because we never unsubscribe
            this.translateService.get(key, { value: value }).subscribe((result: string) => {
                resolve({ key: key, value: result })
            })
        })
    }

    public onResize(event): void {
        this.updateChartHeight(false)
    }

    private updateChartHeight(resize: boolean) {
        if (this.selectedThing.stove_pack) {
            this.chartHeight = 1010
        } else {
            this.chartHeight = 700
        }
        var height = Math.min(this.window.innerHeight, this.window.screen.height)
        if (resize) {
            height = Math.min(this.window.innerHeight, this.window.screen.width)
        }
        // console.log('window [w:' + this.window.innerWidth + ' h:' + this.window.innerHeight + '], screen [w:' + this.window.screen.width + ' h:' + this.window.screen.height + '] => ' + height)
        if (this.thingGraphTop == null)
            return;
        var topHeight = Math.max(38, this.thingGraphTop.nativeElement.clientHeight)
        //this.chartHeight = Math.max((height - 3 * topHeight), 200)
/*
        if (this.thingGraphTop) {
           var topHeight = Math.max(38, this.thingGraphTop.nativeElement.clientHeight)
           var sensorsHeight = Math.max(79, this.thingSensors.nativeElement.clientHeight)
           var extra = 100
           if (this.selectedThing.enhancedControls) {
               sensorsHeight = 0
               extra = 0
           }
           // console.log('top height: ' + topHeight + ', sensors height: ' + sensorsHeight)
           this.chartHeight = Math.max((height - topHeight - sensorsHeight - extra), 200)
        }
*/
    }

    public listThings(): any[] {
        return this.thingService.things
    }

    private getMessageHistoryData(thing: any): void {

        if (!thing) {
            return
        }

        this.awsService.getApiClient()
            .then((apiClient) => {
                let now = new Date().getTime()
                let msgStartMS = now - 2 * 24 * 60 * 60 * 1000
                let msgEndMS = now
                let params = {
                    headers: {
                        'Content-type': 'application/json'
                    },
                    device_id: thing.name,
                    start_time: msgStartMS,
                    end_time: msgEndMS
                }
                let body = {}
                let additionalParams = {}
                return apiClient.messageHistoryGet(params, body, additionalParams)
            })
            .then((result) => {
                this.infoMessages = new Array()
                this.infoMessages2 = new Array()        
                if (result.data && result.data.errorMessage) {
                    console.log('details.getMessageHistoryData ERROR', result)
                    return
                }
                this.messageHistoryData = result.data ? result.data : []
                let keys = Object.keys(this.messageHistoryData.items)
                keys.forEach((key) => {
                    let entry: any = this.messageHistoryData.items[key]
                    this.addInfoMessage(entry.msg, entry.ts, entry.dt, true)
                    this.addInfoMessage(entry.msg, entry.ts, entry.dt, false)
                })
            })
            .catch((error) => {
                console.log('details.getMessageHistoryData API ERROR', error)
            })
    }

    private getHistoryData(thing: any): void {
        if (!thing) {
            return
        }
        this.loading = true

        this.awsService.getApiClient()
            .then((apiClient) => {
                let params = {
                    headers: {
                        'Content-type': 'application/json'
                    },
                    device_id: thing.name,
                    start_time: this.startMS,
                    end_time: this.endMS
                }
                let now = new Date().getTime()
                if (this.endMS > now) {
                    console.log('endMS > now -> fix start - end values')
                    this.startMS = now - this.timeRange.range * 60 * 1000
                    this.endMS = now
                }
                let body = {
                    device_id: thing.name,
                    start_time: this.startMS,
                    end_time: this.endMS
                }
                let additionalParams = {}
                if (this.advancedMode) {
                    return apiClient.historyGet(params, body, additionalParams)
                }
                return apiClient.historyPost(params, body, additionalParams)
            })
            .then((result) => {
                if (result.data && result.data.errorMessage) {
                    console.log('details.getHistoryData ERROR', result)
                    this.loading = false
                    return
                }
                this.historyData = result.data ? result.data : []
                this.updateChart()
                this.loading = false
            })
            .catch((error) => {
                console.log('details.getHistoryData API ERROR', error)
                this.loading = false
                this.messageService.warning('details.failed_to_load_history_data')
            })
    }

    // Format date labels to chart x-axis
    private convertToLabel(date: Date): string {
        if (!date) {
            return ''
        }
        if (this.timeRange.range >= (60 * 24 * 7)) {
            return date.getDate() + '.' + (date.getMonth() + 1) + ' ' +
                ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
        } else {
            return ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
        }
    }

    private convertToLongLabel(date: Date): string {
        if (!date) {
            return ''
        }
        return date.getDate() + '.' + (date.getMonth() + 1) + '.' + (date.getFullYear()) + ' ' +
            ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2)
    }


    public chartClicked(e: any): void {
        let element = this.chartDirective.chart.getElementsAtEvent(e.event)[0]
        if (element && this.historyData && this.historyData.items) {
            let keys = Object.keys(this.historyData.items)
            let dataElement = this.historyData.items[keys[element['_index']]]
            let clickedTimestamp = dataElement.ts

            let selectTimeRangeOption = this.timeRangeOptions[0] // 1hour
            this.focusHistoryTo(clickedTimestamp, selectTimeRangeOption)
        }
    }

    public changeTimeRange(timeRangeOption: TimeRangeOption): void {
        if (this.liveMode) {
            this.router.navigate(['/details', this.selectedThing.name, timeRangeOption.param], { replaceUrl: true })
        } else {
            this.focusHistoryTo(this.endMS, timeRangeOption)
        }
    }

    public onDateChanged(e: any): void {
        let selectedDate = e
        let now = new Date()
        if (selectedDate.getTime() > now.getTime()) {
            selectedDate = now
        }

        // Keep current time range selection
        this.focusHistoryTo(selectedDate.getTime(), this.timeRange)
    }

    private focusHistoryTo(time: number, timeRange: TimeRangeOption): void {
        let params: any = {
            replaceUrl: true
        }
        this.router.navigate(['/details', this.selectedThing.name, timeRange.param, time], params)
    }

    public chartHovered(e: any): void {
        // console.log(e)
    }

    public chartHasData(): boolean {
        return this.chart
            && this.chart.lineChartData.length > 0
            && this.chart.lineChartData[0]
            && this.chart.lineChartData[0].data.length > 1
    }

    private interpolate(newVal : any, prevVal : any, round : number, rounds : number): number {
        if (rounds <= 1 || newVal == prevVal) {
            return newVal
        }
        return ((newVal - prevVal) / rounds) * round + prevVal
    }

    private getStateValue(st: number): number {
        if (st >= 3) {
            st = 10;
        } else if (st >= 1) {
            st = 2;
        } else {
            st = 0;
        }
        return st
    }

    private updateChart(): void {
        let empty: Array<number> = new Array() 
        let x60: Array<number> = new Array()
        let x80: Array<number> = new Array()
        let temperature: Array<number> = new Array()
        let temperature2: Array<number> = new Array()
        let set_temperature: Array<number> = new Array()
        let humidity: Array<number> = new Array()
        let humidity2: Array<number> = new Array()
        let saunaIndex: Array<number> = new Array()
        let noiseLevel: Array<number> = new Array()
        //let roofTemperature: Array<number> = new Array()
        let stoneTemperature: Array<number> = new Array()
        let set_stoneTemperature: Array<number> = new Array()
        let co2: Array<number> = new Array()
        let pcbTemperature: Array<number> = new Array()
        let heatsinkTemperature: Array<number> = new Array()
        let cpuTemperature: Array<number> = new Array()
        let fanSpeed: Array<number> = new Array()
        let lid: Array<number> = new Array()
        let heaters: Array<number> = new Array()
        let heateramps: Array<number> = new Array()
        let state1: Array<number> = new Array()
        let state2: Array<number> = new Array()
        let labels: Array<any> = new Array()
        let longLabels: Array<any> = new Array()

        let keys = Object.keys(this.historyData.items)
        let max: number = undefined
/*
        keys.forEach((key) => {
            let entry: any = this.historyData.items[key]
            let ts = Number(entry.ts)
            let t1: number = this.toFixedNumber(entry.t1)
            let h1: number = this.toFixedNumber(entry.h1)
            let m1: number = this.toFixedNumber(entry.m1)
            let t2: number = this.toFixedNumber(entry.t2)
            let c1: number = this.toFixedNumber(entry.c1)
            temperature.push(t1)
            humidity.push(h1)
            saunaIndex.push(this.toFixedNumber((t1 + h1)))
            noiseLevel.push(m1)
            //roofTemperature.push(t2)
            co2.push(c1)
            labels.push(this.convertToLabel(new Date(Number(entry.ts))))
            longLabels.push(this.convertToLongLabel(new Date(ts)))
            let itemMax: number = Math.max(t1, h1, (t1 + h1), m1)
            if (max === undefined || itemMax > max) {
                max = itemMax
            }
        })
*/
        let prev
        let pt3 = 0
        let pt4 = 0
        let pt5 = 0
        let pt6 = 0
        let ots = 0
        let minTs = 1 * 60 * 1000
        if (this.timeRange.range <= 16 * 60) {
            minTs = 10 * 1000
        }
        if (this.timeRange.range <= 4 * 60) {
            minTs = 2 * 1000
        }
        
        this.chartMinTs = minTs
        let now = new Date().getTime()
        let lastMinTs = minTs

        let am1 = 0, ah1 = 0, ah2 = 0, at1 = 0, at2 = 0, ac1 = 0, at3 = 0
        let avgcnt = 0
        keys.forEach((key) => {
            let entry: any = this.historyData.items[key]
            let ts = Number(entry.ts)
            if (ots == 0) {
                ots = Number(ts);
                prev = entry;
            }
            let last = false;
            if (keys[keys.length-1] === key) {
                last = true
            }

            am1 += entry.m1
            ah1 += entry.h1
            ah2 += entry.h2
            at1 += entry.t1
            at2 += entry.t2
            ac1 += entry.c1
            at3 += entry.t3
            avgcnt++

            let mm1 = entry.m1

            if (ts - ots >= minTs || last) {

                am1 /= avgcnt
                ah1 /= avgcnt
                ah2 /= avgcnt
                at1 /= avgcnt
                at2 /= avgcnt
                ac1 /= avgcnt
                at3 /= avgcnt

                let rounds = Math.trunc((ts - ots) / minTs)
                if (rounds < 1) {
                    rounds = 1;
                }
                if (last) {
                    let tt1 = new Date(ts)
                    let tt2 = new Date(ots)
                    let r = ts - (ots + (rounds - 1) * minTs)
                    if (r > 0) {
                        lastMinTs = r;
                        rounds++;
                    }
                }

                for(let round = 1; round <= rounds; round++) {
                    //let m1: number = this.toFixedNumber(this.interpolate(am1, prev.m1, round, rounds))
                    let m1 = mm1
                    if (mm1 > 2) {
                        mm1 = 2
                    }
                    let h1: number = this.toFixedNumber2(this.interpolate(ah1, prev.h1, round, rounds))
                    let h2: number = this.toFixedNumber2(this.interpolate(ah2, prev.h2, round, rounds))
                    let t1: number = this.toFixedNumber2(this.interpolate(at1, prev.t1, round, rounds))
                    let t2: number = this.toFixedNumber(this.interpolate(at2, prev.t2, round, rounds))
                    let c1: number = this.toFixedNumber(this.interpolate(ac1, prev.c1, round, rounds))
                    x60.push(60.0)
                    x80.push(80.0)
                    temperature.push(t1)
                    temperature2.push(t2)
                    humidity.push(h1)
                    humidity2.push(h2)
                    saunaIndex.push(this.toFixedNumber2(t1 + h1))
                    noiseLevel.push(m1)
                    if (c1 < 100)
                        co2.push(prev.c1)
                    else
                        co2.push(c1)
                    if (this.selectedThing.advanced_pack) {
                        let st: number = this.toFixedNumber(entry.st != undefined ? entry.st : 0)
                        let stt = st % 1000;
                        //state1.push(this.toFixedNumber(st / 1000.0))
                        state1.push(stt == 1 ? 5 : 0)
                        state2.push(stt == 3 ? 5 : 0)
                        let sta: number = 0
                        if (!this.selectedThing.stove_pack) {
                            let sta: number = 0
                            if (entry.sta) {
                                sta = this.toFixedNumber(entry.sta)
                            }
                        }
                    }
                    if (this.selectedThing.stove_pack) {
                        if (at3 > 0) {
                            let t3: number = this.toFixedNumber2(this.interpolate(at3, prev.t3, round, rounds))
                            if (t3 > 0)  {
                                stoneTemperature.push(t3)
                                pt3 = t3;
                            } else {
                                stoneTemperature.push(pt3)
                            }
                        } else {
                            stoneTemperature.push(0);
                            
                        }
                    }
                    if (this.selectedThing.stove_pack && this.advancedMode) {
                        let t4: number = this.toFixedNumber(entry.t4)
                        let t5: number = this.toFixedNumber(entry.t5)
                        let t6: number = this.toFixedNumber(entry.t6)
                        let f1: number = this.toFixedNumber(entry.f1)
                        let f2: number = this.toFixedNumber(entry.f2)
                        let l0: number = this.toFixedNumber(entry.l0)
                        let ht0: number = this.toFixedNumber(entry.ht0)
                        let ht1: number = 0
                        let ht2: number = 0
                        let ht3: number = 0
                        let ht4: number = 0
                        let ht5: number = 0
                        let ht6: number = 0
                        if (entry.ht1)
                            ht1 = this.toFixedNumber(entry.ht1)
                        if (entry.ht2)
                            ht2 = this.toFixedNumber(entry.ht2)
                        if (entry.ht3)
                            ht3 = this.toFixedNumber(entry.ht3)
                        if (entry.ht4)
                            ht4 = this.toFixedNumber(entry.ht4)
                        if (entry.ht5)
                            ht5 = this.toFixedNumber(entry.ht5)
                        if (entry.ht6)
                            ht6 = this.toFixedNumber(entry.ht6)
                        let htamps = ht1 + ht2 + ht3 + ht4 + ht5 + ht6
                        if (entry.hta) {
                            htamps = this.toFixedNumber(entry.hta)
                        }
                        let stm: number = 0
                        if (entry.stm) {
                            stm = this.toFixedNumber(entry.stm)
                        }
                        let sta: number = 0
                        if (entry.sta) {
                            sta = this.toFixedNumber(entry.sta)
                        }
                        if (t4 > -50)  {
                            pcbTemperature.push(t4)
                            pt4 = t4;
                        } else {
                            pcbTemperature.push(pt4)
                        }
                        if (t5 > -50)  {
                            heatsinkTemperature.push(t5)
                            pt5 = t5;
                        } else {
                            heatsinkTemperature.push(pt5)
                        }
                        if (t6 > -50)  {
                            cpuTemperature.push(t6)
                            pt6 = t6;
                        } else {
                            cpuTemperature.push(pt6)
                        }
                        fanSpeed.push(f1)
                        lid.push(l0)
                        heaters.push(ht0)
                        heateramps.push(htamps / 10)
                        set_temperature.push(sta)
                        set_stoneTemperature.push(stm)
                    }
                    this.chartLastTs = ots
                    let date = new Date(ots)
                    labels.push(this.convertToLabel(date))
                    longLabels.push(this.convertToLongLabel(date))
                    let itemMax: number = Math.max(t1, h1, (t1 + h1), m1)
                    if (max === undefined || itemMax > max) {
                        max = itemMax
                    }
                    if (round + 1 == rounds) {
                        ots += lastMinTs
                    } else {
                        ots += minTs
                    }
                }
                prev = entry;
                avgcnt = 0
                am1 = 0
                ah1 = 0
                at1 = 0
                ac1 = 0
                at3 = 0
            }
        })

        let tension = 0.01
        let oldChart = this.chartDirective;
        let advanced = this.advancedMode
        this.lineChartLegendEnable['m1'] = this.getLegendState(oldChart, this.labelTranslations['details.noise_level'], true, advanced)
        this.lineChartLegendEnable['h1'] = this.getLegendState(oldChart, this.labelTranslations['details.humidity'], false, advanced)
        this.lineChartLegendEnable['t1'] = this.getLegendState(oldChart, this.labelTranslations['details.temperature'], false, advanced)
        this.lineChartLegendEnable['c1'] = this.getLegendState(oldChart, this.labelTranslations['details.co2'], false, advanced)
        this.lineChartLegendEnable['ix'] = this.getLegendState(oldChart, this.labelTranslations['details.index'], false, advanced)
        if (this.selectedThing.advanced_pack) {
            this.lineChartLegendEnable['st1'] = this.getLegendState(oldChart, "State1", false, advanced)
            this.lineChartLegendEnable['st2'] = this.getLegendState(oldChart, "State2", false, advanced)
            this.lineChartLegendEnable['h2'] = this.getLegendState(oldChart, this.labelTranslations['details.humidity'], false, advanced)
        }
        if (this.selectedThing.stove_pack) {
            this.lineChartLegendEnable['sta'] = this.getLegendState(oldChart, this.labelTranslations['details.set_temperature'], false, advanced)
        }
        this.lineChartLegendEnable['t2'] = this.getLegendState(oldChart, this.labelTranslations['details.roof_temperature'], false, advanced)
        if (this.selectedThing.stove_pack) {
            this.lineChartLegendEnable['t3'] = this.getLegendState(oldChart, this.labelTranslations['details.stones'], false, advanced)
            this.lineChartLegendEnable['stm'] = this.getLegendState(oldChart, this.labelTranslations['details.set_stones'], false, advanced)
        }
        if (this.selectedThing.stove_pack && advanced) {
            this.lineChartLegendEnable['t4'] = this.getLegendState(oldChart, this.labelTranslations['details.pcb'], false, advanced)
            this.lineChartLegendEnable['t5'] = this.getLegendState(oldChart, this.labelTranslations['details.heatsink'], false, advanced)
            this.lineChartLegendEnable['f1'] = this.getLegendState(oldChart, this.labelTranslations['details.fan'], false, advanced)
            this.lineChartLegendEnable['l0'] = this.getLegendState(oldChart, this.labelTranslations['details.lid'], false, advanced)
            this.lineChartLegendEnable['ht0'] = this.getLegendState(oldChart, this.labelTranslations['details.heaters'], false, advanced)
        }
        if (this.selectedThing.advanced_pack && advanced) {
            this.lineChartLegendEnable['t6'] = this.getLegendState(oldChart, this.labelTranslations['details.cpu'], false, advanced)
        }

        let chartData = new Array()
        chartData.push({ yAxisID: 'A', sensor: 'x60', data: x60, label: "", borderWidth: 2, tension: tension, pointRadius: 4, pointHoverRadius: 4, hidden: false, fill: false })
        chartData.push({ yAxisID: 'A', sensor: 'x80', data: x80, label: "", borderWidth: 2, tension: tension, pointRadius: 4, pointHoverRadius: 4, hidden: false, fill: false })

        chartData.push({ yAxisID: 'A', sensor: 'm1', data: noiseLevel, label: this.labelTranslations['details.noise_level'], borderWidth: 1, tension: tension, pointRadius: 4, pointHoverRadius: 4, hidden: this.lineChartLegendEnable['m1'], fill: false })
        chartData.push({ yAxisID: 'A', sensor: 'h1', data: humidity, label: this.labelTranslations['details.humidity'], borderWidth: 1, tension: tension, pointRadius: 4, pointHoverRadius: 4, hidden: this.lineChartLegendEnable['h1'], fill: true })
        chartData.push({ yAxisID: 'A', sensor: 't1', data: temperature, label: this.labelTranslations['details.temperature'], borderWidth: 1, tension: tension, pointRadius: 4, pointHoverRadius: 4,  hidden: this.lineChartLegendEnable['t1'], fill: true })
        if (this.hasSensorValue('c1')) {
            chartData.push({ yAxisID: 'D', sensor: 'c1', data: co2, label: this.labelTranslations['details.co2short'], borderWidth: 1, tension: tension, pointRadius: 4, pointHoverRadius: 4,  hidden: this.lineChartLegendEnable['c1'], fill: false })
        } else {
            chartData.push({ yAxisID: 'D', sensor: 'c1', data: empty, hidden: true })
        }
        chartData.push({ yAxisID: 'A', sensor: 'ix', data: saunaIndex, label: this.labelTranslations['details.index'], borderWidth: 1, lineTension: 0,tension: tension, pointStyle: 'line', pointRadius: 4, pointHoverRadius: 4, hidden: this.lineChartLegendEnable['ix'], fill: true })
        chartData.push({ yAxisID: 'E', sensor: 'st1', data: state1, label: "", borderWidth: 1, tension: tension, pointStyle: 'line', pointRadius: 4, pointHoverRadius: 4, hidden: false, fill: true })
        chartData.push({ yAxisID: 'F', sensor: 'st2', data: state2, label: "", borderWidth: 1, tension: tension, pointStyle: 'line', pointRadius: 4, pointHoverRadius: 4, hidden: false, fill: true })
        if (this.selectedThing.stove_pack && advanced) {
            chartData.push({ yAxisID: 'G', sensor: 'sta', data: set_temperature, label: this.labelTranslations['details.set_temperature'], borderWidth: 3, tension: tension, pointRadius: 4, pointHoverRadius: 4, hidden: this.lineChartLegendEnable['sta'], fill: false })
        }
/*
        if (!this.selectedThing.advanced_pack)  {
            chartData.push({ yAxisID: 'A', sensor: 't2', data: temperature2, label: this.labelTranslations['details.temperature'], borderWidth: 1, tension: 1, pointRadius: 4, pointHoverRadius: 4, hidden: this.lineChartLegendEnable['t2'], fill: true })
        }
*/
        if (this.selectedThing.stove_pack) {
            chartData.push({ yAxisID: 'B', sensor: 't3', data: stoneTemperature, label: this.labelTranslations['details.stones'], borderWidth: 4, tension: tension, pointRadius: 4, pointHoverRadius: 4, hidden: this.lineChartLegendEnable['t3'], fill: false })
        }
        if (this.selectedThing.stove_pack && advanced) {
            chartData.push({ yAxisID: 'H', sensor: 'stm', data: set_stoneTemperature, label: this.labelTranslations['details.set_stones'], borderWidth: 3, tension: 0, pointRadius: 4, pointHoverRadius: 4, hidden: this.lineChartLegendEnable['stm'], fill: false })
            chartData.push({ yAxisID: 'A', sensor: 't4', data: pcbTemperature, label: this.labelTranslations['details.pcb'], borderWidth: 1, tension: tension, pointRadius: 4, pointHoverRadius: 4, hidden: this.lineChartLegendEnable['t4'], fill: false })
            chartData.push({ yAxisID: 'A', sensor: 't5', data: heatsinkTemperature, label: this.labelTranslations['details.heatsink'], borderWidth: 1, tension: tension, pointRadius: 4, pointHoverRadius: 4, hidden: this.lineChartLegendEnable['t5'], fill: false })
            chartData.push({ yAxisID: 'G', sensor: 'f1', data: fanSpeed, label: this.labelTranslations['details.fan'], borderWidth: 1, tension: 0, pointRadius: 4, pointHoverRadius: 4, hidden: this.lineChartLegendEnable['f1'], fill: false })
            chartData.push({ yAxisID: 'G', sensor: 'l0', data: lid, label: this.labelTranslations['details.lidopen'], borderWidth: 1, tension: 0, pointRadius: 4, pointHoverRadius: 4, hidden: this.lineChartLegendEnable['l0'], fill: false })
            chartData.push({ yAxisID: 'G', sensor: 'ht0', data: heaters, label: this.labelTranslations['details.heaters'], borderWidth: 1, tension: 0, pointRadius: 4, pointHoverRadius: 4, hidden: this.lineChartLegendEnable['ht0'], fill: false })
            chartData.push({ yAxisID: 'G', sensor: 'hta', data: heateramps, label: this.labelTranslations['details.heateramps'], borderWidth: 1, tension: 0, pointRadius: 4, pointHoverRadius: 4, hidden: this.lineChartLegendEnable['hta'], fill: false })
        }
        if (this.selectedThing.advanced_pack && advanced) {
            chartData.push({ yAxisID: 'A', sensor: 't6', data: cpuTemperature, label: this.labelTranslations['details.cpu'], borderWidth: 1, tension: tension, pointRadius: 4, pointHoverRadius: 4,  hidden: this.lineChartLegendEnable['t6'], fill: false })
        }
        chartData.push({ yAxisID: 'C', data: longLabels, borderWidth: 1, tension: 0, pointRadius: 4, pointHoverRadius: 4, display: false, hidden: true, fill: false })

        // hide stove temperature axis if not stove_pack
        Object.keys(this.chart.lineChartOptions.scales.yAxes).forEach((axis) => {
            let ax = this.chart.lineChartOptions.scales.yAxes[axis]
            if (ax.id == "B") {
                ax.display = this.selectedThing.stove_pack
            }
        })
        
        this.chart.stateText = this.labelTranslations['details.chart_stove_state']
        this.chart.activeText = this.labelTranslations['details.chart_stove_active']
        this.chart.heatingText = this.labelTranslations['details.chart_stove_heating']

        // Update to UI
        this.chart.lineChartLabels = labels
        this.chart.lineChartData = chartData

        // Hack to fix labels not updating
        if (this.chartDirective && this.chartDirective.chart) {
            this.updateChartScale(max)
        } else {
            // If chart directive is undefined, try to run update after timeout
            setTimeout(() => {
                this.updateChartScale(max)
            }, 500)
        }

        this.updateChartHeight(false)
    }

    private getLegendState(oldChart: any, name: string, def : boolean, advancedMode : boolean): boolean
    {
        let key = "legend_" + name + "_" + (advancedMode ? "1" : "0")
        let val = this.localStorageService.getItem(key)
        if (oldChart && oldChart.chart) {
            let item = oldChart.chart.legend.legendItems.find(v => v.text === name)
            if (item) {
                var newVal = item.hidden ? "0" : "1"
                if (newVal != val) {
                    this.localStorageService.setItem(key, newVal)
                }
                return item.hidden
            }
        }
        if (val) {
            return val === "1" ? false : true
        }
        return def
    }

    private addInfoMessage(msg: string, ts: string, dt: string, list: boolean)
    {
        if (msg == null || msg == "")
            return;
        let tsInt = Number(ts)
        let dtInt = Number(dt) * 1000
        //if (dtInt > 0) {
        //    tsInt = dtInt
        //}
        let id = ts + msg
        let msgArr = msg.split('|')
        let tsText = this.datePipe.transform(tsInt, 'dd.MM.yyyy HH:mm:ss')
        let tsText2 = this.datePipe.transform(dtInt, 'dd.MM.yyyy HH:mm:ss')
        let type = msgArr[0]
        let bgcolor = "#ffffff"
        if (type == "ERROR")
            bgcolor = "#ff0000"
        else if (type == "WARN")
            bgcolor = "#ffff00"
        else if (type == "OK")
            bgcolor = "#00ff00"
        var newMsg = {id: id, date: tsText, msg: msgArr[1], bgcolor: bgcolor, type: msgArr[0]}
        if (list) {
            this.infoMessages.unshift(newMsg);
        } else {
            if (this.infoMessages2.length >= 3) {
                this.infoMessages2.pop();
            }
            this.infoMessages2.unshift(newMsg);
        }
    }

    private updateChartScale(max: number): void {
        if (this.chartDirective && this.chartDirective.chart) {
            this.chartDirective.chart.config.data.labels = this.chart.lineChartLabels
            if (max > this.chartDirective.chart.config.options.scales.yAxes[0].ticks.max) {
                let ceilMax = (Math.floor(max / 20) + 1) * 20
                this.chartDirective.chart.config.options.scales.yAxes[0].ticks.max = ceilMax
                this.chartDirective.chart.config.options.scales.yAxes[1].ticks.max = ceilMax
            }
            this.chartDirective.chart.update()
        }
    }

    private toFixedNumber(value: any): number {
        return Number(Number(value).toFixed(0))
    }
    private toFixedNumber2(value: any): number {
        return Number(Number(value).toFixed(2))
    }

    private updateChartSingleValue(stateObject: any, updated: string): void {
        if (!this.historyData || !this.historyData.config) {
            // Initial history update may not be ready when this is called
            return
        }
        
        let key = stateObject.timestamp
        if (key <= this.chartLastTs) {
            return
        }

        let maxTs = 0
        let prevKey
        Object.keys(this.historyData.items).forEach((key) => {
            let item = this.historyData.items[key]
            if (item.ts > maxTs) {
                maxTs = item.ts
                prevKey = key
            }
        })
        this.chartLastTs = maxTs

        let block: any = {}
        block.t1 = Number(stateObject.t1)
        block.t2 = Number(stateObject.t2)
        block.h1 = Number(stateObject.h1)
        block.m1 = Number(stateObject.m1)
        block.c1 = Number(stateObject.c1)
        if (this.selectedThing.advanced_pack) {
            block.st = Number(stateObject.st) 
            block.h2 = Number(stateObject.h2)
            block.sta = Number(stateObject.sta)
        }
        if (this.selectedThing.stove_pack) {
            block.t3 = Number(stateObject.t3)
            block.sta = Number(stateObject.sta)
            block.stm = Number(stateObject.stm)
        }
        if (this.selectedThing.stove_pack && this.advancedMode) {
            block.t4 = Number(stateObject.t4)
            block.t5 = Number(stateObject.t5)
            block.t6 = Number(stateObject.t6)
            block.f1 = Number(stateObject.f1)
            block.f2 = Number(stateObject.f2)
            block.l0 = Number(stateObject.l0)
            block.ht0 = Number(stateObject.ht0)
            block.hta = Number(stateObject.hta)
        }
        block.ts = Number(stateObject.timestamp)
        this.historyData.items[key] = block

        // Only update realtime values in case we have not scrolled away from current time view
        if (this.liveMode) {
            let now = new Date().getTime()
            this.startMS = now - this.timeRange.range * 1000 * 60
            this.endMS = now
            this.removeOldData()
            this.updateChart()
        }        
        
        
        
        if (this.firstChartUpdate || this.chartMinTs <=0) {
            this.firstChartUpdate = false
            return
        }
/*
        let key = stateObject.timestamp
        if (key <= this.chartLastTs) {
            return
        }

        let maxTs = 0
        let prevKey
        Object.keys(this.historyData.items).forEach((key) => {
            let item = this.historyData.items[key]
            if (item.ts > maxTs) {
                maxTs = item.ts
                prevKey = key
            }
        })
        this.chartLastTs = maxTs

        let diff = key - this.chartLastTs
        let rounds = Math.trunc(diff / this.chartMinTs)
        if (rounds < 1) {
            rounds = 1
        }

        let prevt1 = this.historyData.items[prevKey].t1
        let prevt2 = this.historyData.items[prevKey].t2
        let prevt3 = this.historyData.items[prevKey].t3
        let prevt4 = 0
        let prevt5 = 0
        if (this.selectedThing.stove_pack && this.advancedMode) {
            prevt4 = this.historyData.items[prevKey].t4
            prevt5 = this.historyData.items[prevKey].t5
        }
        let nTs = this.chartLastTs

        for (var round = 0; round < rounds; round++) {
            nTs = this.chartLastTs + round * this.chartMinTs
            let block: any = {}
            block.ts = Number(stateObject.timestamp)
            block.t1 = this.toFixedNumber(this.interpolate(stateObject.t1, prevt1, round, rounds))
            block.t2 = this.toFixedNumber(this.interpolate(stateObject.t2, prevt2, round, rounds))
            block.h1 = Number(stateObject.h1)
            block.m1 = Number(stateObject.m1)
            block.c1 = Number(stateObject.m1)
            if (this.selectedThing.stove_pack) {
                block.t3 = this.toFixedNumber(this.interpolate(stateObject.t3, prevt3, round, rounds))
                block.sta2 = Number(stateObject.sta2)
                block.stm2 = Number(stateObject.stm2)
            }
            if (this.selectedThing.stove_pack && this.advancedMode) {
                block.t4 = this.toFixedNumber(this.interpolate(stateObject.t4, prevt4, round, rounds))
                block.t5 = this.toFixedNumber(this.interpolate(stateObject.t5, prevt5, round, rounds))
                block.f1 = Number(stateObject.f1)
                block.f2 = Number(stateObject.f2)
                block.l0 = Number(stateObject.l0)
                block.ht0 = Number(stateObject.ht0)
            }
            this.historyData.items[nTs] = block
            prevt1 = block.t1
            prevt2 = block.t2
            prevt3 = block.t3
            prevt4 = block.t4
            prevt5 = block.t5
            this.chartLastTs = nTs
        }
*/

        // Only update realtime values in case we have not scrolled away from current time view
        if (this.liveMode) {
            let now = new Date().getTime()
            this.startMS = now - this.timeRange.range * 1000 * 60
            this.endMS = now
            this.removeOldData()
            this.updateChart()
        }
    }

    private maxValue(currentValue: number, newValue: number): number {
        if (currentValue === undefined && newValue === undefined) {
            return 0
        }
        if (currentValue === undefined) {
            return newValue
        }
        return Math.max(currentValue, newValue)
    }

    private removeOldData(): void {
        if (!this.historyData) {
            return
        }
        let minTime = new Date().getTime() - (this.timeRange.range * 60 * 1000)
        Object.keys(this.historyData.items).forEach((key) => {
            if (Number(key) < minTime) {
                delete this.historyData.items[key]
            }
        })
    }

    // Convert to number for UI
    public toNumber(input): number {
        return Number(input)
    }

    public scrollBack(): void {
        let scrollAmount: number = (this.timeRange.range * 60 * 1000 / 2)
        let scrollTime = this.endMS - scrollAmount
        this.focusHistoryTo(scrollTime, this.timeRange)
    }

    public scrollForward(): void {
        let now: number = new Date().getTime()
        let scrollAmount: number = (this.timeRange.range * 60 * 1000 / 2)
        let scrollTime = this.endMS + scrollAmount

        if (scrollTime > now) {
            scrollTime = now
        }
        this.focusHistoryTo(scrollTime, this.timeRange)
    }

    public scrollNow(): void {
        let params: any = {
            replaceUrl: true
        }
        this.router.navigate(['/details', this.selectedThing.name, this.timeRange.param], params)
    }

    public scrollNowEnabled(): boolean {
        return !this.liveMode
    }

    public scrollForwardEnabled(): boolean {
        return !this.liveMode
    }

    public viewRange(): string {
        let startDate = new Date(this.startMS)
        let endDate = new Date(this.endMS)
        if (startDate.getFullYear() === endDate.getFullYear()
            && startDate.getMonth() === endDate.getMonth()
            && startDate.getDay() === endDate.getDay()) {
            return this.datePipe.transform(startDate, 'dd.MM.yyyy') + ' ' +
                this.datePipe.transform(startDate, 'HH:mm') + ' - ' + this.datePipe.transform(endDate, 'HH:mm')
        }
        return this.datePipe.transform(startDate, 'dd.MM.yyyy HH:mm') + '-' + this.datePipe.transform(endDate, 'dd.MM.yyyy HH:mm');
    }

    public hasSensorValue(sensor: string): boolean {
        if (!this.selectedThing || !this.selectedThing.stateObject) {
            //console.log("1")
            return false
        }
        if (!this.selectedThing.stateObject.stateObjectMetadata) {
            //console.log("3")
            return false
        }
        if (this.selectedThing.stateObject[sensor] == undefined || this.selectedThing.stateObject.stateObjectMetadata[sensor] == undefined) {
            //console.log("2")
            return false
        }
        let dt = Number(this.selectedThing.stateObject.stateObjectMetadata[sensor].timestamp) * 1000
        let date = new Date().getTime();
        let diff = date - dt;
        console.log(diff)
        if (diff < 30 * 60 * 1000)
            return true
        return false
    }

    public sensorValue(sensor: string): any {
        if (this.liveMode) {
            if (sensor == 'hr' || sensor == 'hhr') {
                let v = Number(this.selectedThing.stateObject[sensor])
                if (Number.isNaN(v) || v == undefined) {
                    return '-'
                }
                v = v / (60 * 60)
                return Number(Number(v).toFixed(2))
            } else if (sensor == 'st') {
                if (this.selectedThing.stateObject[sensor] === undefined)
                    return 0
                let v =  Number(this.selectedThing.stateObject[sensor])
                v = v % 1000
                return v
            } else if (sensor == 'fwurl' || sensor == 'tz') {
                return this.selectedThing.stateObject[sensor]
            } else if (sensor == 'ess') {
                let v = parseFloat(this.selectedThing.stateObject[sensor])
                if (isNaN(v)) {
                    return "-"
                }
                return v / 100.0
            } else if (sensor == 'kwh') {
                let v = parseFloat(this.selectedThing.stateObject[sensor])
                if (isNaN(v)) {
                    return "-"
                }
                return v
              } else if (sensor == 'lcfl4' || sensor == 'tcfl4' || sensor == 'lcfh4' || sensor == 'tcfh4') {
                let t4fan = this.selectedThing.stateObject['t4fan']
                if (t4fan === undefined) {
                    return '-'
                }
                let t4fanArr = t4fan.split(',')
                let v = -1
                if (t4fanArr.length >= 5) {
                    if (sensor == 'lcfl4') {
                        v = t4fanArr[0]
                    }
                    if (sensor == 'tcfl4') {
                        v = t4fanArr[1]
                    }
                    if (sensor == 'lcfh4') {
                        v = t4fanArr[3]
                    }
                    if (sensor == 'tcfh4') {
                        v = t4fanArr[4]
                    }
                }
                if (v < 0)
                    return '-'
                return v
            } else if (sensor === 'ix') {
                let h1 = this.selectedThing.stateObject['h1']
                let t1 = this.selectedThing.stateObject['t1']
                if (h1 === undefined || t1 === undefined) {
                    return '-'
                }
                return (Number(h1) + Number(t1)).toFixed(0)
            } else if (sensor === 'hpid') {
                if (this.selectedThing.stateObject['hpid']) {
                    return this.selectedThing.stateObject['hpid'].replace("_",",").replace("_",",")
                } else  {
                    return '-';
                }
            } else if (sensor === 'si') {
                if (this.selectedThing.stateObject['si']) {
                    return this.selectedThing.stateObject['si']
                } else  {
                    return '-';
                }
            } else if (sensor === 'l2') { // lid state
               let activeState = (this.selectedThing.stateObject['l2'] >> 4) & 3
               let currentState = (this.selectedThing.stateObject['l2'] >> 6) & 3
               if (activeState == currentState) {
                   return activeState
               }
               return currentState + 4
            } else if (sensor === 'f1') { // fan rpm
                if (this.selectedThing.stateObject['f1']) {
                   let f1 = this.selectedThing.stateObject['f1'] * 10
                    return f1
                } else {
                    return '-'
                }
            } else if (sensor === 'ht') {
                let v = this.formatSensorValue(this.selectedThing.stateObject[sensor]);
                let num = 0
                if (v & 1)
                    num++;
                if (v & 2)
                    num++;
                if (v & 4)
                    num++;
                if (v & 8)
                    num++;
                if (v & 16)
                    num++;
                if (v & 32)
                    num++;
                if (v & 64)
                    num++;
                if (v & 128)
                    num++;
                if (v & 256)
                    num++;
                return num;
            } else if (sensor === 'ht1' || sensor === 'ht2' || sensor === 'ht3' || sensor === 'ht4' || sensor === 'ht5' || sensor === 'ht6' || sensor === 'ht7' || sensor === 'ht8' || sensor === 'ht9') {
                return this.formatSensorValue(this.selectedThing.stateObject[sensor])
            } else if (sensor === 'ht1d') {
                return this.selectedThing.stateObject['htd'] && (this.selectedThing.stateObject['htd'] & 1) ? 1 : 0
            } else if (sensor === 'ht2d') {
                return this.selectedThing.stateObject['htd'] && (this.selectedThing.stateObject['htd'] & 2) ? 1 : 0
            } else if (sensor === 'ht3d') {
                return this.selectedThing.stateObject['htd'] && (this.selectedThing.stateObject['htd'] & 4) ? 1 : 0
            } else if (sensor === 'ht4d') {
                return this.selectedThing.stateObject['htd'] && (this.selectedThing.stateObject['htd'] & 8) ? 1 : 0
            } else if (sensor === 'ht5d') {
                return this.selectedThing.stateObject['htd'] && (this.selectedThing.stateObject['htd'] & 16) ? 1 : 0
            } else if (sensor === 'ht6d') {
                return this.selectedThing.stateObject['htd'] && (this.selectedThing.stateObject['htd'] & 32) ? 1 : 0
            } else if (sensor === 'ht7d') {
                return this.selectedThing.stateObject['htd'] && (this.selectedThing.stateObject['htd'] & 64) ? 1 : 0
            } else if (sensor === 'ht8d') {
                return this.selectedThing.stateObject['htd'] && (this.selectedThing.stateObject['htd'] & 128) ? 1 : 0
            } else if (sensor === 'ht9d') {
                return this.selectedThing.stateObject['htd'] && (this.selectedThing.stateObject['htd'] & 256) ? 1 : 0
            }
            if (this.selectedThing.stateObject[sensor] === undefined)
                return '-';
            return this.formatSensorValue(this.selectedThing.stateObject[sensor])
        } else {
            if (this.chart.lineChartData && this.chart.lineChartData.length > 0) {
                let sensorData = this.chart.lineChartData.find((data) => { return data.sensor === sensor })
                if (sensorData && sensorData.data && sensorData.data.length > 0) {
                    return this.formatSensorValue(sensorData.data[sensorData.data.length - 1])
                }
            }
            return '-'
        }
    }

    public setParamValue(name: string, value: string)
    {
        if (!this.selectedThing.advanced_pack || !this.advancedMode)
            return;
        let payload: any= {}
        if (this.selectedThing.parms && this.selectedThing.parms["stoveinfo"])
            payload["stoveinfo"] = this.selectedThing.parms["stoveinfo"]
        if (this.selectedThing.parms && this.selectedThing.parms["saunainfo"])
            payload["saunainfo"] = this.selectedThing.parms["saunainfo"]
        payload[name] = value
        this.thingService.updateThingParms(this.selectedThing.name, payload);
        if (!this.selectedThing.parms)
            this.selectedThing.parms = {}
        this.selectedThing.parms[name] = value
    }

    public paramValue(name: string) : string {
        if (!this.selectedThing.advanced_pack || !this.advancedMode)
            return;
        if (this.selectedThing.parms && this.selectedThing.parms[name]) {
            return this.selectedThing.parms[name]
        }
        return ""
    }

    public setSensorValueMinMax(name: string, value: string) {
        if (value == '')
            return;
        if (!this.selectedThing.advanced_pack || !this.advancedMode)
            return;
        let payload: any = {}
        let wasSet = false
        payload.state = {}
        payload.state.desired = {}
        
        if (name == 'lcfl4' || name == 'tcfl4' || name == 'lcfh4' || name == 'tcfh4' || name == 'scfl4' || name == 'scfh4') {
            let t4fan = this.selectedThing.stateObject['t4fan']
            if (t4fan === undefined) {
                t4fan = "37,42,50,40,47,100"
            }
            let t4fanArr = t4fan.split(',')
            if (name == 'lcfl4')
            {
                t4fanArr[0] = value
            }
            if (name == 'tcfl4')
            {
                t4fanArr[1] = value
            }
            if (name == 'scfl4')
            {
                t4fanArr[2] = value
            }
            if (name == 'lcfh4')
            {
                t4fanArr[3] = value
            }
            if (name == 'tcfh4')
            {
                t4fanArr[4] = value
            }
            if (name == 'scfh4')
            {
                t4fanArr[5] = value
            }
            t4fan = t4fanArr.join()
            payload.state.desired['t4fan'] = t4fan
            wasSet = true
        }

        var v = this.selectedThing.stateObject['htd']
        if (name === "hpid") {
            payload.state.desired[name] = value
            wasSet = true
        } else if (name === "si") {
            payload.state.desired[name] = value
            wasSet = true
        } else if (name === "ht1d") {
            name = "htd"
            value = (v | 1).toString()
        } else if (name === "ht2d")  {
            name = "htd"
            value = (v | 2).toString()
        } else if (name === "ht3d")  {
            name = "htd"
            value = (v | 4).toString()
        } else if (name === "ht4d")  {
            name = "htd"
            value = (v | 8).toString()
        } else if (name === "ht5d")  {
            name = "htd"
            value = (v | 16).toString()
        } else if (name === "ht6d")  {
            name = "htd"
            value = (v | 32).toString()
        } else if (name === "ht7d")  {
            name = "htd"
            value = (v | 63).toString()
        } else if (name === "ht8d")  {
            name = "htd"
            value = (v | 128).toString()
        } else if (name === "ht9d")  {
            name = "htd"
            value = (v | 256).toString()
        } else if (name === "ht1e") {
            name = "htd"
            value = (v & ~1).toString()
        } else if (name === "ht2e") {
            name = "htd"
            value = (v & ~2).toString()
        } else if (name === "ht3e") {
            name = "htd"
            value = (v & ~4).toString()
        } else if (name === "ht4e") {
            name = "htd"
            value = (v & ~8).toString()
        } else if (name === "ht5e") {
            name = "htd"
            value = (v & ~16).toString()
        } else if (name === "ht6e") {
            name = "htd"
            value = (v & ~32).toString()
        } else if (name === "ht7e") {
            name = "htd"
            value = (v & ~64).toString()
        } else if (name === "ht8e") {
            name = "htd"
            value = (v & ~128).toString()
        } else if (name === "ht9e") {
            name = "htd"
            value = (v & ~256).toString()
        } else if (name == "sys") {
            value = value + "_" + new Date().getTime()
            payload.state.desired[name] = value
            wasSet = true
        } else if (name == "fwurl") {
            payload.state.desired[name] = value
            wasSet = true
        } else if (name == "ess") {
            value = "" + (parseFloat(value) * 100)
        } else if (name == "kwh") {
            value = "" + (parseFloat(value))
            payload.state.desired[name] = value
            wasSet = true
        }

        if (!wasSet) {
            payload.state.desired[name] = parseInt(value)
        }
        console.log('SEND DESIRED', payload.state)
        this.thingService.updateThingConfiguration(this.selectedThing.name, payload);
    }

    public isHeaters() : boolean
    {
        if (!this.selectedThing || !this.selectedThing.stateObject) {
            return false
        }
        let v = this.formatSensorValue(this.selectedThing.stateObject['ht'])
        if ((v & 511) != 0 && (v & 262144) != 0)
            return true
        return false
    }

    public heaterActive(num : number) : string
    {
        if (!this.selectedThing || !this.selectedThing.stateObject) {
            return ""
        }
        num--
        let v = this.formatSensorValue(this.selectedThing.stateObject['ht'])
        if (v & (1 << num))
            return "On"
        return "Off"
    }

    public heaterDisabled(num : number) : string
    {
        if (!this.selectedThing || !this.selectedThing.stateObject) {
            return ""
        }
        num--
        num += 9
        let v = this.formatSensorValue(this.selectedThing.stateObject['ht'])
        if (!(v & (1 << num)))
            return "Disabled. Overcurrent."
        return "Ok"
    }
    
    public isContactor() : number
    {
        if (!this.selectedThing || !this.selectedThing.stateObject) {
            return 0
        }
        let m = this.selectedThing.stateObject['sm2']
        if (m & this.SEND_STATUS2_ARMED)
            return 1;
            if (m & this.SEND_STATUS2_DISARMED)
            return -1;
        return 0;
    }

    public getHeatsinkFan() : string
    {
        if (!this.selectedThing || !this.selectedThing.stateObject) {
            return "-"
        }
        return this.service_Heatsink()
    }

    public setPower(power: number, temp: number, stones: number, lidDuration: number): void {
        let payload: any = {}
        if (typeof temp === 'string')
            temp = parseInt(temp)
        if (typeof stones === 'string')
            stones = parseInt(stones)
        payload.state = {}
        payload.state.desired = {}
        //payload.state.desired['sta'] = temp
        //payload.state.desired['stm'] = stones
        //payload.state.desired['lidt'] = lidDuration
        payload.state.desired['mm'] = this.toShadowMM(power, temp, stones, lidDuration)
        this.thingService.updateThingManualControl(this.selectedThing.name, payload);
    }

    private padd(value: number): string {
        if (value < 10) {
            return '0' + value
        }
        return '' + value
    }

    private toShadowMM(power: number, temp: number, stones: number, lidDuration: number, currentDatetime?: Date): string {
        let startDatetime = currentDatetime ? currentDatetime : new Date()
        let endDatetime = new Date(startDatetime.getTime());
        endDatetime.setMinutes(endDatetime.getMinutes() + 4 * 60)

        let startDateString: string = startDatetime.getFullYear() + '-' + this.padd(startDatetime.getMonth() + 1) + '-' + this.padd(startDatetime.getDate())
        let endDateString: string = endDatetime.getFullYear() + '-' + this.padd(endDatetime.getMonth() + 1) + '-' + this.padd(endDatetime.getDate())

        let startTime: string = this.padd(startDatetime.getHours()) + ':' + this.padd(startDatetime.getMinutes())
        let endTime: string = this.padd(endDatetime.getHours()) + ':' + this.padd(endDatetime.getMinutes())

        let weekdayBinary: string = (power ? '1' : '0') + '0000000'
        let weekdayHex = parseInt(weekdayBinary, 2).toString(16).toUpperCase()
        if (weekdayHex.length < 2) {
            weekdayHex = '0' + weekdayHex
        }
        let tempHex: string = temp.toString(16).toUpperCase()
        if (tempHex.length < 2) {
            tempHex = '0' + tempHex
        }
        if (this.selectedThing.stove_pack) {
            let stonesTempHex: string = stones.toString(16).toUpperCase()
            if (stonesTempHex.length < 2) {
                stonesTempHex = '00' + stonesTempHex
            } else if (stonesTempHex.length < 3) {
                stonesTempHex = '0' + stonesTempHex
            }
            let lidDurationHex: string = lidDuration.toString(16).toUpperCase()
            if (lidDurationHex.length < 2) {
                lidDurationHex = '0' + lidDurationHex
            }
            return startDateString + '_' + endDateString + '_' + startTime + '-' + endTime + '_' + weekdayHex + '_' + tempHex + '_' + stonesTempHex + '_' + lidDurationHex
        }
        return startDateString + '_' + endDateString + '_' + startTime + '-' + endTime + '_' + weekdayHex + '_' + tempHex
    }

    private formatSensorValue(value: any): any {
        if (value === undefined) {
            return '-'
        }
        return Number(Number(value).toFixed(0))
    }

    public convertHoursMins(value: string): string {
        if (!value) return '-'
        let time = parseInt(value)
        if (Number.isNaN(time)) return '-'

        let hours: number = Math.floor((time / 60))
        let minutes: number = time - (hours * 60)
        return `${hours}h ${minutes}min`
    }

    public clickMessages(value: boolean) {
        this.showMessages = value
    }

    public userMode(toUser: boolean)
    {
        if (this.advancedMode) {
            this.advancedModeWas = true
        }
        if (toUser) {
            this.advancedMode = false
        } else if (this.advancedModeWas) {
            this.advancedMode = true;
        }
        this.getHistoryData(this.selectedThing)
    }

    public canEdit() : boolean
    {
        return this.advancedMode && this.advancedModeEdit
    }

    public canEdit2() : boolean
    {
        return this.advancedMode && this.advancedModeEdit && !this.serviceMode
    }

    public isServiceMode() : boolean
    {
        if (!this.selectedThing)
            return false;
        if (this.serviceMode)
            return true;
        if (this.selectedThing.stateObject['sm2'] & 16777216)
            return true;
        return false;
    }

    public setService(mode: number)
    {
        if (mode == 1) {
            this.serviceMode = this.SERVICEMODE_ON
        } else if (mode == 0) {
            this.serviceMode = 0
        }
        let payload: any = {}
        payload.state = {}
        payload.state.desired = {}
        payload.state.desired['sm'] = this.serviceMode
        this.thingService.updateThingConfiguration(this.selectedThing.name, payload);
    }

    public service_Contactor() : string
    {
        let m = this.selectedThing.stateObject['sm2']
        if (m & this.SEND_STATUS2_ARMED)
            return "On"
        if (m & this.SEND_STATUS2_DISARMED)
            return "Safe mode"
        return "Off (auto)"
    }
    public service_Heatsink() : string
    {
        let m = this.selectedThing.stateObject['sm2']
        if ((m & (this.SEND_STATUS2_HEATSINK_FAN1 | this.SEND_STATUS2_HEATSINK_FAN2)) == (this.SEND_STATUS2_HEATSINK_FAN1 | this.SEND_STATUS2_HEATSINK_FAN2))
            return "Max"
        if (m & this.SEND_STATUS2_HEATSINK_FAN2)
            return "High"
        if (m & this.SEND_STATUS2_HEATSINK_FAN1)
            return "Low"
        return "Off"
    }
    public service_Heaters() : string
    {
        let m = this.selectedThing.stateObject['sm2']
        var msg = ""
        if (m & this.SEND_STATUS2_HEAT_ON) {
            msg += "On"
        }
        if (m & this.SEND_STATUS2_HEAT_ON_PREVENTED) {
            if (msg != "")
                msg += ", "
            msg += "Safe mode"
        }
        if (m & this.SEND_STATUS3_READ_FAN_SPEED) {
            if (msg != "")
                msg += ", "
            msg += "Fan RPM"
        }
        if (m & this.SEND_STATUS3_SET_FAN_SPEED) {
            if (msg != "")
                msg += ", "
            msg += "Fan %"
        }
        if (m & this.SEND_STATUS2_NO_HEATERS_SELECTED) {
            if (msg != "")
                msg += ", "
            msg += "No heaters selected!"
        }
        return msg        
    }

    public setServiceHeater(num : number)
    {
        let payload: any = {}
        payload.state = {}
        payload.state.desired = {}
        payload.state.desired['sm'] = this.SERVICEMODE_HEATER_TOGGLE + 256 * num
        this.thingService.updateThingConfiguration(this.selectedThing.name, payload);
    }

    public startSelfTest(num : number)
    {
        let payload: any = {}
        payload.state = {}
        payload.state.desired = {}
        payload.state.desired['sm'] = this.SERVICEMODE_SELF_TEST + 256 * num
        this.thingService.updateThingConfiguration(this.selectedThing.name, payload);
    }

    public setServiceContactor(num : number)
    {
        let payload: any = {}
        payload.state = {}
        payload.state.desired = {}
        let mode =  this.SERVICEMODE_CONTACTOR_AUTO
        if (num == 1) {
            mode = this.SERVICEMODE_CONTACTOR_ARM
        } else if (num == 2) {
            mode = this.SERVICEMODE_CONTACTOR_DISARM
        }
        payload.state.desired['sm'] = mode
        this.thingService.updateThingConfiguration(this.selectedThing.name, payload);
    }

    public setServiceFan(num : number)
    {
        let payload: any = {}
        payload.state = {}
        payload.state.desired = {}
        payload.state.desired['sm'] = this.SERVICEMODE_FAN + 256 * num
        this.thingService.updateThingConfiguration(this.selectedThing.name, payload);
    }

    public setServiceHeatsinkFan(num : number)
    {
        let payload: any = {}
        payload.state = {}
        payload.state.desired = {}
        payload.state.desired['sm'] = this.SERVICEMODE_HEATSINKFAN + 256 * num
        this.thingService.updateThingConfiguration(this.selectedThing.name, payload);
    }

    public serviceIsHeater(num : number) : boolean
    {
        let m = this.selectedThing.stateObject['ht']
        if ((m & (1 << num)) != 0)
            return true
        return false
    }

    public serviceIsHeaterDisabled(num : number) : boolean
    {
        let m = this.selectedThing.stateObject['ht']
        num += 9
        if ((m & (1 << num)) != 0)
            return true
        return false
    }

    public hasLid() : boolean
    {
        if (this.selectedThing.stateObject['cfg']) {
            let v = this.selectedThing.stateObject['cfg']
            return (v & 1) == 1
        } else {
            return true
        }
    }
}
