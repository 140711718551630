<div class="confirm-modal" *ngIf="visible">
    <div class="confirm-modal-content">
        <div class="confirm-modal-header">
            <div class="confirm-modal-title">{{title}}</div>
        </div>
        <div class="confirm-modal-body">
            <ng-content></ng-content>
        </div>
        <div class="confirm-modal-footer clearfix">
            <button class="btn btn-primary float-right confirm-button" type="button" (click)="onYes()">{{yesText}}</button>
            <button class="btn btn-secondary float-right confirm-button" type="button" (click)="onNo()">{{noText}}</button>
        </div>
    </div>
</div>
