import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-dialog',
    inputs: ['text', 'title'],
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
    public text: string
    public title: string
    public visible: boolean = false
    constructor() { }

    ngOnInit() {
    }

    public show(): void {
        this.visible = true
    }

    public hide(): void {
        this.visible = false
    }
}
