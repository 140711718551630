import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { LoginComponent } from './user/login/login.component'
import { RegisterComponent } from './user/register/register.component'
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component'
import { ForgotPasswordVerifyComponent } from './user/forgot-password-verify/forgot-password-verify.component'
import { ProfileComponent } from './user/profile/profile.component'
import { VerifyComponent } from './user/verify/verify.component'
import { AddDeviceComponent } from './device/add-device/add-device.component'
import { DashboardComponent } from './device/dashboard/dashboard.component'
import { DevicesComponent } from './device/devices/devices.component'
import { DetailsComponent } from './device/details/details.component'
import { AdminComponent } from './admin/admin.component'
import { SchedulesComponent } from './device/schedules/schedules.component'
import { AuthGuard } from './user/auth/auth-guard.service'
import { OtaComponent } from './device/ota/ota.component';

const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'register/verify', component: VerifyComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'forgot-password/verify', component: ForgotPasswordVerifyComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'profile/verify', component: VerifyComponent },
    { path: 'add-device', component: AddDeviceComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'devices', component: DevicesComponent, canActivate: [AuthGuard] },
    { path: 'details/:thing/:timeRange/:time', component: DetailsComponent, canActivate: [AuthGuard] },
    { path: 'details/:thing/:timeRange', component: DetailsComponent, canActivate: [AuthGuard] },
    { path: 'details/:thing', component: DetailsComponent, canActivate: [AuthGuard] },
    { path: 'schedules/:thing', component: SchedulesComponent, canActivate: [AuthGuard] },
    { path: 'schedules', component: SchedulesComponent, canActivate: [AuthGuard] },
    { path: 'ota/:thing', component: OtaComponent, canActivate: [AuthGuard] },
    { path: 'ota', component: OtaComponent, canActivate: [AuthGuard] },
    { path: 'admin/:mode', component: AdminComponent, canActivate: [AuthGuard], data: { roles : ['admin']} },
    { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
