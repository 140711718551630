<div class="card">
    <div class="card-block">
        <h4 class="card-title">{{'admin.sensor_parameters.title' | translate}}
            <app-dialog title="{{'admin.sensor_parameters.help.title' | translate}}">
                <p>{{'admin.sensor_parameters.help.content' | translate}}</p>
            </app-dialog>
        </h4>
        <h6 class="card-subtitle mb-2 text-muted">{{'admin.sensor_parameters.subtitle' | translate}}</h6>

        <app-spinner [loading]="loading"></app-spinner>

        <div *ngIf="!loading && parameters" class="sensor-parameters">
            <div class="form-group row">
                <label for="time" class="col-md-8 col-form-label">{{'admin.sensor_parameters.interval' | translate}}</label>
                <div class="col-md-4" [ngClass]="{'has-danger': errors['parameters.time']}">
                    <input type="number" class="form-control" id="time" [(ngModel)]="parameters.time" name="time">
                    <div class="form-control-feedback" *ngIf="errors['parameters.time']">{{errors['parameters.time']}}</div>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-6">
                    <span><strong>{{'admin.sensor_parameters.sensor' | translate}}</strong></span>
                </div>
                <div class="col-sm-3">
                    <span><strong>{{'admin.sensor_parameters.threshold' | translate}}</strong></span>
                </div>
                <div class="col-sm-3">
                    <span><strong>{{'admin.sensor_parameters.lower_bound' | translate}}</strong></span>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-6 col-form-label">{{'admin.sensor_parameters.temperature' | translate}}</label>
                <div class="col-sm-3" [ngClass]="{'has-danger': errors['parameters.temperature']}">
                    <input class="form-control" type="number" [(ngModel)]="parameters.temperature" name="temperature">
                    <div class="form-control-feedback" *ngIf="errors['parameters.temperature']">{{errors['parameters.temperature']}}</div>
                </div>
                <div class="col-sm-3" [ngClass]="{'has-danger': errors['parameters.lowLimitTemperature']}">
                    <input class="form-control" type="number" [(ngModel)]="parameters.lowLimitTemperature" name="lowLimitTemperature">
                    <div class="form-control-feedback" *ngIf="errors['parameters.lowLimitTemperature']">{{errors['parameters.lowLimitTemperature']}}</div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-6 col-form-label">{{'admin.sensor_parameters.roof_temperature' | translate}}</label>
                <div class="col-sm-3" [ngClass]="{'has-danger': errors['parameters.roofTemperature']}">
                    <input class="form-control" type="number" [(ngModel)]="parameters.roofTemperature" name="roofTemperature">
                    <div class="form-control-feedback" *ngIf="errors['parameters.roofTemperature']">{{errors['parameters.roofTemperature']}}</div>
                </div>
                <div class="col-sm-3" [ngClass]="{'has-danger': errors['parameters.lowLimitRoofTemperature']}">
                    <input class="form-control" type="number" [(ngModel)]="parameters.lowLimitRoofTemperature" name="lowLimitRoofTemperature">
                    <div class="form-control-feedback" *ngIf="errors['parameters.lowLimitRoofTemperature']">{{errors['parameters.lowLimitRoofTemperature']}}</div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-6 col-form-label">{{'admin.sensor_parameters.humidity' | translate}}</label>
                <div class="col-sm-3" [ngClass]="{'has-danger': errors['parameters.humidity']}">
                    <input class="form-control" type="number" [(ngModel)]="parameters.humidity" name="humidity">
                    <div class="form-control-feedback" *ngIf="errors['parameters.humidity']">{{errors['parameters.humidity']}}</div>
                </div>
                <div class="col-sm-3" [ngClass]="{'has-danger': errors['parameters.lowLimitHumidity']}">
                    <input class="form-control" type="number" [(ngModel)]="parameters.lowLimitHumidity" name="lowLimitHumidity">
                    <div class="form-control-feedback" *ngIf="errors['parameters.lowLimitHumidity']">{{errors['parameters.lowLimitHumidity']}}</div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-6 col-form-label">{{'admin.sensor_parameters.co2' | translate}}</label>
                <div class="col-sm-3" [ngClass]="{'has-danger': errors['parameters.co2']}">
                    <input class="form-control" type="number" [(ngModel)]="parameters.co2" name="co2">
                    <div class="form-control-feedback" *ngIf="errors['parameters.co2']">{{errors['parameters.co2']}}</div>
                </div>
                <div class="col-sm-3" [ngClass]="{'has-danger': errors['parameters.lowLimitCo2']}">
                    <input class="form-control" type="number" [(ngModel)]="parameters.lowLimitCo2" name="lowLimitCo2">
                    <div class="form-control-feedback" *ngIf="errors['parameters.lowLimitCo2']">{{errors['parameters.lowLimitCo2']}}</div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-6 col-form-label">{{'admin.sensor_parameters.noise_level' | translate}}</label>
                <div class="col-sm-3" [ngClass]="{'has-danger': errors['parameters.noiseLevel']}">
                    <input class="form-control" type="number" [(ngModel)]="parameters.noiseLevel" name="noiseLevel">
                    <div class="form-control-feedback" *ngIf="errors['parameters.noiseLevel']">{{errors['parameters.noiseLevel']}}</div>
                </div>
                <div class="col-sm-3" [ngClass]="{'has-danger': errors['parameters.lowLimitNoiseLevel']}">
                    <input class="form-control" type="number" [(ngModel)]="parameters.lowLimitNoiseLevel" name="lowLimitNoiseLevel">
                    <div class="form-control-feedback" *ngIf="errors['parameters.lowLimitNoiseLevel']">{{errors['parameters.lowLimitNoiseLevel']}}</div>
                </div>
            </div>

            <div class="has-danger float-left" *ngIf="hasValidationErrors()">
                <div class="form-control-feedback">{{'admin.sensor_parameters.validation_error' | translate}}</div>
            </div>
            <button type="text" class="btn btn-primary float-right" (click)="update()" [disabled]="saving">
                {{'admin.sensor_parameters.update' | translate}}<app-spinner [loading]="saving"></app-spinner>
            </button>

        </div>

    </div>
</div>





