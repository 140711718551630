<div class="row dashboard">

    <div class="col-md-12" *ngIf="listThings().length === 0">
        <div class="alert alert-info">
            {{'dashboard.no_devices_attached_to_account' | translate}}
        </div>
    </div>

    <div class="row sensor-container">
        <div class="col-md-3 sensor-box" *ngFor="let thing of listThings()">
            <div class="card sensor-card">
                <div class="card-body card-block sensor-card-block">
                    <h5 class="card-title thing-title">
                        <span *ngIf="thing.controls" class="led" [ngClass]="{'led-green': deviceOn(thing) === 1, 'led-red': deviceOn(thing) === 0, 'led-gray': deviceOn(thing) === -1}"></span>
                        <app-editable-label style="display:inline-block;" [text]="thing.label" (save)="saveName($event, thing)">
                            <a href="#" [routerLink]="['/details', thing.name, 'hour2']">{{getThingName(thing)}}</a>
                        </app-editable-label>
                        <div class="thing-sub-title" *ngIf="thing.parms && thing.parms.saunainfo">{{thing.parms.saunainfo}}</div>
                        <div class="thing-sub-title" *ngIf="!thing.parms || !thing.parms.saunainfo">&nbsp;</div>
                        <div class="thing-sub-title">{{thing.thingTypeName}}</div>
                    </h5>
                    <div class="row card-text sensors">
                        <div class="col-md-3 sensor mx-auto" *ngIf="thing.enhancedControls">
                            <i class="fa fa-thermometer-three-quarters" aria-hidden="true"></i>
                            <span class="sensor-label" [ngClass]="{'warning': checkWarning(thing).t1}">{{toValue(thing.stateObject.t1)}}°C</span>
                        </div>
                        <div class="col-md-3 sensor mx-auto" *ngIf="thing.enhancedControls">
                            <i class="fa fa-thermometer-three-quarters" aria-hidden="true"></i>
                            <span class="sensor-label" [ngClass]="{'warning': checkWarning(thing).t1}">{{toValue(thing.stateObject.t3)}}°C</span>
                        </div>
                        <div class="col-md-3 sensor mx-auto" *ngIf="!thing.enhancedControls">
                            <i class="fa fa-thermometer-three-quarters" aria-hidden="true"></i>
                            <span class="sensor-label" [ngClass]="{'warning': checkWarning(thing).t1}">{{toValue(thing.stateObject.t1)}}°C</span>
                        </div>
                        <div class="col-md-3 sensor mx-auto">
                            <i class="fa fa-tint" aria-hidden="true"></i>
                            <span class="sensor-label">{{toValue(thing.stateObject.h1)}}%</span>
                        </div>
                        <div class="col-md-3 sensor mx-auto">
                            <i class="fa fa-area-chart" aria-hidden="true"></i>
                            <span class="sensor-label" *ngIf="thing.stateObject.t1 && thing.stateObject.h1">
                                {{(toNumber(thing.stateObject.t1) + toNumber(thing.stateObject.h1)).toFixed(0)}}
                            </span>
                            <span class="sensor-label" *ngIf="!(thing.stateObject.t1 && thing.stateObject.h1)">-</span>
                        </div>
                        <div class="col-md-3 sensor mx-auto">
                            <i class="material-icons">settings_remote</i>
                            <span class="sensor-label" [ngClass]="{'warning': checkWarning(thing).t2}">{{toValue(thing.stateObject.t2)}}°C</span>
                        </div>
                        <div class="col-md-3 sensor mx-auto">
                            <i class="fa fa-cloud thing-sensor-image" aria-hidden="true"></i>
                            <span class="sensor-label">{{toValue(thing.stateObject.c1)}}</span>
                        </div>
                        <div class="col-md-3 sensor mx-auto">
                            <i class="material-icons thing-sensor-image">equalizer</i>
                            <span class="sensor-label">{{toValue(thing.stateObject.m1)}}db</span>
                        </div>
                        <div class="col-md-3 sensor mx-auto" *ngIf="thing.enhancedControls">
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div>
                    <span class="sensor-timestamp" *ngIf="thing.updated && isActive(thing)">
                        {{'details.last_updated' | translate}}: {{thing.updated | date:'dd.MM.yyyy HH:mm:ss'}}
                    </span>
                    <span class="sensor-timestamp-warning" *ngIf="thing.updated && !isActive(thing)">
                        {{'details.last_updated' | translate}}: {{thing.updated | date:'dd.MM.yyyy HH:mm:ss'}}
                    </span>
                    <span class="detach-device">
                        <i class="fa fa-trash-o" aria-hidden="true" (click)="confirm[thing.name] = true"></i>
                        <app-confirm-dialog
                            [visible]="confirm && confirm[thing.name]"
                            [title]="'dashboard.confirm_remove_device_title' | translate"
                            [yesText]="'dashboard.confirm_remove_device_yes' | translate"
                            [noText]="'dashboard.confirm_remove_device_no' | translate"
                            (yes)="removeDevice(thing)"
                            (no)="cancelRemoveDevice(thing)">
                            <span [innerHTML]="'dashboard.confirm_remove_device' | translate: {deviceName: thing.label}"></span>
                        </app-confirm-dialog>
                    </span>
                </div>
            </div>
        </div>
    </div>
    
       
    
</div>
