import { Component, OnInit } from '@angular/core';

import { MessageService } from './message.service';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

    constructor(private messageService: MessageService) { }

    ngOnInit() {
    }

    public messages(): any[] {
        return this.messageService.messages
    }

    public remove(message): void {
        this.messageService.remove(message)
    }
}
