import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '../user/auth/auth.service'
import { TranslateService } from '@ngx-translate/core'
import { ThingService } from '../device/thing.service'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    constructor(private router: Router, public authService: AuthService,
        private translateService: TranslateService, private thingService: ThingService) {
    }

    ngOnInit() {
    }

    public schedulerEnabled(): boolean {
        return this.thingService.things.filter(t => t.controls).length > 0
    }

    public logout() {
        console.log('LOGOUT')
        this.authService.logout()
        this.router.navigate(["login"])
    }

    public setLanguage(language: string): void {
        this.translateService.use(language)
        localStorage.setItem('language', language)
    }

    public getLanguage(): string {
        return this.translateService.currentLang
    }
}
