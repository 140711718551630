import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'app-editable-label',
    templateUrl: './editable-label.component.html',
    styleUrls: ['./editable-label.component.css']
})
export class EditableLabelComponent implements OnInit {
    public editing: boolean = false
    public modifiedText: string
    @Input()
    public text: string
    @Output()
    public save: EventEmitter<any> = new EventEmitter<any>()
    @Output()
    public textChange: EventEmitter<string> = new EventEmitter<string>()

    constructor() { }

    ngOnInit() {
        this.modifiedText = this.text
    }

    public onEdit(): void {
        this.editing = true
    }

    public onSave(): void {
        this.editing = false
        this.textChange.emit(this.modifiedText)
        this.save.emit(this.modifiedText)
    }

    public onCancel(): void {
        this.editing = false
        this.modifiedText = this.text
    }
}
