import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { DatePipe } from '@angular/common'

import { AwsConfig } from './common/aws.config'
import { AwsService } from './common/aws.service'

import { AppComponent } from './app.component'
import { HeaderComponent } from './header/header.component'
import { RegisterComponent } from './user/register/register.component'
import { ProfileComponent } from './user/profile/profile.component'
import { LoginComponent } from './user/login/login.component'
import { AppRoutingModule } from './app-routing.module'
import { AuthGuard } from './user/auth/auth-guard.service'
import { AuthService } from './user/auth/auth.service'
import { CognitoService } from './user/auth/cognito.service'
import { DeviceService } from './device/device.service'
import { ThingService } from './device/thing.service'
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component'
import { ForgotPasswordVerifyComponent } from './user/forgot-password-verify/forgot-password-verify.component'
import { VerifyComponent } from './user/verify/verify.component'
import { AddDeviceComponent } from './device/add-device/add-device.component'
import { DashboardComponent } from './device/dashboard/dashboard.component'
import { DevicesComponent } from './device/devices/devices.component'
import { DetailsComponent } from './device/details/details.component'
import { AdminComponent } from './admin/admin.component'
import { SensorParametersComponent } from './admin/sensor-parameters/sensor-parameters.component'
import { DialogComponent } from './dialog/dialog.component'
import { EditableLabelComponent } from './editable-label/editable-label.component'
import { SpinnerComponent } from './spinner/spinner.component'
import { MessagesComponent } from './messages/messages.component'
import { MessageService } from './messages/message.service'
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component'
import { SchedulesComponent } from './device/schedules/schedules.component'
import { OtaComponent } from './device/ota/ota.component'

import { DatetimePickerModule } from './datetime-picker/datetime-picker.module'
import { DatePickerModule } from './date-picker/date-picker.module'

import { ChartsModule } from 'ng2-charts'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { DatepickerModule, DatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TimepickerModule, TimepickerConfig } from 'ngx-bootstrap/timepicker';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { ManualControlComponent } from './device/manual-control/manual-control.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        RegisterComponent,
        ProfileComponent,
        LoginComponent,
        ForgotPasswordComponent,
        VerifyComponent,
        DevicesComponent,
        AddDeviceComponent,
        DashboardComponent,
        DetailsComponent,
        SpinnerComponent,
        AdminComponent,
        SensorParametersComponent,
        DialogComponent,
        ForgotPasswordVerifyComponent,
        EditableLabelComponent,
        MessagesComponent,
        ConfirmDialogComponent,
        SchedulesComponent,
        ToggleButtonComponent,
        ManualControlComponent,
        OtaComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        ChartsModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        DatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        DatetimePickerModule,
        DatePickerModule,
        BrowserAnimationsModule
    ],
    providers: [
        DatePipe,
        AwsConfig,
        AwsService,
        AuthGuard,
        AuthService,
        CognitoService,
        DeviceService,
        ThingService,
        MessageService,
        DatepickerConfig,
        TimepickerConfig,
        { provide: 'Window', useValue: window }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
