import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import { ThingService } from '../../device/thing.service'

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private thingService: ThingService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        let url: string = state.url
        let roles: string[] = route.data ? route.data['roles'] : []

        return new Promise<boolean>((resolve, reject) => {
            this.checkLogin(url)
                .then(() => this.checkRoles(roles))
                .then(() => resolve(true))
                .catch(() => resolve(false))
        })
    }

    checkLogin(url: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (this.authService.isLoggedIn) {
                resolve(true)
            }
            // Store the attempted url for redirecting
            this.authService.redirectUrl = url;
            // Check if we can login without going to login page
            this.authService.getCurrentUser()
                .then(() => {
                    // Things are shared and must be loaded when logged in
                    this.thingService.initialize(false)
                        .then(() => resolve(false))
                        .catch(() => resolve(false)) // do not go to login page if initilize fails
                })
                .catch((error) => {
                    this.router.navigate(['/login'])
                    reject()
                })
        })
    }

    checkRoles(roles: string[]) {
        return new Promise<boolean>((resolve, reject) => {
            if (!roles || roles.length === 0) {
                return resolve(false)
            }
            let result = roles.filter((role) => this.authService.hasRole(role)).length === roles.length
            if (!result) {
                console.log('User does not have all required roles: ' + roles)
                reject(false)
            } else {
                resolve(false)
            }
        })
    }
}
