import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { interval, Subject, Subscription } from "rxjs"
import { TooltipModule } from 'ng2-tooltip-directive';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

import { ManualControl } from './manual-control.model'
import { ThingService } from '../thing.service'
import { AuthService } from '../../user/auth/auth.service'

import { Schedule, Weekday, Time } from './../schedules/schedule.model'

@Component({
    selector: 'app-manual-control',
    templateUrl: './manual-control.component.html',
    styleUrls: ['./manual-control.component.css']
})
export class ManualControlComponent implements OnInit {
    @Input()
    public thingName: string;

    public manualControl: ManualControl
    public saunaTemperature: number
    public stoveTemperature: number
    public lidDuration: number
    private receivedManualControl: ManualControl
    public syncing: boolean = false
    public powerStateChange: boolean = false
    private modelChanged: Subject<ManualControl> = new Subject<ManualControl>();
    private thingReadySubscription: Subscription
    private thingUpdateSubscription: Subscription
    private updateDelay : number = 0
    private updateDelayDiv: number = 0
    private oldMm0: string = undefined
    private oldPr1: number = 0
    private count: number = 0

    public lidTimeLeft: string
    public duration: number
    public thingEnhancedControls: boolean
    public thingControls: boolean
    public selectedThing: any
    public normalModeEdit: boolean = false
    public scheduleModeEdit: boolean = false
    public adminMode: boolean = false

    public INFINITEDURATION: number = 480

    constructor(private thingService: ThingService, private authService: AuthService) {
        this.modelChanged
            .debounceTime(500)
            .subscribe(() => this.update(false))
    }

    ngOnChanges(changes: SimpleChanges) {
        let thingChange = changes['thingName']
        if (thingChange && thingChange.currentValue) {
            this.loadManualControl(thingChange.currentValue)
        }
    }

    ngOnInit() {
        this.updateDelay = 0
        this.normalModeEdit = this.authService.hasRole('useredit');
        this.adminMode = this.authService.hasRole('adminedit')
        this.scheduleModeEdit = this.authService.hasRole('scheduleedit')
        this.thingReadySubscription = this.thingService.readyObservable.subscribe(thingName => {
            if (this.thingName && this.thingName === thingName) {
                this.loadManualControl(this.thingName)
                this.updateRemaining()
            }
        })
        this.thingUpdateSubscription = this.thingService.thingsObservable.subscribe(thing => {
            if (this.manualControl && this.thingName && this.thingName === thing.name) {
                if (thing.stateObject && this.selectedThing.advanced_pack) {
                    if (thing.stateObject['pr1'] != this.oldPr1) {
                        this.oldPr1 = thing.stateObject['pr1']
                        this.updateRemaining()
                    }
                    if ((thing.stateObject['mm0'] && thing.stateObject['mm0'] != this.oldMm0) || this.oldMm0 == undefined) {
                        this.oldMm0 = thing.stateObject['mm0']
                        this.thingService.getThingManualControl(this.thingName)
                        .then((mm) => {
                            var mmp = JSON.parse(mm.data.payload);
                            if (mmp.state && mmp.state.reported &&  mmp.state.reported.mm) {
                                var mmv = mmp.state.reported.mm
                                console.log('RECEIVED MM field', mmv)
                                this.receivedManualControl = ManualControl.fromShadow(mmv,  this.thingEnhancedControls)
                                if (this.manualControl.equals(this.receivedManualControl)) {
                                    this.syncing = false
                                } else {
                                    this.syncing = false
                                    // RELOAD if mm changed?
                                    //console.log('Thing mm field not in sync, ' + this.manualControl.toShadow() + ' vs ' + thing.stateObject['mm'])
                                    console.log('Thing mm field not in sync, ' + this.manualControl.toShadow() + ' vs ' + this.receivedManualControl.toShadow())
                                }
                                this.updateRemaining()
                            }
                        })
                    }
                }
            }
        })
        interval(5000).subscribe(x => {
            this.executeScheduled()
        });
    }

    ngOnDestroy() {
        if (this.thingReadySubscription) {
            this.thingReadySubscription.unsubscribe()
        }
        if (this.thingUpdateSubscription) {
            this.thingUpdateSubscription.unsubscribe()
        }
    }

    public canEdit() {
        if (!this.isActive()) {
            return false;
        }
        if (this.powerStateChange) {
            return false;
        }
        if (this.normalModeEdit) {
            return true;
        }
        return false;
    }

    private executeScheduled()  {
        if (this.syncing) {
            return;
        }
        if (this.updateDelay > 0) {
            this.updateDelay--;
            if (this.updateDelay == 0) {
                this.powerStateChange = false;
            }
            return;
        }
        if (this.updateDelayDiv > 0) {
            this.updateDelayDiv--;
            return;
        }
        this.updateDelayDiv = 4;
        this.updateRemaining()
    }

    private updateRemaining() {
        if (this.selectedThing) {
            let pr1Value: number = Number(this.selectedThing.stateObject.pr1)
            let duration = 0
            let lidDuration = 0
            let startDateTime : Date = undefined
            if (pr1Value == 0 && this.receivedManualControl && this.receivedManualControl.power) {
                duration = this.receivedManualControl.duration
                startDateTime = this.receivedManualControl.startDateTime
                lidDuration = this.selectedThing.stateObject['l1']                
                if (lidDuration <= 0) {
                    lidDuration = this.receivedManualControl.lidDuration
                }
            } else if (pr1Value > 0 && pr1Value < 255) {
                lidDuration = this.selectedThing.stateObject['l1']
                let sh = "sh" + pr1Value
                let scheduleStr = this.selectedThing.stateObject["sh1"]
                if (scheduleStr) {
                    let schedule = Schedule.fromShadow(sh, scheduleStr, this.selectedThing.enhancedControls, this.selectedThing.advanced_pack)
                    startDateTime = schedule.startDate
                    duration = schedule.getScheduleDuration()
                    if (lidDuration <= 0) {
                        lidDuration = schedule.lidDuration
                    }
                    this.saunaTemperature = schedule.targetTemperature
                    this.stoveTemperature = schedule.stonesTemperature
                }
            } else if (pr1Value == 255) {
                this.duration = 0
            }
            if (startDateTime !== undefined) {
                let date = new Date()
                if (duration < 0 || duration >= this.INFINITEDURATION) {
                    this.duration = this.INFINITEDURATION
                    this.manualControl.duration = -1
                } else {
                    let remain = Math.floor(duration - ((date.getTime() - startDateTime.getTime() - 1000 * 59) / (1000 * 60)))
                    if (remain <= 0) {
                        this.duration = 0
                        this.manualControl.duration = 0
                    } else {
                        this.duration = remain
                        if (this.manualControl) {
                            this.manualControl.duration = remain
                        }
                    }
                }
                this.lidDuration = lidDuration
            }
        } else {
            this.duration = 0
        }
        if (!this.deviceOn()) {
            this.lidDuration = 0
            this.duration = 0
        }
    }   

    private loadManualControl(thingName: string): void {
        let thing: any = this.thingService.getThingByName(thingName)
        if (thing && thing.stateObject) {
            console.log('THING STATE', thing)
            let pr1Value: number = Number(thing.stateObject['pr1'])
            this.lidTimeLeft = thing.stateObject['l1']
            let valid: boolean = false
            if (thing.advanced_pack) {
                this.thingService.getThingManualControl(this.thingName)
                .then((mm) => {
                    var mmp = JSON.parse(mm.data.payload);
                    if (mmp.state && mmp.state.reported &&  mmp.state.reported.mm) {
                        let mmField: string = mmp.state.reported.mm
                        let pr1Value: number = Number(thing.stateObject['pr1'])
                        this.lidTimeLeft = thing.stateObject['l1']
                        this.duration = 0
                        if (ManualControl.validate(mmField) && (pr1Value == 0 || pr1Value == 255)) {
                            this.manualControl = ManualControl.fromShadow(mmField, this.thingEnhancedControls)
                            this.saunaTemperature = this.manualControl.temperature
                            this.stoveTemperature = this.manualControl.stonesTemperature
                            this.lidDuration = this.manualControl.lidDuration
                            this.duration = this.duration
                            valid = true
                        }
                    }
                })
                .catch((error) => {
                    console.log('getThingManualControl failed', error)
                })
            }
            if (!valid) {
                this.manualControl = new ManualControl(false, 70, 200, 240, 120, false,  this.thingEnhancedControls)
                this.saunaTemperature = this.manualControl.temperature
                this.stoveTemperature = this.manualControl.stonesTemperature
                this.lidDuration = this.manualControl.lidDuration
                this.duration = 0
            }
            this.selectedThing = thing
            this.thingControls = thing.controls
            this.thingEnhancedControls = thing.enhancedControls
            if (pr1Value > 0 && pr1Value < 255) {
                let sh = "sh" + pr1Value
                let scheduleStr = thing.stateObject[sh]
                if (scheduleStr) {
                    let schedule = Schedule.fromShadow(sh, scheduleStr, this.selectedThing.enhancedControls, this.selectedThing.advanced_pack)
                    this.saunaTemperature = schedule.targetTemperature
                    this.stoveTemperature = schedule.stonesTemperature
                    this.lidDuration = schedule.lidDuration
                }
            }
        }
    }

    private update(force : boolean): void {
        let pr1Value: number = Number(this.selectedThing.stateObject.pr1)
        // off and manual control only
        if (!force && pr1Value > 0 && pr1Value < 255) {
            return
        }
        this.syncing = true
        let payload: any = {}
        payload.state = {}
        payload.state.desired = {}
        this.manualControl.temperature = this.saunaTemperature
        this.manualControl.stonesTemperature = this.stoveTemperature
        this.manualControl.lidDuration = this.lidDuration
        this.updateDelay = 2
        this.powerStateChange = true
        payload.state.desired['mm'] = this.manualControl.toShadow()
        console.log('SEND DESIRED MM', payload.state)
        this.thingService.updateThingManualControl(this.thingName, payload);
        //this.thingService.updateShadow(this.thingName, payload)
    }

    public convertHoursMins(value: string): string {
        if (!value) return '-'
        let time = parseInt(value)
        if (Number.isNaN(time)) return '-'
        if (time >= this.INFINITEDURATION) {
            return "∞"
        }
        let hours: number = Math.floor((time / 60))
        let minutes: number = time - (hours * 60)
        return `${hours}h ${minutes}min`
    }

    public triggerModelChanged(): void {
        this.modelChanged.next(this.manualControl)
    }

    public triggerModelChangedDuration(): void {
        this.manualControl.duration = this.duration
        if (this.manualControl.duration >= this.INFINITEDURATION) {
            this.manualControl.duration = -1
            if (this.manualControl.duration > this.INFINITEDURATION) {
                this.duration = this.INFINITEDURATION
            }
        }
        this.modelChanged.next(this.manualControl)
    }

    public getManualControlDuration(duration: number): string {
        if (!duration) {
            return '-'
        }
        return this.convertHoursMins(duration.toString())
    }

    public setPower(enabled: boolean): void {
        this.manualControl.power = enabled
        this.manualControl.duration = 2 * 60
        if (this.selectedThing.stateObject.ts1) {
            this.manualControl.duration = this.selectedThing.stateObject.ts1
        }
        this.manualControl.lidDuration = parseInt(this.selectedThing.stateObject.lidt)
        if (Number.isNaN(this.manualControl.lidDuration)) {
            this.manualControl.lidDuration = 20 * 60
        }
        this.manualControl.temperature = parseInt(this.selectedThing.stateObject.sta2)
        if (Number.isNaN(this.manualControl.temperature)) {
            this.manualControl.temperature = 70
        }
        this.manualControl.stonesTemperature = parseInt(this.selectedThing.stateObject.stm2)
        if (Number.isNaN(this.manualControl.stonesTemperature)) {
            this.manualControl.stonesTemperature = 200
        }
        // send update immediately from on/off button
        this.saunaTemperature = this.manualControl.temperature
        this.stoveTemperature = this.manualControl.stonesTemperature
        this.lidDuration = this.manualControl.lidDuration
        this.update(true)
    }

    public setLid(enabled: boolean): void {
        this.syncing = true
        let payload: any = {}
        if (!this.authService.hasRole('useredit') && !this.authService.hasRole('admin'))
            return;
        payload.state = {}
        payload.state.desired = {}
        let id = this.selectedThing.stateObject['lida'] + 1
        if (id >= 10)
            id = 0
        this.count++
        if (this.count >= 10)
            this.count = 0
        payload.state.desired['lida'] = (enabled ? 1 : 0) + (id * 10) + (this.count * 100)
        console.log('SEND DESIRED LID', payload.state)
        this.thingService.updateThingManualControl(this.thingName, payload);
        //this.thingService.updateShadow(this.thingName, payload)
    }

    public LidOpen(): boolean {
        if (!this.thingName) {
            return false
        }
        let thing: any = this.thingService.getThingByName(this.thingName)
        if (!thing || thing.stateObject === undefined || thing.stateObject.lida === undefined) {
            return false
        }
        let lid: number = Number(thing.stateObject.lida)
        return lid == 0 ? false : true;
    }

    public deviceOn(): boolean {
        if (!this.thingName) {
            return false
        }
        let thing: any = this.thingService.getThingByName(this.thingName)
        if (!thing || thing.stateObject === undefined || thing.stateObject.pr1 === undefined) {
            return false
        }
        let pr1Value: number = Number(thing.stateObject.pr1)
        return pr1Value === 255 ? false : true;
    }

    public scheduleOn(): boolean {
        if (!this.thingName) {
            return false
        }
        let thing: any = this.thingService.getThingByName(this.thingName)
        if (!thing || thing.stateObject === undefined || thing.stateObject.pr1 === undefined) {
            return false
        }
        let pr1Value: number = Number(thing.stateObject.pr1)
        return pr1Value > 0 && pr1Value < 255;
    }

    public hasSensorValue(sensor: string): boolean {
        if (!this.selectedThing || !this.selectedThing.stateObject || !this.selectedThing.stateObject.stateObjectMetadata) {
            return false
        }
        if (this.selectedThing.stateObject[sensor] == undefined) {
            return false
        }
        if (this.selectedThing.stateObject.stateObjectMetadata[sensor] == undefined) {
            return false
        }
        let dt = Number(this.selectedThing.stateObject.stateObjectMetadata[sensor].timestamp) * 1000
        let date = new Date();
        if (dt > date.getTime() - 30 * 60 * 1000)
            return true
        return false
    }

    public sensorValue(sensor: string): any {
        if (!this.selectedThing || !this.selectedThing.stateObject) {
            return '-'
        }
        if (sensor == 'hr' || sensor == 'hhr') {
            let v = Number(this.selectedThing.stateObject[sensor])
            if (Number.isNaN(v) || v == undefined) {
                return '-'
            }
            v = v / (60 * 60)
            return Number(Number(v).toFixed(2))
        } else if (sensor === 'st') {
            if (this.selectedThing.stateObject[sensor] === undefined)
                return 0
            let v =  Number(this.selectedThing.stateObject[sensor])
            v = v % 1000
            return v
        } else if (sensor === 'ht1' || sensor === 'ht2' || sensor === 'ht3' || sensor === 'ht4' || sensor === 'ht5' || sensor === 'ht6' || sensor === 'ht7' || sensor === 'ht8' || sensor === 'ht9') {
            return Number((this.formatSensorValue(this.selectedThing.stateObject[sensor])) / 11.0).toFixed(0)
        } else if (sensor === 'ix') {
            let h = this.selectedThing.stateObject['h1']
            if (h === undefined) {
                h = this.selectedThing.stateObject['h2']
            }
            let t = this.selectedThing.stateObject['t1']
            if (t === undefined) {
                t = this.selectedThing.stateObject['t2']
            }
            if (h === undefined || t === undefined) {
                return '-'
            }
            return (Number(h) + Number(t)).toFixed(0)
        } else if (sensor === 'l2') { // lid state
            let activeState = (this.selectedThing.stateObject['l2'] >> 4) & 3
            let currentState = (this.selectedThing.stateObject['l2'] >> 6) & 3
            if (activeState == currentState) {
                return activeState
            }
            return currentState + 4
        }
        if (this.selectedThing.stateObject[sensor] === undefined)
            return '-'
        if (sensor == 't1' || sensor == 't2' || sensor == 'h1' || sensor == 'h2' || sensor == 'c1') {
            return this.formatSensorValue2(this.selectedThing.stateObject[sensor])
        } 
        return this.formatSensorValue(this.selectedThing.stateObject[sensor])
    }

    public getState() : number
    {
        if (!this.selectedThing || !this.selectedThing.stateObject) {
            return -1
        }
        if (this.selectedThing.advanced_pack) {
            let st = this.selectedThing.stateObject['st']
            st = st % 1000
            return st
        }
        return -1
    }

    public saunaMinutes() : string
    {
        if (!this.selectedThing || !this.selectedThing.stateObject) {
            return ""
        }
        if (this.selectedThing.advanced_pack) {
            if (this.selectedThing.stateObject['st1']) {
                let v = this.formatSensorValue(this.selectedThing.stateObject['st1'] / 60)
                return " (" + v + "min)"
            }
        }
        return ""
    }

    public heaterMinutes() : string
    {
        if (!this.selectedThing || !this.selectedThing.stateObject) {
            return ""
        }
        if (this.selectedThing.advanced_pack) {
            if (this.selectedThing.stateObject['st2']) {
                let v = this.formatSensorValue(this.selectedThing.stateObject['st2'] / 60)
                return " (" + v + "min)"
            }
        }
        return ""
    }

    public isHeaters() : boolean
    {
        if (!this.selectedThing || !this.selectedThing.stateObject) {
            return false
        }
        if (this.selectedThing.current_pack) {
            let v1 = this.formatSensorValue(this.selectedThing.stateObject['ht1'])
            let v2 = this.formatSensorValue(this.selectedThing.stateObject['ht2'])
            let v3 = this.formatSensorValue(this.selectedThing.stateObject['ht3'])
            if (v1 && v2 && v3 && v1 + v2 + v3 > 0)
                return true            
        } else {
            // stove_pack
            let v = this.formatSensorValue(this.selectedThing.stateObject['ht'])
            if ((v & 511) != 0 && (v & 262144) != 0)
                return true
        }
        return false
    }

    private formatSensorValue(value: any): any {
        if (value === undefined) {
            return '-'
        }
        return Number(Number(value).toFixed(0))
    }

    private formatSensorValue2(value: any): any {
        if (value === undefined) {
            return '-'
        }
        return Number(Number(value).toFixed(0))
    }

    public isActive() : boolean
    {
        let date = new Date();
        return this.selectedThing.updated >= date.getTime() - 24 * 60 * 60 * 1000;
    }

    public hasLid() : boolean
    {
        return false;
        if (this.selectedThing.stateObject['cfg']) {
            let v = this.selectedThing.stateObject['cfg']
            return (v & 1) == 1
        } else {
            return true
        }
    }
}
