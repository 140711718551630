import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { LocalStorageService } from '../local-storage/local-storage.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, OnDestroy {

  private permNames : string[] = [
    "admin",
    "adminedit",
    "adminview",
    "eventview",
    "scheduleedit",
    "useredit"
  ]

  private routeSubscription : any
  private param: string

  constructor(private localStorageService: LocalStorageService, private router: Router, private route: ActivatedRoute)
  {

  }

  ngOnInit() {
    this.routeSubscription = this.route.params.subscribe(params => {
      this.param = params['mode'];
      if (this.param == "admin") {
        this.permNames.forEach((perm) => {
          this.setPermName(perm, 1)
        })
      } else if (this.param == "user") {
        this.setPermName("adminedit", 0)
        this.setPermName("adminview", 0)
        this.setPermName("eventview", 0)
      }
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
        this.routeSubscription.unsubscribe()
    }
  }

  public hasPerm(perm: number) : boolean
  {
    let key = "perm_" + this.permNames[perm - 1]
    let val = this.localStorageService.getItem(key)
    if (val == null || val == "1") {
      return true
    }
    return false
  }

  public setPermName(permName: string, perm: number) : void
  {
    let key = "perm_" + permName
    this.localStorageService.setItem(key, perm > 0 ? "1" : "0")
  }


  public setPerm(perm: number) : void
  {
    let key = "perm_" + this.permNames[(perm > 0 ? perm : -perm) - 1]
    this.localStorageService.setItem(key, perm > 0 ? "1" : "0")
  }


}
