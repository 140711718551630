export class ManualControl {
    power: boolean
    temperature: number
    stonesTemperature: number
    duration: number
    lidDuration: number
    lidState: boolean
    enhancedControls: boolean
    startDateTime: Date
    endDateTime: Date

    constructor(power: boolean, temperature: number, stonesTemperature: number, duration: number, lidDuration: number, lidState: boolean, enhancedControls: boolean) {
        this.power = power
        this.temperature = temperature
        this.stonesTemperature = stonesTemperature
        this.duration = duration
        this.lidDuration = lidDuration
        this.lidState = lidState
        this.enhancedControls = enhancedControls;
    }

    static fromShadow(value: string, enhancedControls: boolean): ManualControl {
        // “2017-10-01_2017-10-01_18:00-20:00_80_46_000"
        let splitted: string[] = value.split('_')
        let startDateSplitted: string[] = splitted[0].split('-')
        let endDateSplitted: string[] = splitted[1].split('-')
        let startTimeSplitted: string[] = splitted[2].split('-')[0].split(':')
        let endTimeSplitted: string[] = splitted[2].split('-')[1].split(':')
        let weekdayHex: string = splitted[3]
        let tempHex: string = splitted.length > 4 ? splitted[4] : '46'
        let stonesTempHex: string = splitted.length > 5 ? splitted[5] : '3c'
        let lidDurationHex: string = splitted.length > 6 ? splitted[6] : '78'
        let enabledBitmask: number = Math.pow(2, 7)
        let weekdayValue: number = Number(parseInt(weekdayHex, 16).toString(10))
        let power: boolean = splitted[0] === '1' ? true : false

        let powerEnabled: boolean = ((enabledBitmask & weekdayValue) === enabledBitmask) ? true : false
        let targetTemperature: number = Number(parseInt(tempHex, 16).toString(10))
        let stonesTemperature: number = Number(parseInt(stonesTempHex, 16).toString(10))
        let lidDuration: number = Number(parseInt(lidDurationHex, 16).toString(10))

        // 23:00 - 01:00
        let startDatetime = new Date(
            parseInt(startDateSplitted[0]),
            parseInt(startDateSplitted[1]) - 1,
            parseInt(startDateSplitted[2]),
            parseInt(startTimeSplitted[0], 10), parseInt(startTimeSplitted[1], 10), 0, 0)
        let endDatetime = new Date(
            parseInt(endDateSplitted[0]),
            parseInt(endDateSplitted[1]) - 1,
            parseInt(endDateSplitted[2]),
            parseInt(endTimeSplitted[0], 10), parseInt(endTimeSplitted[1], 10), 0, 0)
        if (endDatetime.getDate() == startDatetime.getDate() && endDatetime.getHours() < startDatetime.getHours()) {
            endDatetime.setDate(endDatetime.getDate() + 1)
        }

        var timeDiff = Math.abs(endDatetime.getTime() - startDatetime.getTime());
        let duration: number = Math.ceil(timeDiff / (1000 * 60))
        if (duration >= 50 * 365) {
            duration = -1
        }

        let lidState = false;
        let mc = new ManualControl(powerEnabled, targetTemperature, stonesTemperature, duration, lidDuration, lidState, enhancedControls)
        mc.startDateTime = startDatetime
        mc.endDateTime = endDatetime
        return mc
    }

    static validate(manualString: string): boolean {
        let regExp = new RegExp('^\\d{4}-\\d{2}-\\d{2}\\_\\d{4}-\\d{2}-\\d{2}\\_\\d{2}\\:\\d{2}\\-\\d{2}\\:\\d{2}\\_[0-9A-Za-z]{2}_[0-9A-Za-z]{2}(_[0-9A-Za-z]{3})(_[0-9A-Za-z]{2})?$')
        return manualString && manualString.length > 0 && regExp.test(manualString)
    }

    toShadowLid(): string {
        return this.lidDuration.toString()
    }

    toShadow(currentDatetime?: Date): string {
        let startDatetime = currentDatetime ? currentDatetime : new Date()
        let endDatetime = new Date(startDatetime.getTime());
        if (this.duration < 0 || this.duration >= 365 * 50) {
            endDatetime = new Date(2100, 0, 1, startDatetime.getHours(), startDatetime.getMinutes(), 0, 0)
        } else {
            endDatetime.setMinutes(endDatetime.getMinutes() + this.duration)
        }

        let startDateString: string = startDatetime.getFullYear() + '-' + padd(startDatetime.getMonth() + 1) + '-' + padd(startDatetime.getDate())
        let endDateString: string = endDatetime.getFullYear() + '-' + padd(endDatetime.getMonth() + 1) + '-' + padd(endDatetime.getDate())

        let startTime: string = padd(startDatetime.getHours()) + ':' + padd(startDatetime.getMinutes())
        let endTime: string = padd(endDatetime.getHours()) + ':' + padd(endDatetime.getMinutes())

        let weekdayBinary: string = (this.power ? '1' : '0') + '0000000'
        let weekdayHex = parseInt(weekdayBinary, 2).toString(16).toUpperCase()
        if (weekdayHex.length < 2) {
            weekdayHex = '0' + weekdayHex
        }
        let tempHex: string = this.temperature.toString(16).toUpperCase()
        if (tempHex.length < 2) {
            tempHex = '0' + tempHex
        }
            let stonesTempHex: string = this.stonesTemperature.toString(16).toUpperCase()
            if (stonesTempHex.length < 2) {
                stonesTempHex = '00' + stonesTempHex
            } else if (stonesTempHex.length < 3) {
                stonesTempHex = '0' + stonesTempHex
            }
        if (this.enhancedControls) {
            let lidDurationHex: string = this.lidDuration.toString(16).toUpperCase()
            if (lidDurationHex.length < 2) {
                lidDurationHex = '0' + lidDurationHex
            }
            return startDateString + '_' + endDateString + '_' + startTime + '-' + endTime + '_' + weekdayHex + '_' + tempHex + '_' + stonesTempHex + '_' + lidDurationHex
        }
        return startDateString + '_' + endDateString + '_' + startTime + '-' + endTime + '_' + weekdayHex + '_' + tempHex + '_' + stonesTempHex
    }

    equals(other: ManualControl): boolean {
        var equal = false;
        if (this.enhancedControls) {
            equal = this.power === other.power
                && this.temperature === other.temperature
                && this.stonesTemperature === other.stonesTemperature
                //&& this.duration === other.duration
                && this.lidDuration === other.lidDuration
        } else {
            equal = this.power === other.power
                && this.temperature === other.temperature
                //&& this.duration === other.duration
        }
        if (!equal) {
            console.log('power ' + this.power + ' vs ' + other.power)
            console.log('temperate ' + this.temperature + ' vs ' + other.temperature)
            console.log('stonesTemperature ' + this.stonesTemperature + ' vs ' + other.stonesTemperature)
            console.log('duration ' + this.duration + ' vs ' + other.duration)
            console.log('lidDuration ' + this.lidDuration + ' vs ' + other.lidDuration)
        }
        return equal
    }
}

export function padd(value: number): string {
    if (value < 10) {
        return '0' + value
    }
    return '' + value
}
