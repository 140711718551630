export class Chart {
    public stateText: string = "State"
    public activeText: string = "Active"
    public heatingText: string = "Heating"
    public lineChartData: Array<any> = [{ data: [], label: '' }]
    public lineChartLabels: Array<any> = []
    public lineChartLegendEnable: Map<number, boolean>;
    public lineChartOptions: any = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        legend: {
            display: true,
            labels: {
                fontSize: 28,
                filter: function (legendItem, chartData) {
                    return legendItem.text !== '' && legendItem.text !== undefined
                }
            }
        },
        tooltips: {
            enabled: false,
            mode: 'nearest',
            intersect: true,
            custom: function (tooltipModel) {
                // Get or create tooltip element
                var tooltipElement: any = document.getElementById('rento-tooltip');
                if (!tooltipElement) {
                    tooltipElement = document.createElement('div');
                    tooltipElement.id = 'rento-tooltip';
                    tooltipElement.classList.add('chartjs-tooltip');
                    tooltipElement.innerHTML = '<table></table>';
                    this._chart.canvas.parentNode.appendChild(tooltipElement);
                }

                if (tooltipModel.opacity === 0) {
                    tooltipElement.style.opacity = 0;
                    return;
                }

                if (!tooltipModel || !tooltipModel.dataPoints || tooltipModel.dataPoints.length === 0) {
                    tooltipElement.style.opacity = 0;
                    return;
                }
                var index = tooltipModel.dataPoints[0].index
                if (index === undefined || index < 0) {
                    tooltipElement.style.opacity = 0;
                    return;
                }
                var yLabel = tooltipModel.dataPoints[0].yLabel
                var y = tooltipModel.dataPoints[0].y
                var tooltipValues = []
                var title = tooltipModel.title[0]
                for (var i = 0; i < this._data.datasets.length; i++) {
                    var dataset = this._data.datasets[i]
                    // yAxisID is set in details component
                    if (dataset && (dataset.yAxisID === 'A' || dataset.yAxisID === 'B' || dataset.yAxisID === 'D' ||
                        dataset.yAxisID === 'E'|| dataset.yAxisID === 'F' || dataset.yAxisID === 'G' || dataset.yAxisID === 'H')) {
                        let val = "-"
                        let value = 0
                        if (dataset.data[index] !== undefined) {
                            val = dataset.data[index]
                            value = Number(Number(val).toFixed(0))
                            let vals = value.toString()
                            if (dataset.yAxisID == "G" || dataset.yAxisID == "H") {
                                if (val == "0") {
                                    vals = "-"
                                }
                            }
                            if (val == yLabel) {
                                val = "* " + vals
                            } else {
                                val = "" + vals;
                            }
                        }
                        let label = dataset.label
                        if (dataset.yAxisID == "E" || dataset.yAxisID == "F") {
                            if (dataset.yAxisID == "F" && value > 0) {
                                label = this.stateText
                                val = this.activeText
                            } else if (dataset.yAxisID == "E" && value > 0) {
                                label = this.stateText
                                val = this.heatingText
                            }
                        }
                        if (label) {
                            tooltipValues.push({
                                label: label,
                                value: val
                            });
                        }
                    }
                    if (dataset && (dataset.yAxisID === 'C')) {
                        title = dataset.data[index]
                    }
                }

                // Use table element for tooltip
                var tableHtml = '<thead><tr><th>' + title + '</th></tr></thead>';
                tableHtml += '<tbody>';
                tooltipValues.forEach(tip => {
                    tableHtml += '<tr><td>' + tip.label + '</td><td style="text-align: right;">' + tip.value + '</td></tr>'
                })
                tableHtml += '</tbody>';
                var tableElement = tooltipElement.querySelector('table');
                tableElement.innerHTML = tableHtml;

                // Calculate position based on hovered point, flip tooltip position at edges
                var positionY = this._chart.canvas.offsetTop + 5;
                var positionX = this._chart.canvas.offsetLeft;
                var xAlign = -1 * (tooltipElement.offsetWidth / 2) - 5;
                tooltipElement.style.opacity = 1;
                tooltipElement.style.zIndex = 1;
                var left = positionX + xAlign + tooltipModel.caretX
                if (left < tooltipElement.offsetWidth) {
                    left = positionX + tooltipModel.caretX + (tooltipElement.offsetWidth / 2) + 5
                }
                var top = positionY + tooltipModel.caretY - tooltipElement.offsetHeight
                if (top > (this._chart.canvas.height - tooltipElement.offsetHeight)) {
                    top = positionY + tooltipModel.caretY - tooltipElement.offsetHeight - 10;
                }
                if (top < 0) {
                    top = 0;
                }
                tooltipElement.style.left = left + 'px';
                tooltipElement.style.top = top + 'px';
                tooltipElement.style.font = 'normal 16px sans-serif';
                tooltipElement.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            }
        },
        scales: {
            yAxes: [
                {
                    id: 'A',
                    position: 'right',
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 140,
                        fontSize: 28
                        //suggestedMax: 140
                    },
                    gridLines: {
                        color: 'rgba(100,100,100,0.1)'
                    },
                    fontSize: 14
                },
                {
                    id: 'B',
                    position: 'left',
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 400,
                        fontSize: 28
                        // suggestedMax: 140
                    },
                    gridLines: {
                        color: 'rgba(0,0,0,0.1)'
                    }
                },
                {
                    id: 'C',
                    position: 'right',
                    display: false,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 400
                        // suggestedMax: 140
                    },
                    gridLines: {
                        color: 'rgba(0,0,0,0.1)'
                    }
                },
                {
                    id: 'D',
                    position: 'left',
                    display: false,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 24000,
                        fontSize: 28
                    },
                    gridLines: {
                        color: 'rgba(0,0,0,0.1)'
                    }
                },
                {
                    id: 'E',
                    position: 'left',
                    display: false,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 140,
                        fontSize: 28
                    },
                    gridLines: {
                        color: 'rgba(0,0,0,0.1)'
                    }
                },
                {
                    id: 'F',
                    position: 'left',
                    display: false,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 140,
                        fontSize: 28
                    },
                    gridLines: {
                        color: 'rgba(0,0,0,0.1)'
                    }
                },
                {
                    id: 'G',
                    position: 'right',
                    display: false,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 140,
                        fontSize: 28
                        //suggestedMax: 140
                    },
                    gridLines: {
                        color: 'rgba(100,100,100,0.1)'
                    },
                    fontSize: 14
                },
                {
                    id: 'H',
                    position: 'left',
                    display: false,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 400,
                        fontSize: 28
                        //suggestedMax: 140
                    },
                    gridLines: {
                        color: 'rgba(0,0,0,0.1)'
                    }
                },
            ],
            xAxes: [{
                ticks: {
                    autoSkip: true,
                    display: true,
                    autoSkipPadding: 5,
                    fontSize: 16
                },
                gridLines: {
                    color: 'rgba(0,0,0,0)'
                }
            }]
        }
    }

    public lineChartLegend: boolean = true
    public lineChartType: string = 'line'
    public lineChartColors(advanced: boolean, enhancedControls: boolean, advancedMode: boolean): Array<any> {
        if (advanced) {
            if (enhancedControls) {
                if (advancedMode)
                    return this.lineChartColors_stove_admin;
                return this.lineChartColors_stove_user
            }
            return this.lineChartColors_control_adv
        }
        this.lineChartOptions.scales.yAxes[1].display = false
        return this.lineChartColors_control
    }
    private lineChartColors_control: Array<any> = [
        this.createColors(0, 0, 0, 0),           // 60
        this.createColors(0, 0, 0, 0),           // 80
        this.createColors(100, 255, 100, 0),     // noise
        this.createColors(  5, 100, 255, 0.3),   // humidity
        this.createColors(220,  80,  75, 0.3),   // temperature
        this.createColors(220, 220, 220, 0.3),   // co2
        this.createColors(255, 191,  31, 0.3),   // index
    ]
    private lineChartColors_control_adv: Array<any> = [
        this.createColors(0, 0, 0, 0),           // 60
        this.createColors(0, 0, 0, 0),           // 80
        this.createColors(100, 255, 100, 0),     // noise
        this.createColors(  5, 100, 255, 0.3),   // humidity
        this.createColors(220,  80,  75, 0.3),   // temperature
        this.createColors(220, 220, 220, 0.3),   // co2
        this.createColors(255, 191,  31, 0.3),   // index
        this.createColors(  0,   0,   0, 0.9),   // activity
        this.createColors(255,   0,   0, 0.9)    // heater on
    ]
    private lineChartColors_stove_user: Array<any> = [
        this.createColors(0, 0, 0, 0),           // 60
        this.createColors(0, 0, 0, 0),           // 80
        this.createColors(100, 255, 100, 0),     // noise
        this.createColors(  5, 100, 255, 0.3),   // humidity
        this.createColors(220,  80,  75, 0.3),   // temperature
        this.createColors(220, 220, 220, 0.3),   // co2
        this.createColors(255, 191,  31, 0.3),   // index
        this.createColors(  0,   0,   0, 0.9),   // activity
        this.createColors(255,   0,   0, 0.9),   // heater on
        this.createColors(250,  64,  64, 0.3)    // stone temperature
    ]
    private lineChartColors_stove_admin: Array<any> = [
        this.createColors(0, 0, 0, 0),           // 60
        this.createColors(0, 0, 0, 0),           // 80
        this.createColors(100, 255, 100, 0),     // noise
        this.createColors(  5, 100, 255, 0.3),   // humidity
        this.createColors(220,  80,  75, 0.3),   // temperature
        this.createColors(220, 220, 220, 0.3),   // co2
        this.createColors(255, 191,  31, 0.3),   // index
        this.createColors(  0,   0,   0, 0.9),   // activity
        this.createColors(255,   0,   0, 0.9),  // heater on
        this.createColors(250,  20,  75, 0.3),   // set temperature
        this.createColors(250,  64,  64, 0.3),   // stone temperature
        this.createColors(192, 192, 192, 0.3),   // set stone temperature
        this.createColors(  0, 191, 255, 0.3),   // pcb temperature
        this.createColors(  0, 250, 255, 0.3),   // heat sink temperature
        this.createColors(200, 200,  31, 0.3),   // fan speed
        this.createColors(150,  50, 200, 0.3),   // lid
        this.createColors(255,   0, 255, 0.3),   // heaters
        this.createColors(255,   0, 127, 0.3),   // heateramps
        this.createColors(255, 255, 255, 0.3)    // cpu
    ]

    private createColors(red: number, green: number, blue: number, alpha: number): any {
        let divider: number = 1.5
        let pointAlpha: number = 0.0
        let pointBorderAlpha: number = 0.0
        let dRed: number = Math.round((red / divider))
        let dGreen: number = Math.round((green / divider))
        let dBlue: number = Math.round((blue / divider))
        return {
            backgroundColor: 'rgba(' + red + ', ' + green + ', ' + blue + ', ' + alpha + ')',
            borderColor: 'rgba(' + dRed + ', ' + dGreen + ', ' + dBlue + ', 1.0)',
            pointBackgroundColor: 'rgba(' + red + ', ' + green + ', ' + blue + ', ' + pointAlpha + ')',
            pointBorderColor: 'rgba(' + dRed + ', ' + dGreen + ', ' + dBlue + ', ' + pointBorderAlpha + ')',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        }
    }

    private invisibleColors() {
        return {
            backgroundColor: 'rgba(0,0,0,0)',
            borderColor: 'rgba(0,0,0,0)',
            pointBackgroundColor: 'rgba(0,0,0,0)',
            pointBorderColor: 'rgba(0,0,0,0)',
            pointHoverBackgroundColor: 'rgba(0,0,0,0)',
            pointHoverBorderColor: 'rgba(0,0,0,0)'
        }
    }
}
