export class SensorParameters {
    public time: number
    public temperature: number
    public roofTemperature: number
    public humidity: number
    public co2: number
    public noiseLevel: number
    public lowLimitTemperature: number;
    public lowLimitRoofTemperature: number;
    public lowLimitHumidity: number;
    public lowLimitCo2: number;
    public lowLimitNoiseLevel: number;

    public constructor(
        time: number,
        temperature: number,
        roofTemperature: number,
        humidity: number,
        co2: number,
        noiseLevel: number,
        lowLimitTemperature: number,
        lowLimitRoofTemperature: number,
        lowLimitHumidity: number,
        lowLimitCo2: number,
        lowLimitNoiseLevel: number) {
        this.time = time
        this.temperature = temperature
        this.roofTemperature = roofTemperature
        this.humidity = humidity
        this.co2 = co2
        this.noiseLevel = noiseLevel
        this.lowLimitTemperature = lowLimitTemperature
        this.lowLimitRoofTemperature = lowLimitRoofTemperature
        this.lowLimitHumidity = lowLimitHumidity
        this.lowLimitCo2 = lowLimitCo2
        this.lowLimitNoiseLevel = lowLimitNoiseLevel
    }
}
