import { Component, OnInit } from '@angular/core';

import { ThingService } from '../thing.service'
import { AwsService } from '../../common/aws.service'
import { MessageService } from '../../messages/message.service';

@Component({
    selector: 'app-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit {
    public confirm: boolean[] = []
    public availableFirmware: Array<Object>
    public loading: boolean = false
    public currentFirmwareVersion: string

    constructor(private thingService: ThingService, private awsService: AwsService, private messageService: MessageService) {
    }

    ngOnInit() {
        this.loading = true
        this.awsService.getApiClient()
            .then((apiClient) => {
                return this.getLatestFirmware(apiClient)
            })
            .then((response) => {
                if (response.data.firmware) {
                    this.currentFirmwareVersion = this.firmwareToString(response.data.firmware);
                    this.availableFirmware = response.data.available.map(this.firmwareToString);
                }
                this.loading = false
            })
            .catch((error) => {
                console.log('error', error)
                this.messageService.warning(error.data.message)
                this.loading = false
            })
    }

    public firmwareToString(firmware:any):string {
        return firmware.major + '.' + firmware.minor + "." + firmware.patch;
    }

    public hasUpdate(firmware:string):boolean {
        return false;
        /*
        if (!firmware || !this.currentFirmwareVersion ) return false

        let [newMajor, newMinor] = this.currentFirmwareVersion.split(".");
        let [major, minor] = firmware.split(".");

        return parseInt(newMajor) > parseInt(major) || parseInt(newMinor) > parseInt(minor)
        */
    }

    public listThings(): any[] {
        return this.thingService.things
    }

    private getLatestFirmware(apiClient:any): Promise<any> {
        var params = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        var body = {}
        var additionalParams = {}
        return apiClient.getLatestFirmwareGet(params, body, additionalParams)
    }

    public getSaunaInfo(thing : any): string
    {
        if (thing.parms && thing.parms.saunainfo) {
            return thing.parms.saunainfo
        }
        return ""
    }
}
