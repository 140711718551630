import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from "rxjs";

import { AwsService } from './../../common/aws.service';
import { ThingService } from '../thing.service';
import { MessageService } from '../../messages/message.service';
import { Firmware } from './firmware.model';

@Component({
    selector: 'ota',
    templateUrl: './ota.component.html',
    styleUrls: ['./ota.component.css']
})
export class OtaComponent implements OnInit, OnDestroy {
    public targetFirmwareVersion: string
    public currentFirmwareVersion: string
    public availableFirmware: Array<Object>
    public loading: boolean = false
    public updating: boolean = false


    // Listen route change
    private routeSubscription: Subscription

    // Selections made with url path (ngOnInit)
    public selectedThing: any

    constructor(public router: Router, private route: ActivatedRoute, private awsService: AwsService, private thingService: ThingService, private messageService: MessageService) { }

    ngOnInit() {
        // TODO  what if there are no devices at all?
        this.routeSubscription = this.route.params.subscribe(params => {
            if (params['thing']) {
                this.loading = true
                let routeThing = this.thingService.getThingByName(params['thing'])
                if (routeThing) {
                    this.selectedThing = routeThing
                    //this.schedules = this.loadThingSchedules(this.selectedThing)
                    
                } else {
                    this.router.navigate(["dashboard"])
                }
            } else {
                if (this.thingService.things && this.thingService.things.length > 0) {
                    let things = this.thingService.things
                    if (things.length > 0) {
                        let defaultThing = things[0]
                        this.router.navigate(['/ota', defaultThing.name], { replaceUrl: true })
                    } else {
                        this.router.navigate(["dashboard"])
                    }
                } else {
                    this.router.navigate(["dashboard"])
                }
            }
        })// TODO  what if there are no devices at all?
        
        this.loading = true
        this.awsService.getApiClient()
            .then((apiClient) => {
                return this.getLatestFirmware(apiClient)
            })
            .then((response) => {
                this.currentFirmwareVersion = this.firmwareToString(response.data.firmware);
                this.availableFirmware = response.data.available.map(this.firmwareToString);
                this.targetFirmwareVersion = this.currentFirmwareVersion;
                this.loading = false
            })
            .catch((error) => {
                console.log('error', error)
                this.messageService.warning(error.data.message)
                this.loading = false
            })
    }

    public firmwareToString(firmware:any):string {
        return firmware.major + '.' + firmware.minor + "." + firmware.patch;
    }

    public listThings(): any[] {
        return this.thingService.things
    }

    public startFirmwareUpdate(): void {
        console.log('Start firmware update...')

        if (!this.targetFirmwareVersion) { // TODO add more validations
            this.messageService.warning('ota.validate.target_version_missing')
            return
        }

        this.updating = true

        this.awsService.getApiClient()
            .then((apiClient) => {
                return this.updateFirmware(apiClient, this.targetFirmwareVersion)
            })
            .then((result) => {
                console.log('UPDATE FIRMWARE RESPONSE', result)
                this.updating = false
                this.thingService.setFirmwareUpdatePending(this.selectedThing.name, true)
                this.router.navigate(["devices"])
                this.messageService.info('ota.firmware_update_in_progress')
            })
            .catch((error) => {
                console.log('UPDATE FIRMWARE ERROR', error)
                this.updating = false
            })
    }

    private updateFirmware(apiClient, targetVersion): Promise<any> {
        var params = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        var firmware = Firmware.toFirmware(targetVersion);
        var body = {
            targetVersion: firmware,
            thingName: this.selectedThing.name
        }
        var additionalParams = {}
        return apiClient.updateFirmwarePost(params, body, additionalParams)
    }

    private getLatestFirmware(apiClient:any): Promise<any> {
        var params = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        var body = {}
        var additionalParams = {}
        return apiClient.getLatestFirmwareGet(params, body, additionalParams)
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe()
        }
    }
}
