<div class="dropdown" [ngClass]="{ 'show': showPopup === true }" (offClick)="offClick()">
    <ul class="dropdown-menu" role="menu" [ngClass]="{ 'show': showPopup === true }">
        <li class="my-2 mx-2">
            <datepicker *ngIf="showDate"
                [(ngModel)]="dateValue" [showWeeks]="showWeeks" [datepickerMode]="datepickerMode"
                [minDate]="minDate" [maxDate]="maxDate" [dateDisabled]="dateDisabled" [startingDay]="1">
            </datepicker>
            <timepicker *ngIf="showTime"
                [(ngModel)]="timeValue" [showMeridian]="false" [showSpinners]="true" [minuteStep]="1">
            </timepicker>
        </li>
        <li class="mx-2 mb-2">
            <span class="btn-group pull-left">
                <button class="btn btn-secondary btn-sm" (click)="onNow()">{{texts.now}}</button>
            </span>
            <span class="btn-group pull-right">
                <button class="btn btn-primary btn-sm" (click)="onClose()">{{texts.close}}</button>
            </span>
        </li>
    </ul>
</div>
