import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
    @Input()
    visible: boolean = false
    @Input()
    title: string = ''
    @Input()
    yesText: string = 'Yes'
    @Input()
    noText: string = 'No'
    @Output()
    yes: EventEmitter<any> = new EventEmitter()
    @Output()
    no: EventEmitter<any> = new EventEmitter()

    constructor() { }

    ngOnInit() {
    }

    public onYes(): void {
        this.visible = false
        this.yes.emit()
    }

    public onNo(): void {
        this.visible = false
        this.no.emit()
    }
}
