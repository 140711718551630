<div class="container">
    <div class="col-sm-12">
        <legend>{{'admin.administration' | translate}}</legend>
        <hr>

        <!--<app-sensor-parameters></app-sensor-parameters>-->

    </div>

    <div class="col-sm-12">
        <table class="spreadsheet table table-condensed table-striped table-bordered thing-table">
            <tr>
                <td>
                    <button [disabled]="hasPerm(1)" (click)="setPerm(1)">Enable Admin</button>
                </td>
                <td>
                    <button [disabled]="!hasPerm(1)" (click)="setPerm(-1)">Disable Admin</button>
                </td>
            </tr>
            <tr>
                <td>
                    <button [disabled]="hasPerm(2)" (click)="setPerm(2)">Enable AdminEdit</button>
                </td>
                <td>
                    <button [disabled]="!hasPerm(2)" (click)="setPerm(-2)">Disable AdminEdit</button>
                </td>
            </tr>
            <tr>
                <td>
                    <button [disabled]="hasPerm(3)" (click)="setPerm(3)">Enable AdminView</button>
                </td>
                <td>
                    <button [disabled]="!hasPerm(3)" (click)="setPerm(-3)">Disable AdminView</button>
                </td>
            </tr>
            <tr>
                <td>
                    <button [disabled]="hasPerm(4)" (click)="setPerm(4)">Enable EventView</button>
                </td>
                <td>
                    <button [disabled]="!hasPerm(4)" (click)="setPerm(-4)">Disable EventView</button>
                </td>
            </tr>
            <tr>
                <td>
                    <button [disabled]="hasPerm(5)"  (click)="setPerm(5)">Enable Schedule Edit</button>
                </td>
                <td>
                    <button [disabled]="!hasPerm(5)"  (click)="setPerm(-5)">Disable Schedule Edit</button>
                </td>
            </tr>
            <tr>
                <td>
                    <button [disabled]="hasPerm(6)"  (click)="setPerm(6)">Enable Manual Control</button>
                </td>
                <td>
                    <button [disabled]="!hasPerm(6)"  (click)="setPerm(-6)">Disable Manual Control</button>
                </td>
            </tr>
        </table>
    </div>

</div>
