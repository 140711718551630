import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit {
    @Input()
    public value: Date;
    @Output()
    public valueChange = new EventEmitter();
    @Input()
    public showPopup = false;
    @Output()
    public showPopupChange = new EventEmitter();
    @Input()
    public datepickerMode: string = 'day';

    constructor() { }

    ngOnInit() {
    }

    public offClick(): void {
        this.showPopup = false;
        this.showPopupChange.emit(false);
    }

    public dateChanged(event): void {
        this.showPopup = false;
        this.valueChange.emit(this.value);
    }

}
