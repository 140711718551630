import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/Observable'

import { AwsConfig } from './aws.config'

declare var AWS: any;
declare var AWSCognito: any;
declare var apigClientFactory: any;

@Injectable()
export class AwsService {

    constructor(private awsConfig: AwsConfig) {
        AWS.config.region = AwsConfig.REGION
        AWSCognito.config.region = AwsConfig.REGION
    }

    public getCredentials(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            AWS.config.getCredentials((err) => {
                if (err) {
                    reject(err);
                } else {
                    if (AWS.config && AWS.config.credentials) {
                        if (AWS.config.credentials.expired) {
                            reject('AWS.config.credentials expired')
                        } else {
                            resolve(AWS.config.credentials)
                        }
                    } else {
                        reject('AWS.config.credentials was undefined')
                    }
                }
            })
        })
    }

    public setCredentials(jwtToken): Promise<any> {
        let logins = {}
        logins[AwsConfig.COGNITO_LOGIN_ID] = jwtToken

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: AwsConfig.IDENTITY_POOL_ID,
            Logins: logins
        })

        // Call refresh to update token, will also create identity for user if not done already.
        return new Promise<any>((resolve, reject) => {
            AWS.config.credentials.refresh((error) => {
                if (error) {
                    console.log('aws.service failed to refresh credentials', error)
                    reject(error)
                } else {
                    resolve({})
                }
            })
        })
    }

    public clearCredentials(): void {
        // Clear up previous login or we may get error "Logins don't match.
        // Please include at least one valid login for this identity or identity pool"
        // https://github.com/aws/aws-sdk-js/issues/609
        if (AWS.config.credentials) {
            AWS.config.credentials.clearCachedId();
        }
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: AwsConfig.IDENTITY_POOL_ID
        })
    }

    public getApiClient(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.getCredentials()
                .then((awsCredentials) => {
                    let config = {
                        accessKey: awsCredentials.accessKeyId,
                        secretKey: awsCredentials.secretAccessKey,
                        sessionToken: awsCredentials.sessionToken,
                        region: AwsConfig.REGION,
                        invokeUrl: AwsConfig.API_GATEWAY_INVOKE_URL
                    }
                    // NOTE! AWS Api Gateway does not support invoke url config, the code needs to be
                    // manually included to the apigClientFactory.newClient.
                    // Add piece of code from below inside the newClient method in apigClient.js:line 56
                    // Original code:
                    // var invokeUrl = 'https://rsjep1unsk.execute-api.eu-west-1.amazonaws.com/prod';
                    // Replacement code:
                    // var invokeUrl = 'https://rsjep1unsk.execute-api.eu-west-1.amazonaws.com/prod';
                    // if (config.invokeUrl !== undefined) {
                    //     invokeUrl = config.invokeUrl
                    // }
                    let apigClient = apigClientFactory.newClient(config)
                    resolve(apigClient)
                })
                .catch((error) => reject(error))
        })
    }
}

