<div class="row">
    <div class="col-md-12" *ngIf="selectedThing">
         <span dropdown class="thing-title">
            <i class="fa fa-caret-down" aria-hidden="true"></i>
            <a href="#" dropdownToggle (click)="false">{{selectedThing.label}}</a>
            <ul *dropdownMenu class="dropdown-menu">
                <li *ngFor="let thing of listThings()">
                    <a class="dropdown-item" href="#" [routerLink]="['/ota', thing.name]">{{thing.label}}</a>
                </li>
            </ul>
        </span>
        <app-spinner [loading]="loading"></app-spinner>
    </div>
</div>
<hr>
<div class="row" *ngIf="!loading">
    <div class="offset-sm-3 col-sm-6">
        <legend>
            {{'ota.firmware_update' | translate}}
        </legend>
        <div>
            <div class="form-group">
                <label class="label" for="deviceFirmwareVersion">{{'ota.device_firmware_version' | translate}}:</label>
                <p><i id="deviceFirmwareVersion">{{selectedThing.firmwareRevision}}</i></p>
                <label class="label" for="currentFirmwareVersion">{{'ota.current_firmware_version' | translate}}:</label>
                <p><i>{{currentFirmwareVersion}}</i></p>
                <label class="label" for="targetFirmwareVersion">{{'ota.target_firmware_version' | translate}}:</label>

                <!--<input id="targetFirmwareVersion" type="text" class="form-control" [(ngModel)]="targetFirmwareVersion">-->

                <select id="targetFirmwareVersion" class="form-control" id="power" [(ngModel)]="targetFirmwareVersion">
                    <option *ngFor="let opt of availableFirmware" [value]="opt">{{opt}}</option>
                </select>
            </div>
            <button [disabled]="targetFirmwareVersion == selectedThing.firmwareRevision" type="button" class="btn btn-primary" (click)="startFirmwareUpdate()">{{'ota.update' | translate}}
                <app-spinner [loading]="updating"></app-spinner>
            </button>
        </div>
    
</div>
