import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/Observable'
import { TimerObservable } from "rxjs/observable/TimerObservable";
import { BehaviorSubject } from 'rxjs/BehaviorSubject'

import { AuthService } from '../user/auth/auth.service'
import { DeviceService } from './device.service'
import { AwsService } from '../common/aws.service'
import { resolve } from 'dns';

@Injectable()
export class ThingService {
    // Note! This service must not change this memory reference
    public things: any[] = []
    private stateSource = new BehaviorSubject<any>({})
    public thingsObservable: Observable<any> = this.stateSource.asObservable()
    private readySource = new BehaviorSubject<any>({})
    public readyObservable: Observable<any> = this.readySource.asObservable()
    private mqttClient: any
    private initialized: boolean = false
    private internalClientToken: string = new Date().getTime() + '' + (Math.random() * 100).toFixed(0)

    public PACK_MONITOR : number = 1
    public PACK_CONTROL : number = 2
    public PACK_STOVE : number = 3
    public PACK_CURRENTBOX : number = 4

    constructor(private deviceService: DeviceService, private authService: AuthService, private awsService: AwsService) {
        this.authService.refreshTokensObservable.subscribe(() => {
            console.log('thing.service received refresh token event')
            this.deviceService.updateMqttClientCredentials(this.mqttClient)
        })
    }

    public initialize(force: boolean): Promise<any> {
        if (this.initialized && !force) {
            return Promise.resolve()
        }
        return new Promise((resolve, reject) => {
            let isAdmin = this.authService.hasRole('adminedit') || this.authService.hasRole('admin');
            this.deviceService.listThings(isAdmin)
                .then((things: any[]) => {
                    console.log('thing.service.initialize listThings', things)
                    if (this.initialized && this.mqttClient) {
                        console.log('thing.service.initialize done already, cleaning up previous things')
                        this.things.forEach((thing) => {
                            console.log('thing.service.initialize unregister thing ' + thing.name)
                            this.mqttClient.unregister(thing.name)
                        })
                    }
                    // Empty and keep same reference
                    this.things.splice(0)
                    for (let i = 0; i < things.length; i++) {
                        this.things.push(things[i])
                    }
                    if (this.things.length > 0) {
                        let mqttClientId = 'mqtt-client-' + (Math.floor((Math.random() * 100000) + 1))
                        return this.deviceService.createMqttClient(mqttClientId)
                    } else {
                        return undefined
                    }
                })
                .then((mqttClient) => {
                    if (mqttClient) {
                        this.mqttClient = mqttClient
                        this.registerEventHandlers()
                    }
                    this.initialized = true
                    this.sortThings()
                    resolve({})
                })
                .catch((error) => {
                    console.log('thing.service.catch on initialize', error)
                    this.initialized = false
                    reject(error)
                })
        })
    }

    public sortThings(): void {
        this.things.sort((a, b) => { return a.label.localeCompare(b.label) })
    }

    private getThingType(thing: any): number {
        var thingTypeName = thing.thingTypeName
        thing.monitor_pack = false
        thing.control_pack = false
        thing.stove_pack = false
        thing.current_pack = false
        thing.advanced_pack = false
        if (thingTypeName) {
            thingTypeName = thingTypeName.toLowerCase()
            if (thingTypeName.indexOf('-cb') >= 0) {
                thing.current_pack = true
            }
            if (thingTypeName.indexOf('-adv') >= 0) {
                thing.advanced_pack = true
            }
            if (thingTypeName.indexOf('current-pack') >= 0) {
                thing.current_pack = true
                thing.advanced_pack = true
                return this.PACK_CURRENTBOX;
            } else if (thingTypeName.indexOf('stove-pack') >= 0) {
                thing.stove_pack = true
                thing.advanced_pack = true
                return this.PACK_STOVE;
            } else if (thingTypeName.indexOf('control-pack') >= 0) {
                thing.control_pack = true
                return this.PACK_CONTROL;
            } else if (thingTypeName.indexOf('monitor-pack') >= 0) {
                thing.monitor_pack = true
                return this.PACK_MONITOR;
            }
        }
        return 0
    }

    public getThingByName(thingName: string): any {
        let things: any[] = this.things.filter(thing => thing.name === thingName)
        let thing = things && things.length > 0 ? things[0] : undefined
        if (thing) {
            thing.thingType = this.getThingType(thing)
            //thing.enhancedControls = false;
            //thing.controls = false;
            //thing.current_pack = true
        }
        return thing
    }

    // Updates given thing shadow. Returns null if update fails, otherwise returns value that can used to
    // track when update is finished (status event).
    public updateShadow(thingName: string, payload: any): string {
        if (!this.mqttClient) {
            return null
        }
        let clientToken = this.mqttClient.update(thingName, payload)
        if (clientToken === null) {
            console.log('Thing ' + thingName + ' shadow update failed')
        }
        return clientToken
    }

    // Request shadow from given thing. Return client token that can be used to match
    // update response (register to shadowObservable to be notified when data is received)
    public getShadow(thingName: string): string {
        if (!this.mqttClient) {
            return null
        }
        return this.mqttClient.get(thingName)
    }

    public setFirmwareUpdatePending(thingName: string, value: boolean) {
        this.getThingByName(thingName).firmwareUpdatePending = value;
    }
    

    private registerEventHandlers(): void {
        console.log('thing.service.registerEventHandlers')
        this.mqttClient.on('connect', () => {
            this.onConnect()
        })
        this.mqttClient.on('disconnect', () => {
            this.onDisconnect()
        })
        this.mqttClient.on('status', (thingName, statusType, clientToken, stateObject) => {
            this.onStatus(thingName, statusType, clientToken, stateObject)
        })
        this.mqttClient.on('delta', (thingName, deltaStateObject) => {
            this.onDelta(thingName, deltaStateObject)
        })
        this.mqttClient.on('message', (topic, payload) => {
            this.onMessage(topic, payload)
        })
        this.mqttClient.on('foreignStateChange', (thingName, operation, stateObject) => {
            this.onForeignStateChange(thingName, operation, stateObject)
        })
    }

    private onConnect(): void {
        console.log('thing.service.onConnect')
        this.things.forEach(thing => {
            this.mqttClient.register(thing.name, { persistentSubscribe: true }, (err, failedTopis) => {
                if (!err) {
                    console.log('thing.service.onConnect.register ok ' + thing.name)
                    // Get current shadow values, will trigger status event.
                    this.mqttClient.get(thing.name, this.internalClientToken);
                } else {
                    console.log('thing.service.onConnect.register error ' + thing.name, err)
                }
            })
        })
    }

    private onDisconnect(): void {
        console.log('thing.service.onDisconnect')
        // TODO when will this happen? should we unregister mqtt client?
    }

    private onStatus(thingName, statusType, clientToken, stateObject): void {
        // console.log('thing.service.onStatus ' + thingName, stateObject)
        if (statusType === 'accepted') {
            if (clientToken === this.internalClientToken) {
                this.updateThingState(thingName, stateObject)
                this.readySource.next(thingName)
            }
        }
    }

    private onDelta(thingName, deltaStateObject): void {
        // console.log('thing.service.onDelta', thingName, deltaStateObject)
        // TODO check what to do when we get real delta values from somewhere
        // this.updateThingState(thingName, deltaStateObject.state)
    }

    private onMessage(topic, payload): void {
        console.log('thing.service.onMessage', topic, payload)
    }

    private onForeignStateChange(thingName, operation, stateObject): void {
        // console.log('thing.service.onForeignStateChange ' + thingName, stateObject)
        if (operation === 'update') {
            this.updateThingState(thingName, stateObject)
        } else {
            console.log('Received foreign state change with operation ' + operation)
        }
    }

    private updateThingState(thingName: any, stateObject: any) {
        let matchingThing = this.things.filter(thing => thing.name === thingName)
        if (matchingThing && matchingThing.length > 0) {
            let thingToUpdate = matchingThing[0]

            if (thingToUpdate.stateObject.fu === "1" && stateObject.state.reported.fu === "0") {
                // A firmware update has completed. Lets refresh the device list.
                this.setFirmwareUpdatePending(thingName, false)
                this.initialize(true);
            }

            // ignore missed history events
            if (stateObject.state.reported !== undefined && (stateObject.state.reported.dt === undefined || stateObject.state.reported.dt >= 0)) {
                let merged = Object.assign({}, thingToUpdate.stateObject, stateObject.state.reported)
                thingToUpdate.stateObject = merged
                thingToUpdate.stateObject.timestamp = stateObject.timestamp * 1000
                let mergedMD = Object.assign({}, thingToUpdate.stateObjectMetadata, stateObject.metadata.reported)
                thingToUpdate.stateObject.stateObjectMetadata = mergedMD
                thingToUpdate.updated = this.getLastUpdate(stateObject.metadata)
                // Notify we have new data
                this.stateSource.next(thingToUpdate)
            }
        }
    }

    private getLastUpdate(metadata: any): number {
        if (!metadata || !metadata.reported) {
            return 0
        }
        let stamps: number[] = [0]
        if (metadata.reported.mm0 && metadata.reported.mm0.timestamp) {
            stamps.push(Number(metadata.reported.mm0.timestamp) * 1000)
        }
        if (metadata.reported.cfg0 && metadata.reported.cfg0.timestamp) {
            stamps.push(Number(metadata.reported.cfg0.timestamp) * 1000)
        }
        if (metadata.reported.sh0 && metadata.reported.sh0.timestamp) {
            stamps.push(Number(metadata.reported.sh0.timestamp) * 1000)
        }
        if (metadata.reported.t1 && metadata.reported.t1.timestamp) {
            stamps.push(Number(metadata.reported.t1.timestamp) * 1000)
        }
        if (metadata.reported.t2 && metadata.reported.t2.timestamp) {
            stamps.push(Number(metadata.reported.t2.timestamp) * 1000)
        }
        if (metadata.reported.h1 && metadata.reported.h1.timestamp) {
            stamps.push(Number(metadata.reported.h1.timestamp) * 1000)
        }
        if (metadata.reported.c1 && metadata.reported.c1.timestamp) {
            stamps.push(Number(metadata.reported.c1.timestamp) * 1000)
        }
        if (metadata.reported.m1 && metadata.reported.m1.timestamp) {
            stamps.push(Number(metadata.reported.m1.timestamp) * 1000)
        }
        return Math.max.apply(Math, stamps)
    }

    public updateThingManualControl(thingName: any, stateObject: any)
    {
        this.awsService.getApiClient()
        .then((apiClient) => {
            let body = {
                thingName: thingName,
                configuration: stateObject.state.desired 
            }
            apiClient.manualControlPost(body)
        })       
    }

    public getThingManualControl(thingName: string) : Promise<any>
    {
        return new Promise<any>((resolve, reject) => {
            this.awsService.getApiClient()
            .then((apiClient) => {
                let params = {
                    headers: {
                        'Content-type': 'application/json'
                    },
                    thingName: thingName
                }
                var v = apiClient.manualControlGet(params)
                resolve(v)
            })
            .catch((error) => reject(error))
        })
    }

    public updateThingParms(thingName: any, parms: any)
    {
        this.awsService.getApiClient()
        .then((apiClient) => {
            let body = {
                thingName: thingName,
                parms: parms
            }
            apiClient.configurationPost(body)
        })
    }

    public updateThingConfiguration(thingName: any, stateObject: any)
    {
        this.awsService.getApiClient()
        .then((apiClient) => {
            let body = {
                thingName: thingName,
                configuration: stateObject.state.desired 
            }
            apiClient.configurationPost(body)
        })
    }

    public getThingConfiguration(thingName: string) : Promise<any>
    {
        return new Promise<any>((resolve, reject) => {
            this.awsService.getApiClient()
            .then((apiClient) => {
                let params = {
                    headers: {
                        'Content-type': 'application/json'
                    },
                    thingName: thingName
                }
                var v = apiClient.configurationGet(params)
                resolve(v)
            })
            .catch((error) => reject(error))
        })     
    }
}
