// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.


export const environment = {
    PRODUCTION: false,
    REGION: 'eu-north-1',
    USER_POOL_ID: 'eu-north-1_ZrVT2DafP',
    USER_POOL_CLIENT_ID: '5qlolshghvjqrumimbluuq5gf0',
    IDENTITY_POOL_ID: 'eu-north-1:f0d3e1d8-1674-44a4-a3d8-beb8f7776ffa',
    COGNITO_LOGIN_ID: 'cognito-idp.eu-north-1.amazonaws.com/eu-north-1_ZrVT2DafP',
    IOT_ADDRESS: 'a2i0tkb4wk6jg3-ats.iot.eu-north-1.amazonaws.com',
    API_GATEWAY_INVOKE_URL: 'https://i6uu64vgtb.execute-api.eu-north-1.amazonaws.com/prod'
};
/*
export const environment = {
    PRODUCTION: true,
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_vEF2cSkMm',
    USER_POOL_CLIENT_ID: 'lf89c9dffrjdsm92nt17tok0f',
    IDENTITY_POOL_ID: 'eu-west-1:430fe278-28ca-4b00-9725-6bb838995e99',
    COGNITO_LOGIN_ID: 'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_vEF2cSkMm',
    IOT_ADDRESS: 'a2m1kxbqz1ttl-ats.iot.eu-west-1.amazonaws.com',
    API_GATEWAY_INVOKE_URL: 'https://wlyewvkqpa.execute-api.eu-west-1.amazonaws.com/prod'
};
*/