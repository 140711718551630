<div class="row">
    <div class="offset-sm-3 col-sm-6">
        <legend>{{'forgot_password_verify.change_password' | translate}}&nbsp;<small class="float-right"><a routerLink="/login">{{'forgot_password_verify.login' | translate}}</a></small></legend>

        <div class="form-group">
            <label for="username">{{'forgot_password_verify.email' | translate}}</label>
            <input id="username" type="text" class="form-control" [(ngModel)]="username">
        </div>

        <div class="form-group">
            <label for="verificationCode">{{'forgot_password_verify.reset_code' | translate}}</label>
            <input id="verificationCode" type="text" class="form-control" [(ngModel)]="verificationCode">
            <small id="verificationHelp" class="form-text text-muted">{{'forgot_password_verify.reset_code_help' | translate}}</small>
        </div>

        <div class="form-group">
            <label for="newPassword">{{'forgot_password_verify.new_password' | translate}}</label>
            <input id="newPassword" type="password" class="form-control" [(ngModel)]="newPassword">
        </div>

        <button type="button" class="btn btn-primary" (click)="confirm()">{{'forgot_password_verify.confirm' | translate}}
            <app-spinner [loading]="loading"></app-spinner>
        </button>
    </div>
</div>
