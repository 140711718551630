import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { NgForm } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core';

import { AwsConfig } from './../../common/aws.config'
import { AwsService } from './../../common/aws.service'
import { SensorParameters } from './sensor-parameters.model'
import { MessageService } from '../../messages/message.service'

@Component({
    selector: 'app-sensor-parameters',
    templateUrl: './sensor-parameters.component.html',
    styleUrls: ['./sensor-parameters.component.css']
})
export class SensorParametersComponent implements OnInit {
    public loading: boolean = false
    public saving: boolean = false
    public errors: any = {}
    public parameters: SensorParameters
    private defaultParameters: SensorParameters = new SensorParameters(15, 1, 1, 1, 1, 1, 20, 20, 10, 100, 10)

    constructor(private router: Router, private awsService: AwsService, private messageService: MessageService,
        private translateService: TranslateService) { }

    ngOnInit() {
        this.loading = true
        this.readParameters()
            .then((data) => {
                if (data) {
                    this.parameters = Object.assign({}, this.defaultParameters, data)
                } else {
                    this.parameters = Object.assign({}, this.defaultParameters)
                }
                this.loading = false
            })
            .catch((error) => {
                console.log('sensor-parameters.ngOnInit error', error)
                this.loading = false
            })
    }

    public update(): void {
        let errors: Promise<any>[] = this.validate()
        Promise.all(errors).then((result) => {
            this.errors = []
            result.forEach(v => {
                if (v && v.field && v.result) {
                    this.errors[v.field] = v.result
                }
            })
            if (this.hasValidationErrors()) {
                console.log('sensor.parameter.update validation failed', this.errors)
            } else {
                this.saving = true
                this.createOrUpdateParameters()
                    .then(() => {
                        this.saving = false
                        this.messageService.info('admin.sensor_parameters.sensor_update_successful')
                    })
                    .catch((error) => {
                        this.saving = false
                        this.messageService.warning('admin.sensor_parameters.sensor_update_failed')
                    })
            }
        })

    }

    public hasValidationErrors(): boolean {
        if (this.errors && Object.keys(this.errors).filter(key => this.errors[key]).length > 0) {
            return true
        }
        return false
    }

    private validate(): Promise<any>[] {
        let errors: Promise<any>[] = []
        errors.push(this.check('parameters.time', this.parameters.time, 1, 60 * 60 * 24))
        errors.push(this.check('parameters.temperature', this.parameters.temperature, 0, 200))
        errors.push(this.check('parameters.roofTemperature', this.parameters.roofTemperature, 0, 200))
        errors.push(this.check('parameters.humidity', this.parameters.humidity, 0, 200))
        errors.push(this.check('parameters.co2', this.parameters.co2, 0, 2000))
        errors.push(this.check('parameters.noiseLevel', this.parameters.noiseLevel, 0, 200))

        errors.push(this.check('parameters.lowLimitTemperature', this.parameters.lowLimitTemperature, -200, 200))
        errors.push(this.check('parameters.lowLimitRoofTemperature', this.parameters.lowLimitRoofTemperature, -200, 200))
        errors.push(this.check('parameters.lowLimitHumidity', this.parameters.lowLimitHumidity, 0, 200))
        errors.push(this.check('parameters.lowLimitCo2', this.parameters.lowLimitCo2, 0, 1000))
        errors.push(this.check('parameters.lowLimitNoiseLevel', this.parameters.lowLimitNoiseLevel, 0, 200))

        return errors
    }

    private check(field: string, value: number, min: number, max: number): Promise<any> {
        if (value === null) {
            return this.translate(field, 'admin.sensor_parameters.validate_required')
        }
        if (!Number.isInteger(value)) {
            return this.translate(field, 'admin.sensor_parameters.validate_integer')
        }
        if (value < min) {
            return this.translate(field, 'admin.sensor_parameters.validate_min', min)
        }
        if (value > max) {
            return this.translate(field, 'admin.sensor_parameters.validate_max', max)
        }
        return Promise.resolve({})
    }

    private translate(field: string, key: string, value?: any): Promise<string> {
        return new Promise((resolve, reject) => {
            this.translateService.get(key, { value: value }).subscribe((result: string) => {
                Promise.resolve({ field: field, result: result })
            })
        })
    }

    private createOrUpdateParameters(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.awsService.getApiClient()
                .then((apiClient) => {
                    let params = this.defaultParams()
                    let body = this.parameters
                    let additionalParams = {}
                    return apiClient.parametersPost(params, body, additionalParams)
                })
                .then((result) => resolve({}))
                .catch((error) => reject(error))
        })
    }

    private readParameters(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.awsService.getApiClient()
                .then((apiClient) => {
                    let params = this.defaultParams()
                    let body = {}
                    let additionalParams = {}
                    return apiClient.parametersGet(params, body, additionalParams)
                })
                .then((result) => resolve(result.data))
                .catch((error) => reject(error))
        })
    }

    private defaultParams(): any {
        let params = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        return params
    }
}
