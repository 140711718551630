import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-datetime-picker',
    templateUrl: './datetime-picker.component.html',
    styleUrls: ['./datetime-picker.component.css']
})
export class DatetimePickerComponent implements OnInit {
    @Input()
    public value: Date;
    public dateValue: Date;
    public timeValue: Date;

    @Output()
    public valueChange = new EventEmitter();

    @Input()
    public showPopup = false;

    @Output()
    public showPopupChange = new EventEmitter();

    @Input()
    public showDate = true;

    @Input()
    public showTime = true;

    @Input()
    public showWeeks = false;

    @Input()
    public datepickerMode: string = 'day';

    @Input()
    public initDate: Date = null;

    @Input()
    public minDate: Date = null;

    @Input()
    public maxDate: Date = null;

    @Input()
    public dateDisabled: any[] = [];

    @Input()
    public texts: any = {
        close: 'Choose',
        now: 'Now'
    }

    constructor() { }

    ngOnInit() {
        if (this.value) {
            this.dateValue = new Date(this.value.getTime())
            this.timeValue = new Date(this.value.getTime())
        }
    }

    public offClick() {
        this.showPopup = false;
        this.showPopupChange.emit(false);
    }

    public onNow() {
        this.value = new Date();
        this.dateValue = new Date(this.value.getTime())
        this.timeValue = new Date(this.value.getTime())
    }

    public onClose(): void {
        let newValue = this.dateValue;
        if (this.timeValue) {
            newValue.setHours(this.timeValue.getHours())
            newValue.setMinutes(this.timeValue.getMinutes())
        }
        this.value = newValue
        this.valueChange.emit(this.value);
        this.offClick()
    }
}
