import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { AuthService } from '../auth/auth.service'
import { MessageService } from '../../messages/message.service'

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    public username: string
    public password: string
    public loading: boolean = false

    constructor(private authService: AuthService, public router: Router, private messageService: MessageService) { }

    ngOnInit() {
    }

    public register() {
        if (!this.username || this.username.length < 2 ||  !this.password || this.password.length < 2) {
            return
        }
        this.loading = true
        this.authService.register(this.username, this.password)
            .then(() => {
                this.loading = false
                this.router.navigate(['register/verify'], { queryParams: { email: this.username } })
            })
            .catch((err) => {
                this.loading = false
                if (err === 'registration-failed-invalid-password') {
                    this.messageService.warning('register.invalid_password')

                } else if (err == 'registration-failed-username-exists') {
                    this.messageService.warning('register.choose_another_email')

                } else {
                    this.messageService.warning('register.generic_error')
                }
            })
    }
}
