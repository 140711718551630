import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'

import { Subscription } from "rxjs"
import { ThingService } from '../thing.service'
import { DeviceService } from '../device.service'
import { AwsService } from '../../common/aws.service'
import { AuthService } from '../../user/auth/auth.service'

import { Schedule, Weekday, Time } from './schedule.model'
import { shareReplay } from 'rxjs-compat/operator/shareReplay';

@Component({
    selector: 'app-schedules',
    templateUrl: './schedules.component.html',
    styleUrls: ['./schedules.component.css']
})
export class SchedulesComponent implements OnInit, OnDestroy {
    // Listen route change, device change is done using url change
    private routeSubscription: Subscription
    private thingReadySubscription: Subscription
    private thingUpdateSubscription: Subscription
    

    private static MAX_SCHEDULES: number = 10
    private oldCfg0: string = undefined
    public selectedThing: any
    public schedules: Schedule[] = []
    public selectedSchedule: Schedule
    public datePickerVisible: boolean = false
    public loading: boolean = false
    public normalModeEdit: boolean = false
 
    constructor(private awsService: AwsService, private route: ActivatedRoute, private router: Router, private thingService: ThingService, private authService: AuthService) {
    }

    ngOnInit() {
        // In case of refresh or direct url navigation, the thing states are not available immediately and we need
        // to wait. BUT this observer is only triggered when initializing mqtt connection to things (meaning that
        // if initalization is done already somewhere else, this will not trigger at all).
        this.thingReadySubscription = this.thingService.readyObservable.subscribe(thingName => {
            if (this.selectedThing && this.selectedThing.name === thingName) {
                this.schedules = this.loadThingSchedules(this.selectedThing)
                this.loading = false
            }
        })
        this.normalModeEdit = this.authService.hasRole('scheduleedit');
        // Checks when device reports updated schedule and marks it as synced=true.
        this.thingUpdateSubscription = this.thingService.thingsObservable.subscribe(thing => {
            if (this.selectedThing && this.selectedThing.name === thing.name) {
                if (this.selectedThing.advanced_pack && ((this.selectedThing.stateObject && this.selectedThing.stateObject["cfg0"]) || this.oldCfg0 == undefined)) {
                    this.oldCfg0 = thing.stateObject['cfg0']
                    this.thingService.getThingConfiguration(thing.name)
                    .then((cfg) => {
                        if (cfg.data && cfg.data.payload) {
                            var cfgp = JSON.parse(cfg.data.payload);
                            if (cfgp.state && cfgp.state.reported) {
                                Object.keys(cfgp.state.reported).forEach(item => {
                                    thing.stateObject[item] = cfgp.state.reported[item]
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        console.log('getThingConfiguration failed', error)
                        this.oldCfg0 = ""
                    })
                }
                if (this.selectedThing.stateObject && this.selectedThing.stateObject["sh0"]) {
                    this.schedules.forEach(s => {
                        s.synced = true
                    })
                }
                if (this.selectedThing.stateObject && this.selectedThing.stateObject["sh1"]) {
                    let currentScheduleString = this.selectedThing.stateObject["sh1"]
                    this.schedules.forEach(schedule => {
                        schedule.active = false
                        var s = schedule.toShadow();
                        if (s == currentScheduleString) {
                            schedule.active = true
                        }
                    })
                }
                let reportedSchedules: Schedule[] = this.loadThingSchedules(thing)
                reportedSchedules.forEach(thingSchedule => {
                    let index: number = this.schedules.findIndex(s => s.id === thingSchedule.id)
                    if (index >= 0) {
                        let schedule: Schedule = this.schedules[index]
                        if (schedule.equals(thingSchedule)) {
                            schedule.synced = true
                        }
                    }
                })
            }
        })

        // TODO  what if there are no devices at all?
        this.routeSubscription = this.route.params.subscribe(params => {
            if (params['thing']) {
                this.loading = true
                let routeThing = this.thingService.getThingByName(params['thing'])
                if (routeThing && routeThing.controls === true) {
                    this.selectedThing = routeThing
                    this.schedules = this.loadThingSchedules(this.selectedThing)
                } else {
                    this.router.navigate(["dashboard"])
                }
                this.selectedSchedule = undefined
            } else {
                if (this.thingService.things && this.thingService.things.length > 0) {
                    let things = this.thingService.things.filter(t => t.controls)
                    if (things.length > 0) {
                        let defaultThing = things[0]
                        this.router.navigate(['/schedules', defaultThing.name], { replaceUrl: true })
                    } else {
                        this.router.navigate(["dashboard"])
                    }
                } else {
                    this.router.navigate(["dashboard"])
                }
                this.selectedSchedule = undefined
            }
        })
    }

    ngOnDestroy() {
        if (this.thingReadySubscription) {
            this.thingReadySubscription.unsubscribe()
        }
        if (this.thingUpdateSubscription) {
            this.thingUpdateSubscription.unsubscribe()
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe()
        }
    }

    public listThings(): any[] {
        return this.thingService.things.filter(t => t.controls)
    }

    public selectSchedule(schedule: Schedule) {
        this.selectedSchedule = schedule.copyOf()
        this.datePickerVisible = false
    }

    public newScheduleAllowed(): boolean {
        return (this.schedules.length < SchedulesComponent.MAX_SCHEDULES) && !this.selectedSchedule && !this.loading
    }

    public newSchedule(): void {
        if (this.newScheduleAllowed()) {
            let now: Date = new Date()
            let end: Date = new Date(now.getTime())

            let endMins = this.selectedThing.stateObject["ts1"]
            if (endMins === undefined)
                endMins = 120;

            end.setMinutes(end.getMinutes() + endMins)

            this.selectedSchedule = new Schedule(
                this.findFreeSlot(this.schedules),
                true,
                new Date(),
                new Date(),
                new Time(now.getHours(), now.getMinutes()),
                new Time(end.getHours(), end.getMinutes()),
                [
                    new Weekday(0, false),
                    new Weekday(1, false),
                    new Weekday(2, false),
                    new Weekday(3, false),
                    new Weekday(4, false),
                    new Weekday(5, false),
                    new Weekday(6, false)
                ],
                70, // sauna temp
                200, // stone temp
                120, // lid time
                0,
                this.selectedThing.enhancedControls,
                this.selectedThing.advanced_pack,
                false
            )
        }
    }

    public removeSchedule(schedule: Schedule): void {
        if (!schedule) {
            return
        }
        let index = this.schedules.findIndex(s => s.id === schedule.id)
        if (index >= 0) {
            this.schedules.splice(index, 1)
            if (this.selectedSchedule && schedule.id === this.selectedSchedule.id) {
                this.selectedSchedule = undefined
            }
            this.updateShadowSchedules()
        }
    }

    private findFreeSlot(schedules: Schedule[]): string {
        for (let i = 0; i < SchedulesComponent.MAX_SCHEDULES; i++) {
            let slot: string = 'sh' + (i + 1)
            let index: number = this.schedules.findIndex(s => s.id === slot)
            if (index >= 0) {
                let schedule: Schedule = this.schedules[index]
                if (!Schedule.validate(schedule.toShadow(), this.selectedThing.enhancedControls, this.selectedThing.advanced_pack)) {
                    return slot
                }
            } else {
                return slot
            }
        }
    }

    public saveScheduleEdit(): void {
        if (!this.validateSchedule(this.selectedSchedule)) {
            return
        }

        // Remove any weekday selections from single day schedule
        if (this.selectedSchedule.endsInSameDay()) {
            this.selectedSchedule.weekdays.forEach(wd => {
                wd.enabled = false
            })
        }

        let index = this.schedules.findIndex(s => s.id === this.selectedSchedule.id)
        this.selectedSchedule.synced = false
        if (index >= 0) {
            this.schedules[index] = this.selectedSchedule.copyOf()
        } else {
            this.schedules.push(this.selectedSchedule.copyOf())
        }
        this.selectedSchedule = undefined

        this.sort(this.schedules)
        this.updateShadowSchedules()
    }

    private validateSchedule(schedule: Schedule): boolean {
        if (!schedule) {
            return true
        }
        if (!this.validateEndDate(schedule)) {
            console.log('end date is not valid')
            return false
        }
        if (!this.validateWeekdays(schedule)) {
            console.log('weekdays is not valid')
            return false
        }
        if (!this.validateOverlaps(schedule, this.schedules)) {
            console.log('schedule overlaps with existing schedules')
            return false
        }
        return true
    }

    public validateEndDate(schedule: Schedule): boolean {
        if (!schedule.endDate) {
            return false
        }
        return schedule.endDate.getTime() >= schedule.startDate.getTime()
    }

    public validateWeekdays(schedule: Schedule): boolean {
        if (schedule.endsInSameDay()) {
            return true
        }
        return schedule.weekdays.filter(wd => wd.enabled === true).length > 0
    }

    public validateOverlaps(schedule: Schedule, existingSchedules: Schedule[]): boolean {
        return existingSchedules.filter(ex => {
            return ex.id !== schedule.id && ex.overlaps(schedule)
        }).length === 0
    }

    public formatTime(schedule: Schedule, startEnd: boolean): string {
        let t = Time.format(startEnd ? schedule.endTime : schedule.startTime)
        return t
    }

    public updateTime(schedule: Schedule) {
        this.formatTime(schedule, false)
        let t = Time.format(schedule.startTime)
        let d = this.getScheduleDuration(schedule)
        schedule.setScheduleDuration(d)
    }

    public parseTime(field: Time, input: string): void {
        let parsedValue: Time = Time.parse(input)
        if (parsedValue) {
            field.hours = parsedValue.hours
            field.minutes = parsedValue.minutes
        }
    }

    private updateShadowSchedules(): void {

        if (this.selectedThing.advanced_pack) {
            this.updateNewSchedules(this.selectedThing)
        } else {
            let payload: any = {}
            payload.state = {}
            payload.state.desired = {}
            for (let i = 0; i < SchedulesComponent.MAX_SCHEDULES; i++) {
                let slot: string = 'sh' + (i + 1)
                let index: number = this.schedules.findIndex(s => s.id === slot)
                if (index >= 0) {
                    let schedule: Schedule = this.schedules[index]
                    payload.state.desired[schedule.id] = schedule.toShadow()
                } else {
                    payload.state.desired[slot] = ''
                }
            }
            this.thingService.updateShadow(this.selectedThing.name, payload)
        }

    }

    public cancelScheduleEdit(): void {
        this.selectedSchedule = undefined
    }

    public toggleChange(schedule: Schedule): void {
        schedule.enabled = !schedule.enabled
        if (this.selectedThing.advanced_pack) {
            this.updateNewSchedules(this.selectedThing)
        } else {
            let payload: any = {}
            payload.state = {}
            payload.state.desired = {}
            payload.state.desired[schedule.id] = schedule.toShadow()
            this.thingService.updateShadow(this.selectedThing.name, payload)
        }
    }

    public toggleDatePicker(): void {
        this.datePickerVisible = !this.datePickerVisible
    }

    public startDateChanged(): void {
        if (this.selectedSchedule.startDate.getTime() > this.selectedSchedule.endDate.getTime()) {
            this.selectedSchedule.endDate = new Date(this.selectedSchedule.startDate.getTime())
        }
    }

    public isScheduleRunning(schedule: Schedule): boolean {
        if (this.selectedThing && this.selectedThing.stateObject && schedule) {
            let prField = this.selectedThing.stateObject['pr1']
            if (prField !== undefined) {
                return 'sh' + prField === schedule.id
            }
        }
        return false
    }

    private updateNewSchedules(thing: any) : void
    {
        this.awsService.getApiClient()
            .then((apiClient) => {
                let params = {
                    headers: {
                        'Content-type': 'application/json'
                    }
                }
                let sched : any = []
                let schCnt = 1
                this.schedules.forEach(schedule => {
                    let slot: string = 'sh' + schCnt
                    let txt = schedule.toShadow()
                    let sch = {}
                    sch[slot] = txt;
                    sched.push(sch)
                    schCnt++
                })
                let body = {
                    thingName: thing.name,
                    schedules: sched 
                }
                let additionalParams = {}
                apiClient.schedulesPost(params, body, additionalParams)
                this.schedules.forEach(schedule => {
                    schedule.synced = true
                })
            })
            .catch((error) => {
                console.log('updateNewSchedules API ERROR', error)
            })
    }

    private loadThingNewSchedules(thing: any): Schedule[] {
        let schedules: Schedule[] = []
        let schCnt = 1
 
        this.awsService.getApiClient()
            .then((apiClient) => {
                let params = {
                    headers: {
                        'Content-type': 'application/json'
                    },
                    thingName: thing.name
                }
                return apiClient.schedulesGet(params)
            })
            .then((result) => {
                if (result.data) {
                    var schp = result.data
                    Object.keys(schp).forEach(item => {
                        let pid = item.substring(3)
                        let vs = schp[item]
                        let userOwned = false
                        vs.forEach(shKey => {
                            if (shKey.userOwned && shKey.userOwned === true) {
                                userOwned = true
                            }
                        })
                        vs.forEach(scheduleStr => {
                            Object.keys(scheduleStr).forEach(shKey => {
                                if (shKey == "shid") {
                                    let shVal = scheduleStr[shKey]
                                    schCnt = shVal
                                }
                            })
                            Object.keys(scheduleStr).forEach(shKey => {
                                if (shKey.substring(0, 2) == "sh") {
                                    let shVal = scheduleStr[shKey]
                                    if (Schedule.validate(shVal, this.selectedThing.enhancedControls, this.selectedThing.advanced_pack)) {
                                        let newsh = Schedule.fromShadow('sh' + schCnt, shVal, this.selectedThing.enhancedControls, this.selectedThing.advanced_pack)
                                        newsh.pid = pid
                                        newsh.userOwned = userOwned
                                        schedules.push(newsh)
                                        schCnt++
                                    }  else {
                                        console.log('schedule.loadThingSchedules, invalid schedule format ' + shVal)
                                    }
                                }
                            })
                        });
                    });
                }
                this.sort(schedules)
                this.loading = false
                return schedules
            })
            .catch((error) => {
                console.log('loadThingSchedules failed', error)
                this.loading = false
            })
        return schedules
    }


    private loadThingSchedules(thing: any): Schedule[] {

        if (thing.advanced_pack) {
            return this.loadThingNewSchedules(thing)
        } else {
            let schedules: Schedule[] = []
            let stateObject: any = thing.stateObject

            if (Object.keys(stateObject).length === 0 && stateObject.constructor === Object) {
                return schedules
            }

            for (let i = 0; i < SchedulesComponent.MAX_SCHEDULES; i++) {
                let scheduleStr: string = stateObject['sh' + (i + 1)]
                if (Schedule.validate(scheduleStr, this.selectedThing.enhancedControls, false)) {
                    schedules.push(Schedule.fromShadow('sh' + (i + 1), scheduleStr, this.selectedThing.enhancedControls, this.selectedThing.advanced_pack))
                } else {
                    if (scheduleStr && scheduleStr.length > 0) {
                        console.log('schedule.loadThingSchedules, invalid schedule format ' + scheduleStr)
                    }
                }
            }
            this.sort(schedules)
            this.loading = false

            return schedules;
        }
    }

    private sort(schedules: Schedule[]): void {
        schedules.sort((a, b) => {
            let result: number = a.startDate.getTime() - b.startDate.getTime()
            if (result === 0) {
                result = a.startTime.hours - b.startTime.hours
            }
            if (result === 0) {
                result = a.startTime.minutes - b.startTime.minutes
            }
            if (result === 0) {
                result = a.endDate.getTime() - b.endDate.getTime()
            }
            if (result === 0) {
                result = a.endTime.hours - b.endTime.hours
            }
            if (result === 0) {
                result = a.endTime.minutes - b.endTime.minutes
            }
            return result
        })
    }
    
    public convertHoursMins(value: string): string {
        if (!value) return '-'
        let time = parseInt(value)
        if (Number.isNaN(time)) return '-'
        let hours: number = Math.floor((time / 60))
        let minutes: number = time - (hours * 60)
        return `${hours}h ${minutes}min`
    }

    public hasLid() : boolean
    {
        if (this.selectedThing.stateObject['cfg']) {
            let v = this.selectedThing.stateObject['cfg']
            return (v & 1) == 1
        } else {
            return false
        }
    }

    public getScheduleDuration(schedule : Schedule) : number {
        if (schedule.mode == 1) {
            let v = this.selectedThing.stateObject["ts2"]
            return v
        } else {
            let v = this.selectedThing.stateObject["ts1"]
            return v
        }
    }

    public setScheduleMode(value: number) : void
    {
        let duration = this.selectedSchedule.getScheduleDuration()
        if (value == 0) {
            if (this.selectedSchedule.mode == 1) {
                let v = this.selectedThing.stateObject["ts2"]
                if (v == duration) {
                    this.selectedSchedule.setScheduleDuration(this.selectedThing.stateObject["ts1"])
                }
            }
        } else {
            if (this.selectedSchedule.mode == 0) {
                let v = this.selectedThing.stateObject["ts1"]
                if (v == duration) {
                    this.selectedSchedule.setScheduleDuration(this.selectedThing.stateObject["ts2"])
                }
            }
        }
        this.selectedSchedule.mode = value
    }
}
