export class Firmware {
    public major: number
    public minor: number
    public patch: number

    public constructor(major: number, minor: number, patch: number) {
        this.major =  major
        this.minor = minor
        this.patch = patch
    }

    static toFirmware(version: string):Firmware{
        var versions = version.split(".");
        return new Firmware(Number(versions[0]),Number(versions[1]),Number(versions[2]));
    }
}