import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AwsService } from './../../common/aws.service'
import { ThingService } from '../../device/thing.service';
import { MessageService } from '../../messages/message.service';
import { AuthService } from '../../user/auth/auth.service'

@Component({
    selector: 'app-add-device',
    templateUrl: './add-device.component.html',
    styleUrls: ['./add-device.component.css']
})
export class AddDeviceComponent implements OnInit {
    public authenticationCode: string
    public userName: string
    public loading: boolean = false

    constructor(public router: Router, private awsService: AwsService, private thingService: ThingService, private messageService: MessageService, public authService: AuthService) { }

    ngOnInit() {
    }

    public addDevice(addm): void {
        if (!this.authenticationCode) {
            return
        }
        this.loading = true
        this.awsService.getApiClient()
            .then((apiClient) => {
                console.log('add-device adding device with authentication code ' + this.authenticationCode)
                return this.doApiCall(apiClient, this.authenticationCode, addm)
            })
            .then((result) => {
                console.log('add-device API OK', result)
                this.loading = false
                // Call initialize to update thing list and to register stuff for new thing
                this.thingService.initialize(true)
                    .then(() => {
                        this.router.navigate(["dashboard"])
                    })
                    .catch((error) => {
                        console.log('add-device thing initialize error', error)
                    })
            })
            .catch((error) => {
                console.log('add-device API NOK', error)
                this.loading = false
                if (error && error.status && error.status === 400) {
                    // TODO send message key values from api (or use some generic error?)
                    this.messageService.warning(error.data.message)
                }
            })
    }

    public addUserDevice(): void {
        if (!this.authenticationCode) {
            return
        }
        if (!this.userName) {
            return
        }
        this.loading = true
        this.awsService.getApiClient()
            .then((apiClient) => {
                console.log('add-device adding device with authentication code ' + this.authenticationCode + "' user '" + this.userName + "'")
                return this.doApiCall(apiClient, this.authenticationCode, false)
            })
            .then((result) => {
                console.log('add-device API OK', result)
                this.loading = false
                // Call initialize to update thing list and to register stuff for new thing
                this.thingService.initialize(true)
                    .then(() => {
                        this.router.navigate(["dashboard"])
                    })
                    .catch((error) => {
                        console.log('add-device thing initialize error', error)
                    })
            })
            .catch((error) => {
                console.log('add-device API NOK', error)
                this.loading = false
                if (error && error.status && error.status === 400) {
                    // TODO send message key values from api (or use some generic error?)
                    this.messageService.warning(error.data.message)
                }
            })
    }

    private doApiCallUserDevice(apiClient, authenticationCode, userName): Promise<any> {
        var params = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        var body = {
            authenticationCode: authenticationCode,
            userName: userName
        }
        var additionalParams = {}
        return apiClient.addMDevicePost(params, body, additionalParams)
    }


    private doApiCall(apiClient, authenticationCode, addm): Promise<any> {
        var params = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        var body = {
            authenticationCode: authenticationCode
        }
        var additionalParams = {}
        if (addm) {
            return apiClient.addMDevicePost(params, body, additionalParams)
        } else {
            return apiClient.addDevicePost(params, body, additionalParams)
        }
    }

}
