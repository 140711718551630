import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DatepickerModule, DatepickerConfig } from 'ngx-bootstrap/datepicker';

import { DatePickerComponent } from './date-picker.component';
import { OffClickDirective } from './offclick.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DatepickerModule.forRoot()
    ],
    declarations: [
        DatePickerComponent,
        OffClickDirective
    ],
    exports: [
        DatePickerComponent
    ],
    entryComponents: [
        DatePickerComponent
    ],
    providers: [
        DatepickerConfig
    ]
})

export class DatePickerModule {

}
