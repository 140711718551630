import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'Saunamonitor'

    constructor(private router: Router, private translateService: TranslateService) {
        this.translateService.setDefaultLang('en')
        let storedLanguage = localStorage.getItem('language')
        if (storedLanguage) {
            this.translateService.use(storedLanguage)
        } else {
            this.translateService.use('en')
        }
    }

    public currentRoute(): string {
        return this.router.url
    }
}
