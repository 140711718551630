import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/Observable'
import { TimerObservable } from "rxjs/observable/TimerObservable";
import { BehaviorSubject } from 'rxjs/BehaviorSubject'

import { TranslateService } from '@ngx-translate/core';

import { Message } from './message.model'

@Injectable()
export class MessageService {
    public messages: Message[] = []
    private timerSubscription: any;
    private readonly MaximumNumberOfMessages: number = 4

    constructor(private translateService: TranslateService) {
        let intervalMs = 2 * 1000
        this.timerSubscription = TimerObservable.create(intervalMs, intervalMs)
            .subscribe(t => this.autoremoveMessages())
    }

    public info(message: string): void {
        this.message('INFO', message)
    }

    public warning(message: string): void {
        this.message('WARNNG', message)
    }

    private message(type: string, message: string): void {
        this.translateService.get(message).subscribe(
            result => {
                this.messages.unshift(new Message(type, result, new Date()))
                if (this.messages.length > this.MaximumNumberOfMessages) {
                    this.messages.pop()
                }
            },
            error => {
                console.log('ERROR when getting translation', error)
            })
    }

    public remove(message) {
        if (this.messages.length > 0) {
            let index = this.messages.indexOf(message)
            if (index >= 0) {
                this.messages.splice(index, 1)
            }
        }
    }

    private autoremoveMessages(): void {
        try {
            let tenSecondsAgo = new Date(new Date().getTime() - 10 * 1000)
            let oldMessages = this.messages.filter((message) => message.type === 'INFO' && message.created < tenSecondsAgo)
            oldMessages.forEach((msg) => this.remove(msg))
        } catch (ignored) {
        }
    }
}
