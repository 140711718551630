<div class="row">
    <div class="offset-sm-3 col-sm-6">
        <legend *ngIf="verifyType === 'registration'">{{'verify.verify_account' | translate}}&nbsp;
            <small class="float-right"><a routerLink="/login">{{'verify.login' | translate}}</a></small>
        </legend>

        <legend *ngIf="verifyType === 'changeEmail'">{{'verify.verify_email_change' | translate}}</legend>

        <p>
            <small class="text-muted">{{'verify.verify_email_sent' | translate}}</small>
        </p>


        <div class="form-group row">
            <label for="username" class="col-sm-4 col-form-label">{{'verify.email' | translate}}</label>
            <div class="col-sm-8">
                <input id="username" type="text" class="form-control" [(ngModel)]="username" disabled>
            </div>
        </div>

        <div class="form-group row">
            <label for="verificationCode" class="col-sm-4 col-form-label">{{'verify.verification_code' | translate}}</label>
            <div class="col-sm-8">
                <input id="verificationCode" type="text" class="form-control" [(ngModel)]="verificationCode">
            </div>
        </div>

        <div class="form-group row">
            <div class="offset-sm-4 col-sm-8">
                <button type="button" class="btn btn-primary" (click)="verify()">{{'verify.verify' | translate}}</button>
            </div>
        </div>

    </div>
</div>
