<div class="dropdown" [ngClass]="{ 'show': showPopup === true }" (offClick)="offClick()">
    <ul class="dropdown-menu" role="menu" [ngClass]="{ 'show': showPopup === true }">
        <li class="my-2 mx-2">
            <datepicker
                [(ngModel)]="value"
                (ngModelChange)="dateChanged($event)"
                [showWeeks]="false"
                [startingDay]="1">
            </datepicker>
        </li>
    </ul>
</div>
