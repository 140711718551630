<div *ngIf="!editing">
    <ng-content></ng-content>&nbsp;
    <i class="fa fa-pencil-square-o" aria-hidden="true" (click)="onEdit()"></i>
</div>
<div class="input-group" *ngIf="editing">
    <input type="text" class="form-control" [(ngModel)]="modifiedText">
    <span class="input-group-addon" (click)="onSave()">
        <i class="fa fa-check" aria-hidden="true"></i>
    </span>
    <span class="input-group-addon" (click)="onCancel()">
        <i class="fa fa-times" aria-hidden="true"></i>
    </span>
</div>
