export class TimeRangeOption {
    public label: string
    public param: string
    public range: number

    public constructor(label:string, param: string, range: number) {
        this.label = label
        this.param = param
        this.range = range
    }
}
