<span class="">

    <div class="card manual-control-box" *ngIf="thingControls">

        <div *ngIf="!selectedThing.stove_pack && !selectedThing.control_pack && manualControl" class="manual-control-header">
            {{'manual_control.controller' | translate}}
        </div>

        <div *ngIf="selectedThing.stove_pack || manualControl" class="manual-control-header big-title">
            {{'manual_control.stove_status_big' | translate}}:
            <span>
                <ng-template [ngIf]="sensorValue('st')>=1">
                    <span class="big-title-power-on">&nbsp;ON&nbsp;</span>
                    <span *ngIf="deviceOn() && !scheduleOn()">&nbsp;manual mode</span>
                    <span *ngIf="scheduleOn()">&nbsp;schedule mode</span>
                </ng-template><ng-template [ngIf]="sensorValue('st')==0 || sensorValue('st')=='-'">
                    <span  class="big-title-power-off">&nbsp;OFF&nbsp;</span>
                </ng-template>
                <span *ngIf="!syncing" class="led" [ngClass]="{'led-green': deviceOn(), 'led-red': !deviceOn()}"></span>
            </span>
            <app-spinner class="spinner" [loading]="syncing"></app-spinner>
        </div>

        <ul class="list-group list-group-flush" *ngIf="manualControl">

            <li class="list-group-item" *ngIf="!selectedThing.stove_pack && normalModeEdit">
                <span class="manual-control-container">
                    <span>
                        <button type="button" class="button-label on" [ngClass]="{'activated' : deviceOn()}" (click)="setPower(true)" [disabled]="!canEdit()">{{'manual_control.on' | translate}}</button>
                        <button type="button" class="button-label off" [ngClass]="{'activated' : !deviceOn()}" (click)="setPower(false)" [disabled]="!canEdit()">{{'manual_control.off' | translate}}</button>
                        <span style="float: right;">
                            <span *ngIf="!syncing" class="led" [ngClass]="{'led-green': deviceOn(), 'led-red': !deviceOn()}"></span>
                            <app-spinner class="spinner" [loading]="syncing"></app-spinner>
                        </span>
                    </span>
                </span>
            </li>

            <li class="list-group-item">
                <span class="manual-control-container" *ngIf="selectedThing.stove_pack && manualControl && normalModeEdit">
                    <div class="big-label">
                        <label class="button-text">{{'manual_control.stove_status' | translate}}&nbsp;&nbsp;</label>
                        <button *ngIf="sensorValue('st')>=1" type="button" class="button-label" (click)="setPower(false)" [disabled]="!canEdit()"><span class="big-title-power-bold">{{'manual_control.on2' | translate}}.</span> {{'manual_control.click_power' | translate}} <span class="big-title-power-off">&nbsp;{{'manual_control.off2' | translate}}&nbsp;</span></button>
                        <button *ngIf="sensorValue('st')<=0" type="button" class="button-label" (click)="setPower(true)" [disabled]="!canEdit()"><span class="big-title-power-bold">{{'manual_control.off2' | translate}}.</span> {{'manual_control.click_power' | translate}} <span class="big-title-power-on">&nbsp;{{'manual_control.on2' | translate}}&nbsp;</span></button>
                        &nbsp;<label class="manual-tooltip" data-text="Click to power stove on or off">?</label>
                    </div>
                </span>

                <!-- current/monitor/control -->
                <span class="manual-control-container" *ngIf="manualControl && !selectedThing.stove_pack">
                    <div class="big-label">
                        <label>{{'manual_control.time_remaining' | translate}}:</label>
                        &nbsp;&nbsp;
                        <label class="manual-tooltip" data-text="Time remaining before stove is automatically powered off. Can't be modified in schedule mode.">?</label>
                    </div>
                    <div class="slider">
                        <input [(ngModel)]="this.duration" (ngModelChange)="triggerModelChangedDuration()" type="range" min="0" max="{{this.INFINITEDURATION}}" step="1" [disabled]="!canEdit() || !deviceOn() || scheduleOn()">
                        <span class="duration-label">&nbsp;&nbsp;{{getManualControlDuration(this.duration)}}</span>
                    </div>
                    <div class="big-label">
                        <label>{{'manual_control.programmed_temperature' | translate}}:</label>
                        &nbsp;&nbsp;
                        <label class="manual-tooltip" data-text="Programmed sauna temperature. Can't be modified in schedule mode.">?</label>
                    </div>
                    <div class="slider">
                        <input [(ngModel)]="this.saunaTemperature" (ngModelChange)="triggerModelChanged()" [disabled]="!canEdit() || scheduleOn()" type="range" min="20" max="120">
                        <span class="temperature-label">&nbsp;&nbsp;{{this.saunaTemperature}}°C</span>
                    </div>
                </span>

                <!-- stove -->
                <span class="manual-control-container" *ngIf="manualControl && selectedThing.stove_pack">
                    <div class="big-label">
                        <label>{{'manual_control.time_remaining' | translate}}:</label>
                        &nbsp;&nbsp;
                        <label class="manual-tooltip" data-text="Time remaining before stove is automatically powered off. Can't be modified when stove is powered off or in schedule mode.">?</label>
                    </div>
                    <div class="slider">
                        <input [(ngModel)]="this.duration" (ngModelChange)="triggerModelChangedDuration()" type="range" min="0" max="{{this.INFINITEDURATION}}" step="1" [disabled]="!deviceOn() || scheduleOn() || !canEdit()">
                        <span class="duration-label">&nbsp;&nbsp;{{getManualControlDuration(this.duration)}}</span>
                    </div>
                    <div class="big-label">
                        <label>{{'manual_control.programmed_temperature' | translate}}:</label>
                        &nbsp;&nbsp;
                        <label class="manual-tooltip" data-text="Programmed sauna temperature. Can't be modified when stove is powered off or in schedule mode.">?</label>
                    </div>
                    <div class="slider">
                        <input [(ngModel)]="this.saunaTemperature" (ngModelChange)="triggerModelChanged()"  [disabled]="!canEdit()" type="range" min="20" max="120" >
                        <span class="temperature-label">&nbsp;&nbsp;{{this.saunaTemperature}}°C</span>
                    </div>
                    <div *ngIf="this.thingEnhancedControls" class="big-label">
                        <label>{{'manual_control.programmed_stove_temperature' | translate}}:</label>
                        &nbsp;&nbsp;
                        <label class="manual-tooltip" data-text="Programmed stove temperature. Can't be modified when stove is powered off or in schedule mode.">?</label>
                    </div>
                    <div *ngIf="this.thingEnhancedControls" class="slider">
                        <input [(ngModel)]="this.stoveTemperature" (ngModelChange)="triggerModelChanged()" type="range" min="50" max="400" [disabled]="!deviceOn() || scheduleOn() || !canEdit()">
                        <span class="temperature-label">&nbsp;&nbsp;{{this.stoveTemperature}}°C</span>
                    </div>
                </span>

                <span class="manual-control-container" *ngIf="manualControl && selectedThing.stove_pack && hasLid() && normalModeEdit">
                    <span class="slider">
                        <div class="big-label">
                            <label class="button-text">{{'manual_control.lid_status' | translate}}:</label>
                            <button *ngIf="sensorValue('l2')==0" class="button-label" [disabled]="sensorValue('pr1')==255 || !canEdit()" [ngClass]="{'activated' : manualControl.power}" (click)="setLid(true)"><span  class="big-title-power-off">&nbsp;Closed.&nbsp;</span>&nbsp;Click to <span class="big-title-power-on">&nbsp;Open&nbsp;</span></button>
                            <button *ngIf="sensorValue('l2')==2" class="button-label" disabled="true">Stopped</button>
                            <button *ngIf="sensorValue('l2')==4" class="button-label" disabled="true">Opening</button>
                            <button *ngIf="sensorValue('l2')==5" class="button-label" disabled="true">Closing</button>
                            <button *ngIf="sensorValue('l2')==6" class="button-label" disabled="true">Closing</button>
                            <button *ngIf="sensorValue('l2')==1" class="button-label" [disabled]="sensorValue('pr1')==255 || !canEdit()" [ngClass]="{'activated' : manualControl.power}" (click)="setLid(false)"><span  class="big-title-power-on">&nbsp;Open.&nbsp;</span>&nbsp;Click to <span class="big-title-power-off">&nbsp;Close&nbsp;</span></button>
                            &nbsp;<label class="manual-tooltip" data-text="Click to open or close the lid. Lid can't be opened when stove is powered off.">?</label>
                        </div>
                        <div class="big-label">
                            Lid can't be opened when stove is powered off.
                        </div>


                        <div class="big-label">
                            <label>{{'manual_control.lid_time_remaining' | translate}}:</label>
                            &nbsp;&nbsp;
                            <label class="manual-tooltip" data-text="Time remaining before lid closes automatically. Can't be modified when stove is powered off or in schedule mode.">?</label>
                        </div>
                        <div class="big-label">
                            <input [(ngModel)]="this.lidDuration" (ngModelChange)="triggerModelChanged()" type="range" min="0" max="360" step="5" [disabled]="!deviceOn() || scheduleOn() || !canEdit()">
                            <span class="duration-label">&nbsp;&nbsp;{{convertHoursMins(this.lidDuration)}}</span>
                        </div>

                    </span>
                </span>

                <span class="manual-control-container" *ngIf="manualControl && selectedThing.stove_pack && !hasLid() && normalModeEdit">
                    <span class="slider">
                        <div class="big-label">
                            <label class="button-text">{{'manual_control.lid2_status' | translate}}:</label>
                            <button *ngIf="sensorValue('l2')==0" class="button-label" [disabled]="sensorValue('pr1')==255 || !canEdit()" [ngClass]="{'activated' : manualControl.power}" (click)="setLid(true)"><span  class="big-title-power-off">&nbsp;Inactive.&nbsp;</span>&nbsp;Click to <span class="big-title-power-on">&nbsp;Activate&nbsp;</span></button>
                            <button *ngIf="sensorValue('l2')==1" class="button-label" [disabled]="sensorValue('pr1')==255 || !canEdit()" [ngClass]="{'activated' : manualControl.power}" (click)="setLid(false)"><span  class="big-title-power-on">&nbsp;Active.&nbsp;</span>&nbsp;Click to set<span class="big-title-power-off">&nbsp;Inactive&nbsp;</span></button>
                            &nbsp;<label class="manual-tooltip" data-text="Click to activate or inactivate stove. Not available when stove is powered off.">?</label>
                        </div>

                        <div class="big-label">
                            <label>{{'manual_control.lid2_time_remaining' | translate}}:</label>
                            &nbsp;&nbsp;
                            <label class="manual-tooltip" data-text="Time remaining before stove switches to inactive state. Can't be modified when stove is powered off or in schedule mode.">?</label>
                        </div>
                        <div class="big-label">
                            <input [(ngModel)]="this.lid2Duration" (ngModelChange)="triggerModelChanged()" type="range" min="0" max="360" step="5" [disabled]="!deviceOn() || scheduleOn() || !canEdit()">
                            <span class="duration-label">&nbsp;&nbsp;{{convertHoursMins(this.lidDuration)}}</span>
                        </div>

                    </span>
                </span>

                <span class="manual-control-container" *ngIf="manualControl && thingControls && scheduleModeEdit">
                    <div class="big-label">
                    	<label class="button-text">{{'schedules.schedules' | translate}}:&nbsp;&nbsp;</label>
                        <button type="button" class="button-label" href="#" routerLink="/schedules/{{selectedThing.name}}">{{'schedules.modify_schedule' | translate}}</button>
                        &nbsp;<label class="manual-tooltip" data-text="Create, modify or delete schedules.">?</label>
                    </div>
                </span>
                <span class="manual-control-container" *ngIf="manualControl && thingControls && !scheduleModeEdit">
                    <div class="big-label">
                    	<label class="button-text">{{'schedules.schedules' | translate}}:&nbsp;&nbsp;</label>
                        <button type="button" class="button-label" href="#" routerLink="/schedules/{{selectedThing.name}}">{{'schedules.open_schedule' | translate}}</button>
                        &nbsp;<label class="manual-tooltip" data-text="Check schedules.">?</label>
                    </div>
                </span>

            </li>
        </ul>
    </div>

    <div class="card manual-control-box" *ngIf="manualControl && !selectedThing.stove_pack">
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <span class="manual-control-container slider">
                    <ng-template #temperatureTooltip>{{'details.tooltip.temperature' | translate}}</ng-template>
                    <div [tooltip]="temperatureTooltip" *ngIf="hasSensorValue('t1') || hasSensorValue('t2')">
                        <label class="measurements-name-label">{{'details.temperature' | translate}}</label>
                        <span class="measurements-label">
                            {{ sensorValue('t1') }}°C
                        </span>
                        <span class="measurements-label" *ngIf="adminMode && hasSensorValue('t2')">
                            ({{ sensorValue('t2') }}°C)
                        </span>
                    </div>
                    <ng-template #roofTemperatureTooltip>{{'details.tooltip.roof_temperature' | translate}}</ng-template>
                    <div [tooltip]="roofTemperatureTooltip" *ngIf="!thingEnhancedControls && !selectedThing.advanced_pack">
                        <label class="measurements-name-label">{{'details.roof_temperature' | translate}}</label>
                        <span class="measurements-label">
                            {{ sensorValue('t2') }}°C
                        </span>
                    </div>
                    <ng-template #humidityTooltip>{{'details.tooltip.humidity' | translate}}</ng-template>
                    <div [tooltip]="humidityTooltip" *ngIf="hasSensorValue('h1') || hasSensorValue('h2')">
                        <label class="measurements-name-label">{{'details.humidity' | translate}}</label>
                        <span class="measurements-label">
                            {{ sensorValue('h1') }}% 
                        </span>
                        <span class="measurements-label" *ngIf="adminMode && hasSensorValue('h2')">
                            ({{ sensorValue('h2') }}%)
                        </span>
                    </div>
                    <ng-template #indexTooltip>{{'details.tooltip.index' | translate}}</ng-template>
                    <div [tooltip]="indexTooltip"  *ngIf="(hasSensorValue('t1') || hasSensorValue('t2')) || (hasSensorValue('h1') || hasSensorValue('h2'))">
                        <label class="measurements-name-label">{{'details.index' | translate}}</label>
                        <span class="measurements-label">
                            {{ sensorValue('ix') }}
                        </span>
                    </div>
                    <ng-template #co2Tooltip>{{'details.tooltip.co2' | translate}}</ng-template>
                    <div [tooltip]="co2Tooltip" *ngIf="hasSensorValue('c1')">
                        <label class="measurements-name-label">{{'details.co2' | translate}}</label>
                        <span class="measurements-label">
                            {{ sensorValue('c1') }}
                        </span>
                    </div>
                    <ng-template #noiseTooltip>{{'details.tooltip.noise_level' | translate}}</ng-template>
                    <div [tooltip]="noiseTooltip" *ngIf="hasSensorValue('m1')">
                        <label class="measurements-name-label">{{'details.noise_level' | translate}}</label>
                        <span class="measurements-label">
                            {{ sensorValue('m1') }}db
                        </span>
                    </div>
                    <div *ngIf="getState()>=0 && hasSensorValue('t1')">
                        <label class="measurements-name-label">{{'details.heaters' | translate}}</label>
                        <span class="measurements-label" *ngIf="getState()<3">
                            Off {{heaterMinutes()}}
                        </span>
                        <span class="measurements-label" *ngIf="getState()==3">
                            On {{heaterMinutes()}}
                        </span>
                    </div>
                    <div *ngIf="selectedThing.current_pack && isHeaters() && hasSensorValue('t1')">
                        <label class="measurements-name-label">&nbsp;</label>
                        <span class="measurements-label">
                            {{ sensorValue('ht1') }}/{{ sensorValue('ht2') }}/{{ sensorValue('ht3') }} }}
                        </span>
                    </div>
                    <div>
                        <span class="measurements-label" *ngIf="selectedThing.updated">
                            {{'details.last_updated' | translate}} {{selectedThing.updated | date:'dd.MM.yyyy HH:mm:ss'}}
                        </span>
                    </div>
                </span>
             </li>
        </ul>
    </div>

    <div class="card manual-control-box" *ngIf="manualControl && selectedThing.stove_pack">
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <span class="manual-control-container slider">
                    <div>
                        <label class="measurements-name-label">{{'details.sauna_temperature' | translate}}</label>
                        <span class="measurements-label">
                            {{ sensorValue('t1') }}°C
                        </span>
                    </div>
                    <div>
                        <label class="measurements-name-label">{{'details.stove_temperature' | translate}}</label>
                        <span class="measurements-label">
                            {{ sensorValue('t3') }}°C
                        </span>
                    </div>
                    <div>
                        <label class="measurements-name-label">{{'details.pcb' | translate}}</label>
                        <span class="measurements-label">
                            {{ sensorValue('t4') }}°C
                        </span>
                    </div>
                    <div>
                        <label class="measurements-name-label">{{'details.humidity' | translate}}</label>
                        <span class="measurements-label">
                            {{ sensorValue('h1') }}%
                        </span>
                    </div>
                    <div>
                        <label class="measurements-name-label">{{'details.index' | translate}}</label>
                        <span class="measurements-label">
                            {{ sensorValue('ix') }}
                        </span>
                    </div>
                    <div>
                        <label class="measurements-name-label">{{'details.co2' | translate}}</label>
                        <span class="measurements-label">
                            {{ sensorValue('c1') }}
                        </span>
                    </div>
                    <div>
                        <label class="measurements-name-label">{{'details.noise_level' | translate}}</label>
                        <span class="measurements-label">
                            {{ sensorValue('m1') }}db
                        </span>
                    </div>
                    <div>
                        <label class="measurements-name-label">{{'details.heaters' | translate}}</label>
                        <span class="measurements-label" *ngIf="!isHeaters()">
                            Off
                        </span>
                        <span class="measurements-label" *ngIf="isHeaters()">
                            On
                        </span>
                    </div>
                    <div *ngIf="isHeaters()">
                        <label class="measurements-name-label">&nbsp;</label>
                        <span class="measurements-label">
                            {{ sensorValue('ht1') }}/{{ sensorValue('ht4') }}/{{ sensorValue('ht2') }}/{{ sensorValue('ht5') }}/{{ sensorValue('ht3') }}/{{ sensorValue('ht6') }}
                        </span>
                    </div>
                    <div>
                        <label class="measurements-name-label">{{'details.fan' | translate}}</label>
                        <span class="measurements-label">
                        	{{sensorValue('f2')}}%
                        </span>
                    </div>
                    <div>
                        <span class="measurements-label" *ngIf="isActive()">
                            {{'details.last_updated' | translate}} {{selectedThing.updated | date:'dd.MM.yyyy HH:mm:ss'}}
                        </span>
                        <span class="measurements-label-warning" *ngIf="!isActive()">
                            {{'details.last_updated' | translate}} {{selectedThing.updated | date:'dd.MM.yyyy HH:mm:ss'}}
                        </span>
                    </div>
                </span>
             </li>
        </ul>
    </div>

</span>
