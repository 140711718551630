<span class="badge badge-primary link-text" (click)="show()">&nbsp;?&nbsp;</span>

<div class="modal" *ngIf="visible">
    <div class="modal-box">
        <div class="modal-box-content">
            <div class="title">{{title}} <a class="modal-box-close" (click)="hide()">&times;</a></div>
            <div class="text">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
