import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/Observable'

import { AwsConfig } from '../common/aws.config'
import { AwsService } from '../common/aws.service'

declare var AWS: any;
var awsIot = require('aws-iot-device-sdk')

@Injectable()
export class DeviceService {

    constructor(private awsConfig: AwsConfig, private awsService: AwsService) {
    }

    public listThings(admin: boolean): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.awsService.getApiClient()
                .then((apiClient) => {
                    let params = {
                        headers: {
                            'Content-type': 'application/json'
                        }
                    }
                    let body = {}
                    let additionalParams = {}
                    if (admin) {
                        return apiClient.listDevicesGetFull(params, body, additionalParams)
                    }
                    return apiClient.listDevicesGet(params, body, additionalParams)
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log('device.service.listThings ERROR', error)
                    reject(error)
                })
        });
    }

    public createMqttClient(clientId: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.awsService.getCredentials()
                .then((awsCredentials) => {
                    let mqttClient = awsIot.thingShadow({
                        region: AwsConfig.REGION,
                        clientId: clientId,
                        protocol: 'wss',
                        host: AwsConfig.IOT_ADDRESS,
                        maximumReconnectTimeMs: 8000,
                        debug: true,
                        accessKeyId: awsCredentials.accessKeyId,
                        secretKey: awsCredentials.secretAccessKey,
                        sessionToken: awsCredentials.sessionToken
                    })
                    resolve(mqttClient)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public updateMqttClientCredentials(mqttClient: any) {
        if (!mqttClient) {
            console.log('device.service.updateMqttClientCredentials mqtt client is not initialized')
            return
        }
        this.awsService.getCredentials()
            .then((awsCredentials) => {
                console.log('device.service.updateMqttClientCredentials with credentials', awsCredentials)
                mqttClient.updateWebSocketCredentials(
                    awsCredentials.accessKeyId,
                    awsCredentials.secretAccessKey,
                    awsCredentials.sessionToken)
            })
            .catch((err) => {
                console.log('device.service.updateMqttClientCredentials Failed to update web socket credentials', err)
            })
    }
}
