import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from "rxjs"
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

import { ThingService } from '../thing.service'
import { AwsService } from '../../common/aws.service'
import { MessageService } from '../../messages/message.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    public confirm: boolean[] = []
    public thingEditData:any = {}
    public showModalPopUp : boolean = false;

    constructor(private thingService: ThingService, private awsService: AwsService, private messageService: MessageService) {
    }

    ngOnInit() {
    }

    public listThings(): any[] {
        return this.thingService.things
    }

    public toNumber(input): number {
        return Number(input)
    }

    public getThingName(thing) : string {
        if (thing.userOwned) {
            return thing.label
        }
        return "[" + thing.label + "]"
    }

    public power(thing): void {
        let manualMode: String = '1_60_120'
        if (thing.stateObject && thing.stateObject['mm']) {
            manualMode = thing.stateObject['mm']
            let params: string[] = manualMode.split('_')
            let power: number = (Number(params[0]) === 1) ? 0 : 1
            manualMode = power + '_' + params[1] + '_' + params[2]
        }

        let payload: any = {}
        payload.state = {}
        payload.state.desired = {}
        payload.state.desired['mm'] = manualMode
        this.thingService.updateShadow(thing.name, payload)
    }

    // -1 = state not available, 0 = closed, 1 = open
    public LidOpen(thing): number {
        if (!thing || !thing.stateObject || thing.stateObject.pr1 === undefined) {
            return -1
        }
        let pr1Value: number = Number(thing.stateObject.pr1)
        return pr1Value === 255 ? 0: 1
    }

    // -1 = state not available, 0 = off, 1 = on
    public deviceOn(thing): number {
        if (!thing || !thing.stateObject || thing.stateObject.pr1 === undefined) {
            return -1
        }
        let pr1Value: number = Number(thing.stateObject.pr1)
        return pr1Value === 255 ? 0: 1
    }


    public toValue(input) {
        if (input === undefined) {
            return '-'
        }
        return Number(Number(input).toFixed(0))
    }

    public checkWarning(thing): any {
        return {
            t1: thing && thing.stateObject && thing.stateObject.t1 >= 100,
            t2: thing && thing.stateObject && thing.stateObject.t2 >= 100
        }
    }

    public saveName(event, thing): void {
        // could update label right away, and rollback previous value if save fails
        this.awsService.getApiClient()
            .then((apiClient) => this.namesPost(apiClient, thing, event))
            .then(() => {
                thing.label = event
                this.thingService.sortThings()
            })
            .catch((error) => {
                console.log('dashboard.saveName error', error)
                this.messageService.warning('dashboard.failed_to_save_device_name')
            })
    }

    private namesPost(apiClient, thing, name): Promise<any> {
        var params = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        var body = {
            thingName: thing.name,
            name: name
        }
        var additionalParams = {}
        return apiClient.namesPost(params, body, additionalParams)
    }

    public removeDevice(thing): void {
        this.confirm[thing.name] = false
        this.awsService.getApiClient()
            .then((apiClient) => {
                return this.removeDevicePost(apiClient, thing.name)
            })
            .then(() => {
                return this.thingService.initialize(true)
            })
            .catch((error) => {
                console.log('remove-device API NOK', error)
                if (error && error.status && error.status === 400) {
                    // TODO send message key values from api (or use some generic error?)
                    this.messageService.warning(error.data.message)
                }
            })
    }

    public cancelRemoveDevice(thing): void {
        this.confirm[thing.name] = false
    }

    private removeDevicePost(apiClient, thingName) {
        var params = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        var body = {
            thingName: thingName
        }
        var additionalParams = {}
        return apiClient.removeDevicePost(params, body, additionalParams)
    }
    
    private updateParams(thingEditData): void {
        let payload: any = {}
        payload.state = {}
        payload.state.desired = {}
        let modified: boolean = false
        if (thingEditData.lt1_o != thingEditData.lt1) {
        	payload.state.desired['lt1'] = thingEditData.lt1
        	modified = true
        }
        if (thingEditData.lf1_o != thingEditData.lf1) {
        	payload.state.desired['lf1'] = thingEditData.lf1
        	modified = true
        }
        if (thingEditData.lf2_o != thingEditData.lf2) {
        	payload.state.desired['lf2'] = thingEditData.lf2
        	modified = true
        }
        if (modified) {
	        console.log('SEND DESIRED', payload.state)
        	this.thingService.updateShadow(thingEditData.name, payload)
      	}
    }
    
    public isActive(thing) : boolean
    {
        let date = new Date()
        let v = thing.updated && thing.updated >= date.getTime() - 24 * 60 * 60 * 1000
        return v
    }

    private editPopUpmodal(thing): void {
      this.showModalPopUp = true
      this.thingEditData.lt1 = thing.stateObject.lt1
      this.thingEditData.lt1_o = thing.stateObject.lt1
      this.thingEditData.tt1 = thing.stateObject.tt1
      this.thingEditData.tt1_o = thing.stateObject.tt1

      this.thingEditData.lt2 = thing.stateObject.lt2
      this.thingEditData.tt2 = thing.stateObject.tt2

      this.thingEditData.lt3 = thing.stateObject.lt3
      this.thingEditData.tt3 = thing.stateObject.tt3

      this.thingEditData.lh1 = thing.stateObject.lh1
      this.thingEditData.th1 = thing.stateObject.th1

      this.thingEditData.lc1 = thing.stateObject.lc1
      this.thingEditData.tc1 = thing.stateObject.tc1

      this.thingEditData.lm1 = thing.stateObject.lm1
      this.thingEditData.tm1 = thing.stateObject.tm1

      this.thingEditData.lf1 = thing.stateObject.lf1
      this.thingEditData.lf1_o = thing.stateObject.lf1
      this.thingEditData.tf1 = thing.stateObject.tf1
      this.thingEditData.tf1_o = thing.stateObject.tf1

      this.thingEditData.name = thing.name
    }
    private updateThingData(thingEditData, saveEdit): void {
    	this.showModalPopUp = false
    	if (saveEdit) {
    		this.updateParams(thingEditData)
    	}
    }
    
    
}
