<div class="row">
    <div class="offset-sm-3 col-sm-6">
        <legend>{{'forgot_password.request_new_password' | translate}}&nbsp;<small class="float-right"><a routerLink="/login">{{'forgot_password.login' | translate}}</a></small></legend>

        <div class="form-group">
            <label for="username">{{'forgot_password.email' | translate}}</label>
            <input id="username" type="text" class="form-control" [(ngModel)]="username">
            <small id="forgotPasswordHelp" class="form-text text-muted">{{'forgot_password.email_help' | translate}}</small>
        </div>

        <button type="button" class="btn btn-primary" (click)="send()">{{'forgot_password.send' | translate}}
            <app-spinner [loading]="loading"></app-spinner>
        </button>
    </div>
</div>
