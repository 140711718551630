import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'

import { AuthService } from '../auth/auth.service';
import { MessageService } from '../../messages/message.service';

@Component({
    selector: 'app-forgot-password-verify',
    templateUrl: './forgot-password-verify.component.html',
    styleUrls: ['./forgot-password-verify.component.css']
})
export class ForgotPasswordVerifyComponent implements OnInit {
    public loading: boolean = false
    public username: string
    public verificationCode: string
    public newPassword: string

    constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, private messageService: MessageService) { }

    ngOnInit() {
        this.username = this.route.snapshot.queryParams['email']
    }


    public confirm(): void {
        this.loading = true
        this.authService.forgotPasswordConfirm(this.username, this.verificationCode, this.newPassword)
            .then(() => {
                this.loading = false
                this.router.navigate(['login'], { queryParams: { email: this.username } })
            })
            .catch((error) => {
                console.log('forgot-password-verify failed', error)
                this.loading = false
                if (error && error.code === 'InvalidPasswordException') {
                    this.messageService.warning('forgot_password_verify.invalid_new_password')
                } else if (error && error.code === 'CodeMismatchException') {
                    this.messageService.warning('forgot_password_verify.invalid_reset_code')
                } else {
                    this.messageService.warning('forgot_password_verify.generic_failure')
                }
            })
    }
}
