import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/Observable'

import { AwsService } from '../../common/aws.service'
import { AwsConfig } from '../../common/aws.config'

declare var AWS: any;
declare var AWSCognito: any;

@Injectable()
export class CognitoService {

    constructor(private awsService: AwsService) {
    }

    public login(username: string, password: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let authenticationData = {
                Username: username,
                Password: password,
            }
            let authenticationDetails = new AWSCognito.CognitoIdentityServiceProvider.AuthenticationDetails(authenticationData)

            let userData = {
                Username: username,
                Pool: this.getUserPool()
            }
            let cognitoUser = new AWSCognito.CognitoIdentityServiceProvider.CognitoUser(userData)

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {
                    console.log('*** authenticateUser success', result)
                    this.awsService.setCredentials(result.getIdToken().getJwtToken())
                        .then(() => resolve('login-successful'))
                        .catch(() => reject('login-successful-but-credentials-failed'))
                },
                onFailure: (err) => {
                    console.log('*** authenticateUser failure, code', err.code);
                    if (err.code === 'UserNotConfirmedException') {
                        reject('login-user-not-confirmed')
                    } else {
                        reject('login-failed')
                    }
                },
                newPasswordRequired: (val) => {
                    resolve('login-new-password-required')
                }
            })
        })
    }

    public logout() {
        let userPool = this.getUserPool()
        let cognitoUser = userPool.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.signOut()
        }
        this.awsService.clearCredentials()
    }

    public getCurrentUser(): Promise<any> {
        let userPool = this.getUserPool()
        let cognitoUser = userPool.getCurrentUser();

        return new Promise<any>((resolve, reject) => {
            if (cognitoUser != null) {
                cognitoUser.getSession((err, session) => {
                    if (err) {
                        reject(err)
                    } else {
                        this.awsService.setCredentials(session.getIdToken().getJwtToken())
                            .then(() => {
                                cognitoUser.groups = this.getGroups(session.getIdToken().getJwtToken())
                                return true
                            })
                            .then(() => {
                                return this.getUserAttributes(cognitoUser)
                            })
                            .then((attributes) => {
                                cognitoUser.attributes = {}
                                for (var i = 0; i < attributes.length; i++) {
                                    cognitoUser.attributes[attributes[i].getName()] = attributes[i].getValue()
                                }
                                cognitoUser.email = cognitoUser.attributes['email']
                                resolve(cognitoUser)
                            })
                            .catch((err) => {
                                reject(err)
                            })
                    }
                })
            } else {
                reject()
            }
        })
    }

    private getUserAttributes(cognitoUser: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            cognitoUser.getUserAttributes((error, result) => {
                if (error) {
                    reject(error)
                } else {
                    resolve(result)
                }
            })
        })
    }

    public refreshTokens(): Promise<any> {
        return this.getCurrentUser()
    }

    private getGroups(jwtToken: string) {
        let payload = this.parsePayload(jwtToken)
        return payload ? payload['cognito:groups'] : []
    }

    private parsePayload(jwtToken: string): any {
        if (!jwtToken) {
            return undefined
        }
        let payload = jwtToken.split('.')[1]
        return JSON.parse(AWS.util.base64.decode(payload).toString('utf8'))
    }

    public register(username: string, password: string): Promise<string> {
        let dataEmail = {
            Name: 'email',
            Value: username
        }
        let attributeEmail = new AWSCognito.CognitoIdentityServiceProvider.CognitoUserAttribute(dataEmail)
        let attributeList = [];
        attributeList.push(attributeEmail);

        let userPool = this.getUserPool()

        return new Promise<string>((resolve, reject) => {
            userPool.signUp(username, password, attributeList, null, (err, result) => {
                if (err) {
                    console.log('cognito.service ERROR registration, code', err.code)
                    if (err.code === 'InvalidPasswordException') {
                        reject('registration-failed-invalid-password')
                    } else if (err.code === 'UsernameExistsException') {
                        reject('registration-failed-username-exists')
                    } else {
                        reject('registration-failed')
                    }
                } else {
                    resolve(null)
                }
            })
        })
    }

    public confirmRegistration(username: string, code: string): Promise<string> {
        let userData = {
            Username: username,
            Pool: this.getUserPool()
        }
        let cognitoUser = new AWSCognito.CognitoIdentityServiceProvider.CognitoUser(userData);
        return new Promise<string>((resolve, reject) => {
            cognitoUser.confirmRegistration(code, true, (error, result) => {
                if (error) {
                    if (error.code === 'CodeMismatchException') {
                        reject('confirm-code-mismatch')
                    } else if (error.code === 'UserNotFoundException') {
                        reject('confirm-user-not-found')
                    } else {
                        console.log('ERROR in confirm registration, code', error.code)
                        reject('confirm-registration-failed')
                    }
                } else {
                    resolve(result)
                }
            })
        })
    }

    public confirmEmailModification(code: string): Promise<any> {

        function verifyAttribute(cognitoUser) {
            return new Promise((resolve, reject) => {
                cognitoUser.verifyAttribute('email', code, {
                    onSuccess: function ( result ) { resolve(result) },
                    onFailure: function ( error ) {
                        if (error.code === 'CodeMismatchException') {
                            return reject('confirm-code-mismatch')
                        } else if (error.code === 'UserNotFoundException') {
                            return reject('confirm-user-not-found')
                        } else {
                            console.log('ERROR in confirm verifyAttribute, code', error.code)
                            return reject('confirm-registration-failed')
                        }
                    }
                })
            })
        }

        return this.getCurrentUser()
            .then(verifyAttribute)
    }

    public forgotPassword(username: string): Promise<any> {
        return new Promise<string>((resolve, reject) => {
            let userData = {
                Username: username,
                Pool: this.getUserPool()
            }
            let cognitoUser = new AWSCognito.CognitoIdentityServiceProvider.CognitoUser(userData);
            cognitoUser.forgotPassword({
                onSuccess: function (result) {
                    resolve(result)
                },
                onFailure: function (err) {
                    reject(err)
                }
            })
        })
    }

    public changePassword(oldPassword: string, newPassword: string): Promise<any> {
        return this.getCurrentUser()
            .then(user => {
                return new Promise( (resolve, reject) => {
                    user.changePassword(oldPassword, newPassword, (err, data) => {
                        if (err) {
                            reject(err)
                        } else {
                            resolve(data)
                        }
                    })
                })
            })
    }

    public updateAttributes(attributes: Array<any>): Promise<any> {
        return this.getCurrentUser()
            .then(user => {
                return new Promise( (resolve, reject) => {
                    user.updateAttributes(attributes, (err, data) => {
                        if (err) {
                            reject(err)
                        } else {
                            resolve(data)
                        }
                    })
                })
            })
    }

    public forgotPasswordConfirm(username: string, verificationCode: string, newPassword: string): Promise<any> {
        return new Promise<string>((resolve, reject) => {
            let userData = {
                Username: username,
                Pool: this.getUserPool()
            }
            let cognitoUser = new AWSCognito.CognitoIdentityServiceProvider.CognitoUser(userData);

            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onFailure(err) {
                    reject(err);
                },
                onSuccess() {
                    resolve(null);
                }
            })
        })
    }

    private getUserPool() {
        let poolData = {
            UserPoolId: AwsConfig.USER_POOL_ID,
            ClientId: AwsConfig.USER_POOL_CLIENT_ID
        }
        return new AWSCognito.CognitoIdentityServiceProvider.CognitoUserPool(poolData)
    }
}
