import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'app-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.css']
})
export class ToggleButtonComponent implements OnInit {
    @Input()
    @Output()
    enabled: boolean
    @Output()
    public toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    constructor() { }

    ngOnInit() {
    }

    public toggleClicked(): void {
        this.enabled = !this.enabled
        this.toggleChange.emit(this.enabled)
    }
}

