<div class="row">
    <div class="offset-sm-3 col-sm-6">
        <legend>{{'register.create_account' | translate}}&nbsp;<small class="float-right"><a routerLink="/login">{{'register.login' | translate}}</a></small></legend>
        <div class="form-group row">
            <label for="username" class="col-sm-3 col-form-label">{{'register.email' | translate}}</label>
            <div class="col-sm-9">
                <input id="username" type="text" class="form-control" [(ngModel)]="username">
            </div>
        </div>

        <div class="form-group row">
            <label for="password" class="col-sm-3 col-form-label">{{'register.password' | translate}}</label>
            <div class="col-sm-9">
                <input id="password" type="password" class="form-control" [(ngModel)]="password">
            </div>
        </div>

        <div class="form-group row">
            <div class="offset-sm-3 col-sm-9">
                <button type="button" class="btn btn-primary" (click)="register()">{{'register.register' | translate}}
                    <app-spinner [loading]="loading"></app-spinner>
                </button>
            </div>
        </div>
    </div>
</div>
