import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { AuthService } from '../auth/auth.service'
import { ThingService } from '../../device/thing.service'
import { MessageService } from '../../messages/message.service'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    public username: string
    public password: string
    public loading: boolean = false

    constructor(public authService: AuthService, private thingService: ThingService, private messageService: MessageService,
        private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.username = this.route.snapshot.queryParams['email']

        // If current user is in local storage, use it.
        this.authService.getCurrentUser()
            .then(() => {
                this.postProcessLogin()
            })
            .catch((error) => {
                console.log('login.getCurrentUser.catch', error)
                // stay at the login page
            })
    }

    public login(): void {
        this.loading = true
        this.authService.login(this.username, this.password)
            .then(() => {
                this.loading = false
                this.postProcessLogin()
            })
            .catch((error) => {
                this.loading = false
                if (error === 'login-user-not-confirmed') {
                    this.messageService.info('login.user_not_confirmed')
                    this.router.navigate(['register/verify'], { queryParams: { email: this.username } })
                } else {
                    this.messageService.warning('login.login_failed')
                }
            })
    }

    private postProcessLogin(): void {
        this.thingService.initialize(false)
            .then(() => {
                console.log('login.postProcessLogin redirect to ' + this.authService.redirectUrl)
                this.router.navigate([this.authService.redirectUrl]);
            })
            .catch((error) => {
                console.log('login.postProcessLogin thing service catch error', error)
                if (error === 'no-things-registered') {
                    console.log('login.postProcessLogin no things, redirect to add device')
                    this.router.navigate(['add-device'])
                }
            })
    }
}
