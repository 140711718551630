import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LocalStorageService } from '../../local-storage/local-storage.service';
import { TimerObservable } from "rxjs/observable/TimerObservable";
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';

import { CognitoService } from './cognito.service';

@Injectable()
export class AuthService {
    isLoggedIn: boolean = false
    username: string
    email: string
    groups: string[] = []
    redirectUrl: string
    emailVerified: boolean = true

    private permNames : string[] = [
        "admin",
        "adminedit",
        "adminview",
        "eventview",
        "scheduleedit",
        "useredit"
    ]

    private refreshTokensSource = new BehaviorSubject<any>({})
    public refreshTokensObservable = this.refreshTokensSource.asObservable()
    private timerSubscription: any;

    constructor(private router: Router, private cognitoService: CognitoService, private localStorageService: LocalStorageService) {
        this.redirectUrl = ''
        let intervalMs = 15 * 60 * 1000
        this.timerSubscription = TimerObservable.create(intervalMs, intervalMs)
            .subscribe(t => this.refreshTokens())
    }

    public login(username: string, password: string): Promise<void> {
        return new Promise<any>((resolve, reject) => {
            this.cognitoService.login(username, password)
                .then(() => { return this.getCurrentUser() })
                .then(() => { resolve({}) })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public logout(): void {
        this.isLoggedIn = false
        this.username = ''
        this.email = ''
        this.cognitoService.logout()
    }

    public register(username: string, password: string): Promise<any> {
        return this.cognitoService.register(username, password)
    }

    public confirmRegistration(username: string, code: string): Promise<any> {
        return this.cognitoService.confirmRegistration(username, code)
    }

    public confirmEmailModification(code: string): Promise<any> {
        return this.cognitoService.confirmEmailModification(code)
    }

    public forgotPassword(username: string): Promise<any> {
        return this.cognitoService.forgotPassword(username)
    }

    public forgotPasswordConfirm(username: string, verificationCode: string, newPassword: string) {
        return this.cognitoService.forgotPasswordConfirm(username, verificationCode, newPassword)
    }

    public changePassword(oldPassword: string, newPassword): Promise<any> {
        return this.cognitoService.changePassword(oldPassword, newPassword)
    }

    public updateAttributes(attributes: Array<any>): Promise<any> {
        return this.cognitoService.updateAttributes(attributes)
    }

    public getCurrentUser(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.cognitoService.getCurrentUser()
                .then((data) => {
                    console.log('auth.service - getCurrentUser.then', data)
                    this.isLoggedIn = true
                    this.username = data.username
                    this.email = data.email ? data.email : data.username
                    this.emailVerified = data.attributes.email_verified
                    this.groups = data.groups
                    if (this.groups.indexOf("admin") >= 0) {
                        this.permNames.forEach((item) => {
                            if (this.groups.indexOf(item) < 0) {
                                this.groups.push(item)
                            }
                        })
                    }
                    resolve(data)
                })
                .catch((error) => {
                    console.log('auth.service - getCurrentUser.catch', error)
                    this.isLoggedIn = false
                    this.username = undefined
                    this.email = undefined
                    this.groups = []
                    reject(error)
                })
        })
    }

    public hasRole(role: string) {
        if (this.groups && this.groups.indexOf(role) >= 0) {
            let key = "perm_" + role
            let val = this.localStorageService.getItem(key)
            if (val == null || val == "1") {
                return true
            }
        }
        return false
    }

    private refreshTokens(): void {
        console.log('auth.service.refreshToken called by timer')
        this.cognitoService.refreshTokens()
            .then(() => this.refreshTokensSource.next({}))
            .catch((err) => {
                console.log('auth.service.refreshToken failed', err)
            })
    }
}
