<!-- Background just for login page using simply an image with some css :) -->
<!--<img src="http://i0.wp.com/www.ispsd.com/0psd/yellow_grunge_background.jpg" id="bg" alt="">-->

<div class="row">

    <div class="offset-sm-3 col-sm-6 login">
        <legend>{{'login.login' | translate}}&nbsp;<small class="float-right"><a routerLink="/register">{{'login.create_an_account' | translate}}</a></small></legend>
        <div class="form-group row">
            <label for="username" class="col-sm-3 col-form-label">{{'login.email' | translate}}</label>
            <div class="col-sm-9">
                <input id="username" type="text" class="form-control" [(ngModel)]="username" [disabled]="loading">
            </div>
        </div>

        <div class="form-group row">
            <label for="password" class="col-sm-3 col-form-label">{{'login.password' | translate}}</label>
            <div class="col-sm-9">
                <input id="password" type="password" class="form-control" [(ngModel)]="password" [disabled]="loading">
            </div>
        </div>

        <div class="form-group row">
            <div class="offset-sm-3 col-sm-9">
                <button type="button" class="btn btn-primary" (click)="login()">{{'login.sign_in' | translate}}
                    <app-spinner [loading]="loading"></app-spinner>
                </button>
                <small class="float-right"><a routerLink="/forgot-password">{{'login.forgot_password' | translate}}</a></small>
            </div>
        </div>
    </div>

</div>
