import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'

import { AuthService } from '../auth/auth.service';
import { MessageService } from '../../messages/message.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
    public loading: boolean = false
    public username: string

    constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private messageService: MessageService) { }

    ngOnInit() {
    }

    public send(): void {
        this.loading = true
        this.authService.forgotPassword(this.username)
            .then(() => {
                this.loading = false
                this.messageService.info('forgot_password.password_reset_completed')
                this.router.navigate(['forgot-password/verify'], { queryParams: { email: this.username } })
            })
            .catch((error) => {
                this.loading = false
                console.log('forgot-password failed', error)
                this.messageService.warning('forgot_password.password_reset_failed')
            })
    }
}
