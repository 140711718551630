<div class="container">

    <div class="col-md-12 devices">

        <legend>{{'devices.devices' | translate}}</legend>

        <hr>
    
        <div class="col-md-12" *ngIf="listThings().length === 0">
            <div class="alert alert-info">
                {{'dashboard.no_devices_attached_to_account' | translate}}
            </div>
        </div>

        <div class="card">
            <div class="card-block">
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th>{{'devices.label' | translate}}</th>
                            <th>{{'devices.device' | translate}}</th>
                            <th>{{'devices.firmware_version' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let thing of listThings()">
                            <td><a routerLink="{{'/details/' + thing.name}}">{{ thing.label }}</a></td>
                            <td>{{ thing.name }} <br> {{ getSaunaInfo(thing) }}</td>
                            <td>
                                <div class="progress" *ngIf="thing.stateObject.fu === '1' || thing.firmwareUpdatePending; else notUpdating">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" 
                                        role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" 
                                        [ngStyle]="{'width': thing.stateObject.fu === '1' ? '75%' : '25%'}"></div>
                                </div>
                                <ng-template #notUpdating>
                                    {{ thing.firmwareRevision }}
                                </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <br>

        <div class="col-md-12">
            <a routerLink="/add-device" class="btn btn-primary pull-right">+ {{'devices.add_device' | translate}}</a>
        </div>

    </div>

    
</div>
