import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { AuthService } from '../auth/auth.service'
import { MessageService } from '../../messages/message.service'

@Component({
    selector: 'app-register',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {    
    public currentPassword: string
    public newPassword: string
    public newPasswordRetype: string

    public loading: boolean = false
    public loadingEmail: boolean = false

    constructor(public authService: AuthService, public router: Router, private messageService: MessageService) { }

    ngOnInit() {
    }

    public saveUsername(username: string): void {
        this.loadingEmail = true
        this.authService.updateAttributes([{"Name": "email",  "Value": username}])
            .then(() => {
                this.authService.getCurrentUser()
            })
            .then(() => {
                this.loadingEmail = false
                this.router.navigate(['register/verify'], { queryParams: { verifyType: "changeEmail" } })
            })
            .catch((err) => {
                this.loadingEmail = false
                console.log("fail!", err)
            })
    }

    public changePassword() {
        if (!this.newPassword ||  this.newPassword !== this.newPasswordRetype) {
            return
        }
        this.loading = true

        this.authService.changePassword(this.currentPassword, this.newPassword)
            .then(() => {
                this.loading = false
                this.currentPassword = ''
                this.newPassword = ''
                this.newPasswordRetype = ''
                this.messageService.info('profile.change_password_success')
            })
            .catch((err) => {
                this.loading = false

                if (err && err.code === "InvalidParameterException") {
                    this.messageService.warning('profile.check_password_policy')
                } else {
                    this.messageService.warning('profile.change_password_error')
                }
            })
    }
}
