<div class="row">
    <div class="offset-sm-3 col-sm-6">
        <legend>{{'menu.profile' | translate}}</legend>
        <div class="form-group row">
            <label for="username" class="col-sm-3 col-form-label">{{'register.email' | translate}}</label>
            <div class="col-sm-9">
                <app-editable-label style="display:inline-block;" [text]="authService.email" (save)="saveUsername($event)">
                    {{ authService.email }}
                </app-editable-label>
                <app-spinner [loading]="loadingEmail"></app-spinner>
                <a *ngIf="!authService.emailVerified" routerLink="/register/verify" [queryParams]="{verifyType: 'changeEmail'}">
                    <small class="form-text text-muted">{{'profile.not_verified_yet' | translate}}</small>
                </a>
                <small class="form-text text-muted">{{'profile.verify_email_required' | translate}}</small>
            </div>
        </div>

        <legend>{{'profile.change_password' | translate}}</legend>

        <div class="form-group row">
            <label for="currentPassword" class="col-sm-3 col-form-label">{{'profile.current_password' | translate}}</label>
            <div class="col-sm-9">
                <input id="currentPassword" type="password" class="form-control" [(ngModel)]="currentPassword">
            </div>
        </div>

        <div class="form-group row">
            <label for="newPassword" class="col-sm-3 col-form-label">{{'profile.new_password' | translate}}</label>
            <div class="col-sm-9">
                <input id="newPassword" 
                    pattern="^(?=.*\d)(?=.*[a-zA-Z]).{8,}$" type="password" class="form-control" [(ngModel)]="newPassword">
            </div>
        </div>

        <div class="form-group row">
            <label for="newPasswordRetype" class="col-sm-3 col-form-label">{{'profile.retype_new_password' | translate}}</label>
            <div class="col-sm-9">
                <input id="newPasswordRetype" type="password" class="form-control" [(ngModel)]="newPasswordRetype">
                <small class="form-text text-muted">{{'profile.password_policy' | translate}}</small>
            </div>
        </div>

        <div class="form-group row">
            <div class="offset-sm-3 col-sm-9">
                <button [disabled]="!newPassword || newPassword != newPasswordRetype" type="button" class="btn btn-primary" (click)="changePassword()">{{'profile.change_password' | translate}}
                    <app-spinner [loading]="loading"></app-spinner>
                </button>
            </div>
        </div>
    </div>
</div>
