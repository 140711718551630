<div class="row" (window:resize)="onResize($event)">

    <div class="col-md-12 thing-graph-top" #thingGraphTop>
        <div class="row">

            <div class="col-md-5">
                <span dropdown class="thing-title">
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                    <a href="#" dropdownToggle (click)="false">{{selectedThing.label}}</a>
                    <ul *dropdownMenu class="dropdown-menu">
                        <li *ngFor="let thing of listThings()">
                            <a class="dropdown-item" href="#" [routerLink]="['/details', thing.name, timeRange.param]">{{thing.label}}</a>
                        </li>
                    </ul>
                </span>
                <app-spinner [loading]="loading"></app-spinner>
            </div>

            <div class="col-md-3 text-center">
                <i class="fa fa-calendar" (click)="toggleDatePicker()" aria-hidden="true"></i>
                <span class="thing-date">{{viewRange()}}</span>
                 <app-datetime-picker 
                    [(value)]="datePickerDate"
                    (valueChange)="onDateChanged($event)"
                    [(showPopup)]="datePickerVisible"
                    [maxDate]="datePickerMaxDate"
                    [texts]="{close: ('details.choose' | translate), now: ('details.now' | translate)}">
                 </app-datetime-picker>
            </div>

            <div class="col-md-1 scroll-buttons">
                <button class="btn btn-outline-primary btn-sm scroll-button" (click)="scrollBack()" [disabled]="loading">
                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                </button>
                <button class="btn btn-outline-primary btn-sm scroll-button" (click)="scrollNow()" [disabled]="loading || !scrollNowEnabled()">
                    <i class="fa fa-circle-o" aria-hidden="true"></i>
                </button>
                <button class="btn btn-outline-primary btn-sm scroll-button" (click)="scrollForward()" [disabled]="loading || !scrollForwardEnabled()">
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </button>
            </div>

            <div class="col-md-3">
                <div class="btn-group btn-group-xs btn-block" *ngIf="selectedThing">
                    <button type="button" class="btn btn-secondary time-range-button" *ngFor="let timeRangeOption of timeRangeOptions"
                        [ngClass]="{'active': timeRangeOption.param === timeRange.param}" (click)="changeTimeRange(timeRangeOption)"
                        [disabled]="loading">
                        {{timeRangeOption.label | translate}}
                    </button>
                </div>
            </div>

        </div>
    </div>

    <div class="thing-chart" class="col-md-9" [style.height.px]="chartHeight">
        <canvas id="chart" *ngIf="chartHasData()"
            baseChart
            [datasets]="chart.lineChartData"
            [labels]="chart.lineChartLabels"
            [options]="chart.lineChartOptions"
            [colors]="chart.lineChartColors(selectedThing.advanced_pack, selectedThing.stove_pack, advancedMode)"
            [legend]="chart.lineChartLegend"
            [chartType]="chart.lineChartType"
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)">
        </canvas>
        <div *ngIf="!chartHasData() && !loading" class="col-sm-12 no-data-background">
            <div class="no-data-text">
                <span>{{'details.no_data_available' | translate}}<br>{{'details.last_updated' | translate}}: {{selectedThing.updated | date:'dd.MM.yyyy HH:mm'}}</span>
            </div>
        </div>
    </div>

    <div class="col-md-3">
        <app-manual-control [thingName]="selectedThing.name"></app-manual-control>
    </div>

<!--
    <div class="col-sm-12 thing-sensors" #thingSensors>
        <div class="row thing-sensors-position" *ngIf="chart.lineChartData.length > 0 && selectedThing && selectedThing.stateObject && !selectedThing.enhancedControls">
			<div class="col-sm-6">
                <div class="row">
                    <ng-template #temperatureTooltip>{{'details.tooltip.temperature' | translate}}</ng-template>
                    <div class="col-sm-4 thing-sensor-box" [tooltip]="temperatureTooltip" container="body">
                        <span class="text-center" *ngIf="!advancedMode">
                            <i class="fa fa-thermometer-three-quarters thing-sensor-image" aria-hidden="true"></i>
                        </span>
                        <span class="thing-sensor-label-value-box">
                            <div class="thing-sensor-label">{{'details.temperature' | translate}}</div>
                            <div class="thing-sensor-value">{{sensorValue('t1')}}°C</div>
                        </span>
                    </div>
                    <ng-template #humidityTooltip>{{'details.tooltip.humidity' | translate}}</ng-template>
                    <div class="col-sm-4 thing-sensor-box" [tooltip]="humidityTooltip" container="body">
                        <span class="text-center" *ngIf="!advancedMode">
                             <i class="fa fa-tint thing-sensor-image" aria-hidden="true"></i>
                        </span>
                        <span class="thing-sensor-label-value-box">
                            <div class="thing-sensor-label">{{'details.humidity' | translate}}</div>
                            <div class="thing-sensor-value">{{sensorValue('h1')}}%</div>
                        </span>
                    </div>
                    <ng-template #indexTooltip>{{'details.tooltip.index' | translate}}</ng-template>
                    <div class="col-sm-4 thing-sensor-box" [tooltip]="indexTooltip" container="body">
                        <span class="text-center" *ngIf="!advancedMode">
                            <i class="fa fa-area-chart thing-sensor-image" aria-hidden="true"></i>
                        </span>
                        <span class="thing-sensor-label-value-box">
                            <div class="thing-sensor-label">{{'details.index' | translate}}</div>
                            <div class="thing-sensor-value">{{sensorValue('ix')}}</div>
                        </span>
                    </div>
					<div *ngIf="selectedThing.controls && !selectedThing.enhancedControls">
						<ng-template #roofTemperatureTooltip>{{'details.tooltip.roof_temperature' | translate}}</ng-template>
						<div class="col-sm-4 thing-sensor-box" [tooltip]="roofTemperatureTooltip" container="body">
							<span class="text-center" *ngIf="!advancedMode">
								<i class="material-icons thing-sensor-image">settings_remote</i>
							</span>
							<span class="thing-sensor-label-value-box">
								<div class="thing-sensor-label">{{'details.roof_temperature' | translate}}</div>
								<div class="thing-sensor-value">{{sensorValue('t2')}}°C</div>
							</span>
						</div>
					</div>
                    <ng-template #co2Tooltip>{{'details.tooltip.co2' | translate}}</ng-template>
                    <div class="col-sm-4 thing-sensor-box" [tooltip]="co2Tooltip" container="body">
                        <span class="text-center" *ngIf="!advancedMode">
                            <i class="fa fa-cloud thing-sensor-image" aria-hidden="true"></i>
                        </span>
                        <span class="thing-sensor-label-value-box">
                        <div class="thing-sensor-label">{{'details.co2' | translate}}</div>
                        <div class="thing-sensor-value">{{sensorValue('c1')}}</div>
                        </span>
                    </div>
                    <ng-template #noiseTooltip>{{'details.tooltip.noise_level' | translate}}</ng-template>
                    <div class="col-sm-4 thing-sensor-box" [tooltip]="noiseTooltip" container="body">
                        <span class="text-center" *ngIf="!advancedMode">
                            <i class="material-icons thing-sensor-image">equalizer</i>
                        </span>
                        <span class="thing-sensor-label-value-box">
                            <div class="thing-sensor-label">{{'details.noise_level' | translate}}</div>
                            <div class="thing-sensor-value">{{sensorValue('m1')}}db</div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6" *ngIf="selectedThing.enhancedControls && advancedMode">
                <div class="row">
                    <ng-template #roofTemperatureTooltip>{{'details.tooltip.roof_temperature' | translate}}</ng-template>
                    <div class="col-sm-4 thing-sensor-box" [tooltip]="roofTemperatureTooltip" container="body">
                        <span class="thing-sensor-label-value-box">
                            <div class="thing-sensor-label">{{'details.stones' | translate}}</div>
                            <div class="thing-sensor-value">{{sensorValue('t3')}}°C</div>
                        </span>
                    </div>
                    <ng-template #co2Tooltip>{{'details.tooltip.co2' | translate}}</ng-template>
                    <div class="col-sm-4 thing-sensor-box" [tooltip]="co2Tooltip" container="body">
                        <span class="thing-sensor-label-value-box">
                        <div class="thing-sensor-label">{{'details.lid' | translate}}</div>
                        <div class="thing-sensor-value"><ng-template [ngIf]="sensorValue('l1')<=0">Closed</ng-template><ng-template [ngIf]="sensorValue('l1')>0">Open {{sensorValue('l1')}}min</ng-template></div>
                        </span>
                    </div>
                    <ng-template #noiseTooltip>{{'details.tooltip.noise_level' | translate}}</ng-template>
                    <div class="col-sm-4 thing-sensor-box" [tooltip]="noiseTooltip" container="body">
                        <span class="thing-sensor-label-value-box">
                            <div class="thing-sensor-label">{{'details.fan' | translate}}</div>
                            <div class="thing-sensor-value">{{sensorValue('f2')}}%</div>
                        </span>
                    </div>
                    <ng-template #noiseTooltip>{{'details.tooltip.noise_level' | translate}}</ng-template>
                    <div class="col-sm-4 thing-sensor-box" [tooltip]="noiseTooltip" container="body">
                        <span class="thing-sensor-label-value-box">
                            <div class="thing-sensor-label">{{'details.pcb' | translate}}</div>
                            <div class="thing-sensor-value">{{sensorValue('t4')}}°C</div>
                        </span>
                    </div>
                    <ng-template #noiseTooltip>{{'details.tooltip.heaters' | translate}}</ng-template>
                    <div class="col-sm-4 thing-sensor-box" [tooltip]="noiseTooltip" container="body">
                        <span class="thing-sensor-label-value-box">
                            <div class="thing-sensor-label">{{'details.heaters' | translate}}</div>
                            <div class="thing-sensor-value">{{sensorValue('ht0')}}</div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
-->

	<div *ngIf="selectedThing.advanced_pack">
		<div *ngIf="advancedModeWas && !advancedMode">
			<button (click)="userMode(false)">-> Back to admin mode interface</button>
		</div>

		<div *ngIf="selectedThing.advanced_pack && eventViewMode && infoMessages.length == 0">
			<b>No recent events</b>
		</div>

		<div *ngIf="selectedThing.advanced_pack && eventViewMode && infoMessages.length > 0">
			<div *ngIf="!showMessages">
				<button (click)="clickMessages(true)">Show all messages ({{infoMessages.length}})</button>
				<table class="spreadsheet table table-condensed table-striped table-bordered">
					<thead>
						<tr>
							<th>Date</th>
							<th>Message</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let msg of infoMessages2">
							<td align="left" width="20%" style="background-color:{{msg.bgcolor}};">{{msg.date}}</td>
							<td align="left" width="80%" style="background-color:{{msg.bgcolor}};">{{msg.msg}}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div *ngIf="showMessages">
				<button (click)="clickMessages(false)">Show last 3 messages ({{infoMessages.length}})</button>
				<table class="spreadsheet table table-condensed table-striped table-bordered">
					<thead>
						<tr>
							<th>Date</th>
							<th>Message</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let msg of infoMessages;">
							<td align="left" style="background-color:{{msg.bgcolor}};">{{msg.date}}</td>
							<td align="left" style="background-color:{{msg.bgcolor}};">{{msg.msg}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div *ngIf="selectedThing.thingType!=3 && advancedMode">
			<table class="spreadsheet table table-condensed table-striped table-bordered thing-table">
				<thead>
					<tr>
						<th>Parameter</th>
						<th>Measured value</th>
						<th>Set value</th>
						<th>Update set value</th>
						<th>Limit Min</th>
						<th>Update Limit Min</th>
						<th>Limit Max</th>
						<th>Update Limit Max</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Sauna temperature</td>
						<td>{{ sensorValue('t1') }}°C</td>
						<td><ng-template [ngIf]="sensorValue('sta')>0">{{ sensorValue('sta') }}°C</ng-template><ng-template [ngIf]="sensorValue('sta')==0">-</ng-template></td>
						<td><input [disabled]="!canEdit()" type="number" #sta name="sta" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sta',sta.value)">Update</button></td>
						<td>{{ sensorValue('lt1') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #lt1 name="lt1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lt1',lt1.value)">Update</button></td>
						<td>{{ sensorValue('tt1') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #tt1 name="tt1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tt1',tt1.value)">Update</button></td>
					</tr>
					<tr>
						<td>CPU temperature</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('t6') }}°C</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Sauna hours</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('hr') }}h</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Heater hours</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('hhr') }}h</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Sauna time (normal)</td>
						<td>&nbsp;</td>
						<td><ng-template [ngIf]="sensorValue('ts1')>0">{{ sensorValue('ts1') }}min</ng-template><ng-template [ngIf]="sensorValue('ts1')==0">-</ng-template></td>
						<td><input [disabled]="!canEdit()" type="number" #ts1 name="ts1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ts1',ts1.value)">Update</button></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Sauna time (es)</td>
						<td>&nbsp;</td>
						<td><ng-template [ngIf]="sensorValue('ts2')>0">{{ sensorValue('ts2') }}min</ng-template><ng-template [ngIf]="sensorValue('ts2')==0">-</ng-template></td>
						<td><input [disabled]="!canEdit()" type="number" #ts2 name="ts2" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ts2',ts2.value)">Update</button></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Sauna temperature alert</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rha') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #rha name="rha" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rha',rha.value)">Update</button></td>
					</tr>
					<tr>
						<td>Sauna temperature shutdown</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('roha') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #roha name="roha" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('roha',roha.value)">Update</button></td>
					</tr>
					<tr>
						<td>Humidity alert</td>
						<td>{{ sensorValue('h1') }}%</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lh1') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #lh1 name="lh1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lh1',lh1.value)">Update</button></td>
						<td>{{ sensorValue('th1') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #th1 name="th1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('th1',th1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Co2 alert</td>
						<td>{{ sensorValue('c1') }}</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lc1') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #lc1 name="lc1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lc1',lc1.value)">Update</button></td>
						<td>{{ sensorValue('tc1') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #tc1 name="tc1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tc1',tc1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Noise alert</td>
						<td>{{ sensorValue('m1') }}db</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lm1') }}db</td>
						<td><input [disabled]="!canEdit()" type="number" #lm1 name="lm1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lm1',lm1.value)">Update</button></td>
						<td>{{ sensorValue('tm1') }}db</td>
						<td><input [disabled]="!canEdit()" type="number" #tm1 name="tm1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tm1',tm1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Temperature (min/delta)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rt1') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #rt1 name="rt1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rt1',rt1.value)">Update</button></td>
						<td>{{ sensorValue('ut1') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #ut1 name="ut1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ut1',ut1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Humidity (min/delta)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rh1') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #rh1 name="rh1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rh1',rh1.value)">Update</button></td>
						<td>{{ sensorValue('uh1') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #uh1 name="uh1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('uh1',uh1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Co2 (min/delta)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rc1') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #rc1 name="rc1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rc1',rc1.value)">Update</button></td>
						<td>{{ sensorValue('uc1') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #uc1 name="uc1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('uc1',uc1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Noise (min/delta)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rm1') }}db</td>
						<td><input [disabled]="!canEdit()" type="number" #rm1 name="rm1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rm1',rm1.value)">Update</button></td>
						<td>{{ sensorValue('um1') }}db</td>
						<td><input [disabled]="!canEdit()" type="number" #um1 name="um1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('um1',um1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Update rate (off, seconds)</td>
						<td>&nbsp;</td><!--<td>{{ sensorValue('rtm') }}</td>-->
						<td>&nbsp;</td><!--<td>{{ sensorValue('rtime') }}</td>-->
						<td>&nbsp;</td><!--td><input [disabled]="!canEdit()" type="number" #rtime name="rtime" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rtime',rtime.value)">Update</button></td>-->
						<td>{{ sensorValue('lrtime') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #lrtime name="lrtime" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lrtime',lrtime.value)">Update</button></td>
						<td>{{ sensorValue('trtime') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #trtime name="trtime" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('trtime',trtime.value)">Update</button></td>
					</tr>
					<tr>
						<td>Update rate (on, seconds)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lrtims') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #lrtims name="lrtims" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lrtims',lrtims.value)">Update</button></td>
						<td>{{ sensorValue('trtims') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #trtims name="trtims" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('trtims',trtims.value)">Update</button></td>
					</tr>
					<tr>
						<td>Heater hysteresis (degrees)</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('hst') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #hst name="hst" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('hst',hst.value)">Update</button></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>kWh multiplier</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('kwh') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #kwh name="kwh" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('kwh',kwh.value)">Update</button></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>ES multiplier</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('ess') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #ess name="ess" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ess',ess.value)">Update</button></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Stove info</td>
						<td colspan="7"><input [disabled]="!canEdit()" #stoveinfo name="stoveinfo" value="{{ paramValue('stoveinfo') }}" style="width: 40em"/><button [disabled]="!canEdit()" (click)="setParamValue('stoveinfo',stoveinfo.value)">Update</button></td>
					</tr>
					<tr>
						<td>Sauna info</td>
						<td colspan="7"><input [disabled]="!canEdit()" #saunainfo name="saunainfo" value="{{ paramValue('saunainfo') }}" style="width: 40em"/><button [disabled]="!canEdit()" (click)="setParamValue('saunainfo',saunainfo.value)">Update</button></td>
					</tr>
					<!--
					<tr>
						<td>Update rate change (seconds)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lrti') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #lrti name="lrti" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lrti',lrti.value)">Update</button></td>
						<td>{{ sensorValue('trti') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #trti name="trti" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('trti',trti.value)">Update</button></td>
					</tr>
					-->
					<tr>
						<td>History preserved (days)</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('hist') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #hist name="hist" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('hist',hist.value)">Update</button></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Email alerts</td>
						<td colspan="7"><input [disabled]="!canEdit()" #email name="email" value="{{paramValue('email')}}" style="width: 40em"/><button [disabled]="!canEdit()" (click)="setParamValue('email',email.value)">Update</button></td>
					</tr>
					<tr>
						<td>Time zone</td>
						<td colspan="7"><input [disabled]="!canEdit()" #tz name="tz" value="{{sensorValue('tz')}}" style="width: 40em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tz',tz.value)">Update</button></td>
					</tr>
					<tr>
						<td>FW Update URL</td>
						<td colspan="7"><input [disabled]="!canEdit()" #fwurl name="fwurl" value="{{sensorValue('fwurl')}}" style="width: 40em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('fwurl',fwurl.value)">Update</button></td>
					</tr>
					<tr>
						<td>Reboot/Update</td>
						<td>&nbsp;</td>
						<td colspan="1"><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sys',1)">Restart</button></td>
						<td colspan="1"><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sys',22)">Check FW</button></td>
						<td colspan="1"><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sys',20)">Update FW</button></td>
						<td colspan="1"><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sys',21)">Force FW Update</button></td>
						<td colspan="1"><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sys',90)">Reboot</button></td>
						<td colspan="1"><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sys',95)">Power off</button></td>
					</tr>
				</tbody>
			</table>
		</div>

		<div *ngIf="selectedThing.thingType==3 && advancedMode">
			<table class="spreadsheet table table-condensed table-striped table-bordered thing-table">
				<thead>
					<tr>
						<th>Parameter</th>
						<th>Measured value</th>
						<th>Set value</th>
						<th>Update set value</th>
						<th>Limit Min</th>
						<th>Update Limit Min</th>
						<th>Limit Max</th>
						<th>Update Limit Max</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Stove state</td>
						<td colspan="3">
							State: <ng-template [ngIf]="sensorValue('st')>=1">On</ng-template><ng-template [ngIf]="sensorValue('st')==0 || sensorValue('st')=='-'">Off</ng-template>,
							Heaters: <ng-template [ngIf]="isHeaters()">On</ng-template><ng-template [ngIf]="!isHeaters()">Off</ng-template>,
							Contactor: <ng-template [ngIf]="isContactor()>0">On</ng-template><ng-template [ngIf]="isContactor()==0">Off</ng-template><ng-template [ngIf]="isContactor()<0">Safe mode</ng-template>
							Lid: <ng-template [ngIf]="hasLid()>0">Yes</ng-template><ng-template [ngIf]="hasLid()==0">No</ng-template>
						</td>
						<td colspan="5">
							<button [disabled]="isServiceMode()" (click)="setService(1)">Activate service mode</button>
							<button [disabled]="!isServiceMode()" (click)="setService(0)">Deactivate service mode</button>
						</td>
					</tr>

					<tr class="servicemode" *ngIf="isServiceMode()">
						<td>SAFETY CONTACTOR</td>
						<td>{{ service_Contactor() }}</td>
						<td colspan="2">
							<button (click)="setServiceContactor(2)">Safe mode</button>
							<button (click)="setServiceContactor(1)">On</button>
							<button (click)="setServiceContactor(0)">Off (auto)</button>
						</td>
						<td>&nbsp;</td>
						<td colspan="2">
							<button (click)="startSelfTest(1)">Execute self test</button>
						</td>
					</tr>
					<tr class="servicemode" *ngIf="isServiceMode()">
						<td>HEATERS</td>
						<td>{{ sensorValue('ht1') }}/{{ sensorValue('ht4') }}/{{ sensorValue('ht2') }}/{{ sensorValue('ht5') }}/{{ sensorValue('ht3') }}/{{ sensorValue('ht6') }}</td>
						<td colspan="2">{{ service_Heaters() }}</td>
						<td colspan="4">
							<button (click)="setServiceHeater(0)">H1L1<ng-template [ngIf]="serviceIsHeater(0)">*</ng-template></button>
							<button (click)="setServiceHeater(1)">H2L1<ng-template [ngIf]="serviceIsHeater(1)">*</ng-template></button>
							<button (click)="setServiceHeater(2)">H1L2<ng-template [ngIf]="serviceIsHeater(2)">*</ng-template></button>
							<button (click)="setServiceHeater(3)">H2L2<ng-template [ngIf]="serviceIsHeater(3)">*</ng-template></button>
							<button (click)="setServiceHeater(4)">H1L3<ng-template [ngIf]="serviceIsHeater(4)">*</ng-template></button>
							<button (click)="setServiceHeater(5)">H2L3<ng-template [ngIf]="serviceIsHeater(5)">*</ng-template></button>
						</td>
					</tr>
					<tr class="servicemode" *ngIf="isServiceMode()">
						<td>FAN</td>
						<td>{{ sensorValue('f1') }} RPM</td>
						<td>{{ sensorValue('f2') }}%</td>
						<td>
							<input type="number" #slf name="slf" style="width: 4em"/><button (click)="setServiceFan(slf.value)">Update</button>
						</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr class="servicemode" *ngIf="isServiceMode()">
						<td>HEATSINK FAN</td>
						<td>{{ service_Heatsink() }}</td>
						<td>&nbsp;</td>
						<td>
							<input type="number" #shlf name="shlf" style="width: 4em"/><button (click)="setServiceHeatsinkFan(shlf.value)">Update</button>
						</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr class="servicemode" *ngIf="isServiceMode()">
						<td>LID STATE</td>
						<td>
							<ng-template [ngIf]="sensorValue('l2')==0">Closed</ng-template>
							<ng-template [ngIf]="sensorValue('l2')==1">Open</ng-template>
							<ng-template [ngIf]="sensorValue('l2')==2">Stopped</ng-template>
							<ng-template [ngIf]="sensorValue('l2')==4">Opening</ng-template>
							<ng-template [ngIf]="sensorValue('l2')==5">Closing</ng-template>
							<ng-template [ngIf]="sensorValue('l2')==6">Closing</ng-template>
							</td>
						<td colspan="2"><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lida', '0')">Close</button><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lida', '1')">Open</button><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lida', '2')">Stop</button></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>

					<tr>
						<td>Sauna temperature</td>
						<td>{{ sensorValue('t1') }}°C</td>
						<td><ng-template [ngIf]="sensorValue('sta2')>0">{{ sensorValue('sta2') }}°C</ng-template><ng-template [ngIf]="sensorValue('sta2')<=0">-</ng-template></td>
						<td><input [disabled]="!canEdit()" type="number" #sta2 name="sta2" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sta2',sta2.value)">Update</button></td>
						<td>{{ sensorValue('lt1') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #lt1 name="lt1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lt1',lt1.value)">Update</button></td>
						<td>{{ sensorValue('tt1') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #tt1 name="tt1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tt1',tt1.value)">Update</button></td>
					</tr>
					<tr>
						<td>CPU temperature</td>
						<td>{{ sensorValue('t6') }}°C</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Sauna hours</td>
						<td>{{ sensorValue('hr') }}h</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Heater hours/kWh</td>
						<td>{{ sensorValue('hhr') }}h</td>
						<td>{{ sensorValue('pwr') }}kWh</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Sauna temperature alert</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rha') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #rha name="rha" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rha',rha.value)">Update</button></td>
					</tr>
					<tr>
						<td>Sauna temperature shutdown</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('roha') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #roha name="roha" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('roha',roha.value)">Update</button></td>
					</tr>
					<tr>
						<td>Stove temperature</td>
						<td>{{ sensorValue('t3') }}°C</td>
						<td><ng-template [ngIf]="sensorValue('stm2')>0">{{ sensorValue('stm2') }}°C</ng-template><ng-template [ngIf]="sensorValue('stm2')==0">-</ng-template></td>
						<td><input [disabled]="!canEdit()" type="number" #stm2 name="stm2" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('stm2',stm2.value)">Update</button></td>
						<td>{{ sensorValue('lt3') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #lt3 name="lt3" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lt3',lt3.value)">Update</button></td>
						<td>{{ sensorValue('tt3') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #tt3 name="tt3" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tt3',tt3.value)">Update</button></td>
					</tr>
					<tr>
						<td>Stove temperature alert</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('sla') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #sla name="sla" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sla',sla.value)">Update</button></td>
						<td>{{ sensorValue('sha') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #sha name="sta" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sha',sha.value)">Update</button></td>
					</tr>
					<tr>
						<td>Stove temperature shutdown</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('soha') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #soha name="soha" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('soha',soha.value)">Update</button></td>
					</tr>
<!--
					<tr>
						<td>Ceiling temperature</td>
						<td>{{ sensorValue('t2') }}°C</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lt2') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #lt2 name="lt2" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lt2',lt2.value)">Update</button></td>
						<td>{{ sensorValue('tt2') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #tt2 name="tt2" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tt2',tt2.value)">Update</button></td>
					</tr>
-->
					<tr>
						<td>Circuit board temperature</td>
						<td>{{ sensorValue('t4') }}°C</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lt4') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #lt4 name="lt4" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lt4',lt4.value)">Update</button></td>
						<td>{{ sensorValue('tt4') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #tt4 name="tt4" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tt4',tt4.value)">Update</button></td>
					</tr>
					<tr>
						<td>Circuit board fan (50%)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lcfl4') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #lcfl4 name="lcfl4" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lcfl4',lcfl4.value)">Update</button></td>
						<td>{{ sensorValue('tcfl4') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #tcfl4 name="tcfl4" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tcfl4',tcfl4.value)">Update</button></td>
					</tr>
					<tr>
						<td>Circuit board fan (100%)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('tcfh4') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #tcfh4 name="tcfh4" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tcfh4',tcfh4.value)">Update</button></td>
					</tr>
					<tr>
						<td>Heat sink temperature</td>
						<td>{{ sensorValue('t5') }}°C</td>
						<td colspan="2">Fan status: {{ getHeatsinkFan() }}</td>
						<td>{{ sensorValue('lt5') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #lt5 name="lt5" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lt5',lt5.value)">Update</button></td>
						<td>{{ sensorValue('tt5') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #tt5 name="tt5" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tt5',tt5.value)">Update</button></td>
					</tr>

					<tr *ngIf="hasLid()">
						<td>Fan speed (lid closed)</td>
						<td><ng-template [ngIf]="sensorValue('l2')==0">{{ sensorValue('f1') }} RPM</ng-template></td>
						<td><ng-template [ngIf]="sensorValue('l2')==0">{{ sensorValue('f2') }}%</ng-template></td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lf1c') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #lf1c name="lf1c" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lf1c',lf1c.value)">Update</button></td>
						<td>&nbsp;</td>
					</tr>
					<tr *ngIf="!hasLid()">
						<td>Fan speed (inactive)</td>
						<td><ng-template [ngIf]="sensorValue('l2')==0">{{ sensorValue('f1') }} RPM</ng-template></td>
						<td><ng-template [ngIf]="sensorValue('l2')==0">{{ sensorValue('f2') }}%</ng-template></td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lf1c') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #lf1c name="lf1c" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lf1c',lf1c.value)">Update</button></td>
						<td>&nbsp;</td>
					</tr>
					<tr *ngIf="hasLid()">
						<td>Fan speed (lid open)</td>
						<td><ng-template [ngIf]="sensorValue('l2')!=0">{{ sensorValue('f1') }} RPM</ng-template></td>
						<td><ng-template [ngIf]="sensorValue('l2')!=0">{{ sensorValue('f2') }}%</ng-template></td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lf1') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #lf1 name="lf1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lf1',lf1.value)">Update</button></td>
						<td>{{ sensorValue('tf1') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #tf1 name="tf1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tf1',tf1.value)">Update</button></td>
					</tr>
					<tr *ngIf="!hasLid()">
						<td>Fan speed (active)</td>
						<td><ng-template [ngIf]="sensorValue('l2')!=0">{{ sensorValue('f1') }} RPM</ng-template></td>
						<td><ng-template [ngIf]="sensorValue('l2')!=0">{{ sensorValue('f2') }}%</ng-template></td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lf1') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #lf1 name="lf1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lf1',lf1.value)">Update</button></td>
						<td>{{ sensorValue('tf1') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #tf1 name="tf1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tf1',tf1.value)">Update</button></td>
					</tr>
					<tr *ngIf="hasLid()">
						<td>Time lid open</td>
						<td>{{ convertHoursMins(sensorValue('l1')) }}</td>
						<td>{{ convertHoursMins(sensorValue('lidt')) }}</td>
						<td><input [disabled]="!canEdit()" type="number" #lidt name="lidt" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lidt',lidt.value)">Update</button></td>
						<td>{{ sensorValue('tl1') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #tl1 name="tl1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tl1',tl1.value)">Update</button></td>
						<td>{{ sensorValue('ll1') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #ll1 name="ll1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ll1',ll1.value)">Update</button></td>
					</tr>
					<tr *ngIf="!hasLid()">
						<td>Time until inactive</td>
						<td>{{ convertHoursMins(sensorValue('l1')) }}</td>
						<td>{{ convertHoursMins(sensorValue('lidt')) }}</td>
						<td><input [disabled]="!canEdit()" type="number" #lidt name="lidt" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lidt',lidt.value)">Update</button></td>
						<td>{{ sensorValue('tl1') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #tl1 name="tl1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tl1',tl1.value)">Update</button></td>
						<td>{{ sensorValue('ll1') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #ll1 name="ll1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ll1',ll1.value)">Update</button></td>
					</tr>
<!--
					<tr>
						<td>Lid potentiometer</td>
						<td>{{ sensorValue('l2') }}</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('ll2') }}</td>
						<td><input type="number" #ll2 name="ll2" style="width: 4em"/><button (click)="setSensorValueMinMax('ll2',ll2.value)">Update</button></td>
						<td>{{ sensorValue('tl2') }}</td>
						<td><input type="number" #tl2 name="tl2" style="width: 4em"/><button (click)="setSensorValueMinMax('tl2',tl2.value)">Update</button></td>
					</tr>
-->
					<tr *ngIf="hasLid()">
						<td>Lid open/close current</td>
						<td>{{ sensorValue('l3') }}</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('loc') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #loc name="loc" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('loc',loc.value)">Update</button></td>
						<td>{{ sensorValue('lcc') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #lcc name="lcc" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lcc',lcc.value)">Update</button></td>
					</tr>
					<tr *ngIf="hasLid()">
						<td>Lid open/close timeout</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lot') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #lot name="lot" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lot',lot.value)">Update</button></td>
						<td>{{ sensorValue('lct') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #lct name="lct" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lct',lct.value)">Update</button></td>
					</tr>
					<tr>
						<td>Heat PID parameters</td>
						<td colspan="2">{{ sensorValue('hpid') }}</td>
						<td colspan="2"><input [disabled]="!canEdit()" #hpid name="hpid" style="width: 10em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('hpid',hpid.value)">Update</button></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Heaters</td>
						<td>{{ sensorValue('ht') }}</td>
						<td colspan="2"><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('htd', '511')">Disable all</button><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('htd', '0')">Enable all</button></td>
						<td>{{ sensorValue('lht') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #lht name="lht" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lht',lht.value)">Update</button></td>
						<td>{{ sensorValue('tht') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #tht name="tht" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tht',tht.value)">Update</button></td>
					</tr>
					<tr>
						<td>Heater H1L1 current</td>
						<td>{{ sensorValue('ht1') }}</td>
						<td><ng-template [ngIf]="sensorValue('ht1d')>0">Disabled</ng-template><ng-template [ngIf]="sensorValue('ht1d')==0 || sensorValue('ht1d')=='-'">Enabled</ng-template></td>
						<td><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ht1d', '1')">Disable</button><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ht1e', '1')">Enable</button></td>
						<td>{{ heaterActive(1) }}</td>
						<td>{{ heaterDisabled(1) }}</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Heater H2L1 current</td>
						<td>{{ sensorValue('ht4') }}</td>
						<td><ng-template [ngIf]="sensorValue('ht4d')>0">Disabled</ng-template><ng-template [ngIf]="sensorValue('ht4d')==0 || sensorValue('ht4d')=='-'">Enabled</ng-template></td>
						<td><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ht4d', '1')">Disable</button><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ht4e', '1')">Enable</button></td>
						<td>{{ heaterActive(4) }}</td>
						<td>{{ heaterDisabled(4) }}</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Heater H1L2 current</td>
						<td>{{ sensorValue('ht2') }}</td>
						<td><ng-template [ngIf]="sensorValue('ht2d')>0">Disabled</ng-template><ng-template [ngIf]="sensorValue('ht2d')==0 || sensorValue('ht2d')=='-'">Enabled</ng-template></td>
						<td><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ht2d', '1')">Disable</button><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ht2e', '1')">Enable</button></td>
						<td>{{ heaterActive(2) }}</td>
						<td>{{ heaterDisabled(2) }}</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Heater H2L2 current</td>
						<td>{{ sensorValue('ht5') }}</td>
						<td><ng-template [ngIf]="sensorValue('ht5d')>0">Disabled</ng-template><ng-template [ngIf]="sensorValue('ht5d')==0 || sensorValue('ht5d')=='-'">Enabled</ng-template></td>
						<td><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ht5d', '1')">Disable</button><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ht5e', '1')">Enable</button></td>
						<td>{{ heaterActive(5) }}</td>
						<td>{{ heaterDisabled(5) }}</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Heater H1L3 current</td>
						<td>{{ sensorValue('ht3') }}</td>
						<td><ng-template [ngIf]="sensorValue('ht3d')>0">Disabled</ng-template><ng-template [ngIf]="sensorValue('ht3d')==0 || sensorValue('ht3d')=='-'">Enabled</ng-template></td>
						<td><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ht3d', '1')">Disable</button><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ht3e', '1')">Enable</button></td>
						<td>{{ heaterActive(3) }}</td>
						<td>{{ heaterDisabled(3) }}</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Heater H2L3 current</td>
						<td>{{ sensorValue('ht6') }}</td>
						<td><ng-template [ngIf]="sensorValue('ht6d')>0">Disabled</ng-template><ng-template [ngIf]="sensorValue('ht6d')==0 || sensorValue('ht6d')=='-'">Enabled</ng-template></td>
						<td><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ht6d', '1')">Disable</button><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ht6e', '1')">Enable</button></td>
						<td>{{ heaterActive(6) }}</td>
						<td>{{ heaterDisabled(6) }}</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Humidity alert</td>
						<td>{{ sensorValue('h1') }}%</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lh1') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #lh1 name="lh1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lh1',lh1.value)">Update</button></td>
						<td>{{ sensorValue('th1') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #th1 name="th1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('th1',th1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Co2 alert</td>
						<td>{{ sensorValue('c1') }}</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lc1') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #lc1 name="lc1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lc1',lc1.value)">Update</button></td>
						<td>{{ sensorValue('tc1') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #tc1 name="tc1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tc1',tc1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Noise alert</td>
						<td>{{ sensorValue('m1') }}db</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lm1') }}db</td>
						<td><input [disabled]="!canEdit()" type="number" #lm1 name="lm1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lm1',lm1.value)">Update</button></td>
						<td>{{ sensorValue('tm1') }}db</td>
						<td><input [disabled]="!canEdit()" type="number" #tm1 name="tm1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tm1',tm1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Sauna temperature (min/delta)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rt1') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #rt1 name="rt1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rt1',rt1.value)">Update</button></td>
						<td>{{ sensorValue('ut1') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #ut1 name="ut1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ut1',ut1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Stove temperature (min/delta)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rt3') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #rt3 name="rt3" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rt3',rt3.value)">Update</button></td>
						<td>{{ sensorValue('ut3') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #ut3 name="ut3" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ut3',ut3.value)">Update</button></td>
					</tr>
					<tr>
						<td>PCB temperature (min/delta)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rt4') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #rt4 name="rt4" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rt4',rt4.value)">Update</button></td>
						<td>{{ sensorValue('ut4') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #ut4 name="ut4" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ut4',ut4.value)">Update</button></td>
					</tr>
					<tr>
						<td>Heat sink temp (min/delta)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rt5') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #rt5 name="rt5" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rt5',rt5.value)">Update</button></td>
						<td>{{ sensorValue('ut5') }}°C</td>
						<td><input [disabled]="!canEdit()" type="number" #ut5 name="ut5" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ut5',ut5.value)">Update</button></td>
					</tr>
					<tr>
						<td>Fan speed (min/delta)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rf1') }} RPM</td>
						<td><input [disabled]="!canEdit()" type="number" #rf1 name="rf1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rf1',rf1.value)">Update</button></td>
						<td>{{ sensorValue('uf1') }} RPM</td>
						<td><input [disabled]="!canEdit()" type="number" #uf1 name="uf1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('uf1',uf1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Fan set speed (min/delta)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rf2') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #rf2 name="rf2" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rf2',rf2.value)">Update</button></td>
						<td>{{ sensorValue('uf2') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #uf2 name="uf2" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('uf2',uf2.value)">Update</button></td>
					</tr>
					<tr>
						<td>Humidity (min/delta)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rh1') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #rh1 name="rh1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rh1',rh1.value)">Update</button></td>
						<td>{{ sensorValue('uh1') }}%</td>
						<td><input [disabled]="!canEdit()" type="number" #uh1 name="uh1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('uh1',uh1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Co2 (min/delta)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rc1') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #rc1 name="rc1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rc1',rc1.value)">Update</button></td>
						<td>{{ sensorValue('uc1') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #uc1 name="uc1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('uc1',uc1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Noise (min/delta)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('rm1') }}db</td>
						<td><input [disabled]="!canEdit()" type="number" #rm1 name="rm1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rm1',rm1.value)">Update</button></td>
						<td>{{ sensorValue('um1') }}db</td>
						<td><input [disabled]="!canEdit()" type="number" #um1 name="um1" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('um1',um1.value)">Update</button></td>
					</tr>
					<tr>
						<td>Update rate (off, seconds)</td>
						<td>&nbsp;</td><!--<td>{{ sensorValue('rtm') }}</td>-->
						<td>&nbsp;</td><!--<td>{{ sensorValue('rtime') }}</td>-->
						<td>&nbsp;</td><!--td><input [disabled]="!canEdit()" type="number" #rtime name="rtime" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('rtime',rtime.value)">Update</button></td>-->
						<td>{{ sensorValue('lrtime') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #lrtime name="lrtime" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lrtime',lrtime.value)">Update</button></td>
						<td>{{ sensorValue('trtime') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #trtime name="trtime" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('trtime',trtime.value)">Update</button></td>
					</tr>
					<tr>
						<td>Update rate (on, seconds)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lrtims') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #lrtims name="lrtims" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lrtims',lrtims.value)">Update</button></td>
						<td>{{ sensorValue('trtims') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #trtims name="trtims" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('trtims',trtims.value)">Update</button></td>
					</tr>
					<tr>
						<td>Heater hysteresis (degrees)</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('hst') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #hst name="hst" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('hst',hst.value)">Update</button></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>kWh multiplier</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('kwh') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #kwh name="kwh" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('kwh',kwh.value)">Update</button></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>ES multiplier</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('ess') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #ess name="ess" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('ess',ess.value)">Update</button></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Stove info</td>
						<td colspan="7"><input [disabled]="!canEdit()" #stoveinfo name="stoveinfo" value="{{ paramValue('stoveinfo') }}" style="width: 40em"/><button [disabled]="!canEdit()" (click)="setParamValue('stoveinfo',stoveinfo.value)">Update</button></td>
					</tr>
					<tr>
						<td>Sauna info</td>
						<td colspan="7"><input [disabled]="!canEdit()" #saunainfo name="saunainfo" value="{{ paramValue('saunainfo') }}" style="width: 40em"/><button [disabled]="!canEdit()" (click)="setParamValue('saunainfo',saunainfo.value)">Update</button></td>
					</tr>
					<!--
					<tr>
						<td>Update rate change (seconds)</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('lrti') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #lrti name="lrti" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('lrti',lrti.value)">Update</button></td>
						<td>{{ sensorValue('trti') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #trti name="trti" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('trti',trti.value)">Update</button></td>
					</tr>
					-->
					<tr>
						<td>History preserved (days)</td>
						<td>&nbsp;</td>
						<td>{{ sensorValue('hist') }}</td>
						<td><input [disabled]="!canEdit()" type="number" #hist name="hist" style="width: 4em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('hist',hist.value)">Update</button></td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>Email alerts</td>
						<td colspan="7"><input [disabled]="!canEdit()" #email name="email" value="{{paramValue('email')}}" style="width: 40em"/><button [disabled]="!canEdit()" (click)="setParamValue('email',email.value)">Update</button></td>
					</tr>
					<tr>
						<td>Time zone</td>
						<td colspan="7"><input [disabled]="!canEdit()" #tz name="tz" value="{{sensorValue('tz')}}" style="width: 40em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('tz',tz.value)">Update</button></td>
					</tr>
					<tr>
						<td>FW Update URL</td>
						<td colspan="7"><input [disabled]="!canEdit()" #fwurl name="fwurl" value="{{sensorValue('fwurl')}}" style="width: 40em"/><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('fwurl',fwurl.value)">Update</button></td>
					</tr>
					<tr>
						<td>Reboot/Update</td>
						<td>&nbsp;</td>
						<td colspan="1"><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sys',1)">Restart</button></td>
						<td colspan="1"><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sys',22)">Check FW</button></td>
						<td colspan="1"><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sys',20)">Update FW</button></td>
						<td colspan="1"><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sys',21)">Force FW Update</button></td>
						<td colspan="1"><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sys',90)">Reboot</button></td>
						<td colspan="1"><button [disabled]="!canEdit()" (click)="setSensorValueMinMax('sys',95)">Power off</button></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
