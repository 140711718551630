<nav class="navbar navbar-toggleable-md navbar-inverse bg-primary">
    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand saunamonitor" routerLink="/dashboard">
        <i class="fa fa-line-chart" aria-hidden="true"></i>
        Saunamonitor
    </a>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item" *ngIf="authService.isLoggedIn">
                <a class="nav-link saunamonitor_item" href="#" routerLink="/devices">{{'menu.devices' | translate}}</a>
            </li>
            <!--<li class="nav-item" *ngIf="authService.isLoggedIn && schedulerEnabled()">
                <a class="nav-link saunamonitor_item" href="#" routerLink="/schedules">{{'menu.schedules' | translate}}</a>
            </li>
            <li class="nav-item" *ngIf="authService.isLoggedIn">
                <a class="nav-link" href="#" routerLink="/ota">{{'menu.ota' | translate}}</a>
            </li>-->
            <li class="nav-item" *ngIf="authService.hasRole('admin')">
                <a class="nav-link saunamonitor_item" href="#" [routerLink]="['/admin']">{{'menu.admin' | translate}}</a>
            </li>
            <li class="nav-item" *ngIf="authService.hasRole('admin')">
                <a class="nav-link saunamonitor_item" href="#" [routerLink]="['/admin', 'admin']">{{'menu.adminadmin' | translate}}</a>
            </li>
            <li class="nav-item" *ngIf="authService.hasRole('admin')">
                <a class="nav-link saunamonitor_item" href="#" [routerLink]="['/admin', 'user']">{{'menu.adminuser' | translate}}</a>
            </li>
        </ul>
        <ul class="navbar-nav">
            <li class="nav-item dropdown" *ngIf="authService.isLoggedIn">
                <a class="nav-link dropdown-toggle saunamonitor_item" href="#" id="navbarDropdownUser"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-user-circle-o user-menu-image" aria-hidden="true"></i>
                    {{authService.email}}
                </a>
                <div class="dropdown-menu dropdown-menu-left user-menu" aria-labelledby="navbarDropdownUser">
                    <a class="dropdown-item user-menu-item saunamonitor_item" routerLink="/profile">
                        <i class="fa fa-address-card-o user-menu-item-image" aria-hidden="true"></i>
                        {{'menu.profile' | translate}}
                    </a>
                    <a class="dropdown-item user-menu-item saunamonitor_item" href="#" (click)="logout()">
                        <i class="fa fa-sign-out user-menu-item-image" aria-hidden="true"></i>
                        {{'menu.logout' | translate}}
                    </a>
                </div>
            </li>
            <li class="nav-item dropdown" *ngIf="authService.isLoggedIn">
                <a class="nav-link dropdown-toggle saunamonitor_item" href="#" id="navbarDropdownLanguage"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src="/assets/img/{{getLanguage()}}.png" width="30px" height="20px">
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownLanguage">
                    <a class="dropdown-item language-menu-item saunamonitor_item" *ngFor="let lang of ['en','fi']" (click)="setLanguage(lang)">
                        <img src="/assets/img/{{lang}}.png" width="30px" height="20px">
                        <span class="language-menu-text">{{lang}}</span>
                    </a>
                </div>
            </li>
        </ul>
    </div>
</nav>
