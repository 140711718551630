<div class="row">
    <div class="col-md-12" *ngIf="selectedThing">
         <span class="thing-title">
            <i class="fa" aria-hidden="true"></i>
            <a ref="#" routerLink="/details/{{selectedThing.name}}">{{selectedThing.label}}</a>
        </span>
        <app-spinner [loading]="loading"></app-spinner>
    </div>
</div>
<hr>

<div class="row">
    <div class="col-md-4">
        <h3>
            {{'schedules.schedules' | translate}}
            <i *ngIf="normalModeEdit" class="fa fa-plus-square float-right" [ngClass]="{'fa-disabled' : !newScheduleAllowed()}" aria-hidden="true" (click)="newSchedule()"></i>
        </h3>
        <table class="table schedules-table">
            <tbody>
                <tr *ngFor="let schedule of schedules"
                    [ngClass]="{'table-active' : (selectedSchedule && (schedule.id === selectedSchedule.id || schedule.active))}"
                    [ngClass]="{'table-inverse' : (schedule.userOwned != true)}">
                    <td>
                        <div *ngIf="!schedule.endsInSameDay()" class="schedule-text-item">
                            {{schedule.startDate | date: 'dd.MM.yyyy'}} - {{schedule.endDate | date: 'dd.MM.yyyy'}}
                        </div>
                        <div *ngIf="schedule.endsInSameDay()" class="schedule-text-item">
                            {{schedule.startDate | date: 'dd.MM.yyyy'}}
                        </div>
                        <div class="schedule-text-item">
                            {{schedule.startTime.toString()}} - {{schedule.endTime.toString()}}
                        </div>
                        <div *ngIf="!schedule.endsInSameDay()">
                            <button type="text" class="btn btn-sm weekday-schedule-list-button"
                                *ngFor="let wd of schedule.weekdays"
                                [ngClass]="wd.enabled ? 'btn-primary' : 'btn-secondary'">
                                {{('schedules.weekdays_short' | translate)[wd.day]}}
                            </button>
                        </div>
                    </td>
                    <td *ngIf="hasLid()">
                        <div>Sauna: {{schedule.targetTemperature}}°C</div>
                        <div *ngIf="selectedThing.enhancedControls">{{('schedules.stove' | translate)}}: {{schedule.stonesTemperature}}°C</div>
                        <div *ngIf="selectedThing.enhancedControls">{{('schedules.lid' | translate)}}: {{convertHoursMins(schedule.lidDuration)}}</div>
                        <div *ngIf="schedule.mode!=1">{{('schedules.mode' | translate)}}: {{('schedules.mode_normal' | translate)}}</div>
                        <div *ngIf="schedule.mode==1">{{('schedules.mode' | translate)}}: {{('schedules.mode_es' | translate)}}</div>    
                    </td>
                    <td *ngIf="!hasLid()">
                        <div>Sauna: {{schedule.targetTemperature}}°C</div>
                        <div *ngIf="selectedThing.enhancedControls">{{('schedules.stove' | translate)}}: {{schedule.stonesTemperature}}°C</div>
                        <div *ngIf="selectedThing.enhancedControls">{{('schedules.lid2' | translate)}}: {{convertHoursMins(schedule.lidDuration)}}</div>
                        <div *ngIf="schedule.mode!=1">{{('schedules.mode' | translate)}}: {{('schedules.mode_normal' | translate)}}</div>
                        <div *ngIf="schedule.mode==1">{{('schedules.mode' | translate)}}: {{('schedules.mode_es' | translate)}}</div>    
                    </td>
                    <td class="text-right" style="width: 92px;">
                        <app-toggle-button *ngIf="normalModeEdit" [enabled]="schedule.enabled" (toggleChange)="toggleChange(schedule)" [disabled]="!normalModeEdit"></app-toggle-button>
                        <div *ngIf="!normalModeEdit && schedule.enabled">On</div>
                        <div *ngIf="!normalModeEdit && !schedule.enabled">Off</div>
                        <button *ngIf="normalModeEdit" type="button" class="btn btn-sm btn-secondary" (click)="removeSchedule(schedule)" [disabled]="!normalModeEdit">
                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                        <button *ngIf="normalModeEdit" type="button" class="btn btn-sm btn-secondary" (click)="selectSchedule(schedule)" [disabled]="!normalModeEdit">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </button>
                        <div *ngIf="!normalModeEdit">&nbsp;</div>
                        <app-spinner class="schedule-spinner" [loading]="!schedule.synced"></app-spinner>
                        <span *ngIf="schedule.synced" class="led" [ngClass]="{'led-green': isScheduleRunning(schedule), 'led-gray': !isScheduleRunning(schedule)}"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="col-md-8" *ngIf="selectedSchedule" [readonly]="true" >
        <h3>{{'schedules.modify_schedule' | translate}}</h3>
        <div class="row">
            <div class="col-md-5 datepicker">
                <span>
                    <datepicker [(ngModel)]="selectedSchedule.startDate" [startingDay]="1" [showWeeks]="false" (ngModelChange)="startDateChanged()"></datepicker>
                </span>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-md-2 col-form-label">{{'schedules.time' | translate}}</label>
                    <div class="col-md-10">
                        <input type="text" class="form-control time" name="startTime"
                            [ngModel]="formatTime(selectedSchedule, 0)" #startTime="ngModel"
                            (blur)="parseTime(selectedSchedule.startTime, $event.target.value)"
                            (focusout)="updateTime(selectedSchedule)"
                            [disabled]="!normalModeEdit"
                            required pattern="\d{1,2}:\d{1,2}">&nbsp;-&nbsp;
                        <input type="text" class="form-control time" name="endTime"
                            [ngModel]="formatTime(selectedSchedule, 1)" #endTime="ngModel"
                            (blur)="parseTime(selectedSchedule.endTime, $event.target.value)"
                            [disabled]="!normalModeEdit"
                            required pattern="\d{1,2}:\d{1,2}">
                        <div *ngIf="(startTime.invalid || endTime.invalid)" class="has-danger">
                            <div class="form-control-feedback" *ngIf="startTime.errors && startTime.errors.required">
                                {{'schedules.validate_start_time_required' | translate}}
                            </div>
                            <div class="form-control-feedback" *ngIf="(startTime.errors && startTime.errors.pattern) || (endTime.errors && endTime.errors.pattern)">
                                {{'schedules.validate_time_format' | translate}}
                            </div>
                            <div class="form-control-feedback" *ngIf="endTime.errors && endTime.errors.required">
                                {{'schedules.validate_end_time_required' | translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{'schedules.repeat' | translate}}</label>
                    <div style="width: 100%;" class="has-danger">
                        <button type="text" class="btn btn-sm weekday-button"
                            (click)="selectedSchedule.weekdays[wd.day].enabled = !selectedSchedule.weekdays[wd.day].enabled"
                            *ngFor="let wd of selectedSchedule.weekdays"
                            [ngClass]="wd.enabled ? 'btn-primary' : 'btn-secondary'"
                            [disabled]="!normalModeEdit">
                            {{('schedules.weekdays_short' | translate)[wd.day]}}
                        </button>
                        <div class="form-control-feedback" *ngIf="!validateWeekdays(selectedSchedule)">
                            {{'schedules.validate_weekday_required' | translate}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-2 col-form-label">{{'schedules.until' | translate}}</label>
                    <div class="col-md-10" [ngClass]="{'has-danger' : !validateEndDate(selectedSchedule)}">
                        <div class="input-group">
                            <input type="text" class="form-control until" disabled value="{{selectedSchedule.endDate | date:'dd.MM.yyyy'}}">
                            <i class="fa fa-calendar input-group-addon" aria-hidden="true" (click)="toggleDatePicker()"></i>
                        </div>
                        <div class="form-control-feedback" *ngIf="!validateEndDate(selectedSchedule)">
                            {{'schedules.validate_end_date_invalid' | translate}}
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="selectedThing.advanced_pack">
                    <label>{{'schedules.mode' | translate}}</label>
                    <div style="width: 100%;">
                        <button type="text" class="btn btn-sm mode-button" (click)="setScheduleMode(0)" [ngClass]="selectedSchedule.mode==0 ? 'btn-primary' : 'btn-secondary'" [disabled]="!normalModeEdit">
                            {{('schedules.mode_normal' | translate)}}
                        </button>
                        &nbsp;
                        <button type="text" class="btn btn-sm mode-button" (click)="setScheduleMode(1)" [ngClass]="selectedSchedule.mode==1 ? 'btn-primary' : 'btn-secondary'" [disabled]="!normalModeEdit">
                            {{('schedules.mode_es' | translate)}}
                        </button>
                </div>
                <div class="form-group" *ngIf="selectedThing.controls">
                    <label>{{'schedules.temperature' | translate}}</label>
                    <div style="width: 100%">
                        <input type="range" min="20" max="120" step="1" [(ngModel)]="selectedSchedule.targetTemperature" [disabled]="!normalModeEdit">
                        <span>{{selectedSchedule.targetTemperature}}°C</span>
                    </div>
                </div>
                <div class="form-group" *ngIf="selectedThing.enhancedControls">
                    <label>{{'schedules.stones_temperature' | translate}}</label>
                    <div style="width: 100%">
                        <input type="range" min="120" max="400" step="1" [(ngModel)]="selectedSchedule.stonesTemperature" [disabled]="!normalModeEdit">
                        <span>{{selectedSchedule.stonesTemperature}}°C</span>
                    </div>
                </div>
                 <div class="form-group" *ngIf="selectedThing.enhancedControls">
                    <label *ngIf="hasLid()">{{'schedules.lid_duration' | translate}}</label>
                    <label *ngIf="!hasLid()">{{'schedules.lid2_duration' | translate}}</label>
                    <div style="width: 100%">
                        <input type="range" min="10" max="120" step="1" [(ngModel)]="selectedSchedule.lidDuration" [disabled]="!normalModeEdit">
                        <span>{{convertHoursMins(selectedSchedule.lidDuration)}}</span>
                    </div>
                </div>

                <app-date-picker
                    [(value)]="selectedSchedule.endDate"
                    [(showPopup)]="datePickerVisible">
                </app-date-picker>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 has-danger">
                <div class="form-control-feedback" *ngIf="!validateOverlaps(selectedSchedule, schedules)">
                    {{'schedules.validate_overlaps' | translate}}
                </div>
                <button type="text" class="btn btn-primary" (click)="saveScheduleEdit()" [disabled]="!normalModeEdit">{{'schedules.save' | translate}}</button>
                <button type="text" class="btn btn-secondary" (click)="cancelScheduleEdit()">{{'schedules.cancel' | translate}}</button>
            </div>
        </div>
    </div>
</div>
