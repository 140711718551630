export class Schedule {
    id: string
    enabled: boolean
    active: boolean
    startDate: Date
    endDate: Date
    weekdays: Weekday[] = []
    startTime: Time
    endTime: Time
    targetTemperature: number
    stonesTemperature: number
    lidDuration: number
    synced: boolean
    enhanced: boolean
    advanced: boolean
    mode : number
    pid : string
    userOwned : boolean

    constructor(id: string, enabled: boolean, startDate: Date, endDate: Date,
        startTime: Time, endTime: Time, weekdays: Weekday[],
        targetTemperature: number, stonesTemperature: number, lidDuration: number, mode : number, enhanced: boolean, advanced: boolean, synced: boolean) {
        this.id = id
        this.enabled = enabled
        this.startDate = startDate
        this.endDate = endDate
        this.weekdays = weekdays // mutable reference
        this.startTime = startTime
        this.endTime = endTime
        this.targetTemperature = targetTemperature
        this.stonesTemperature = stonesTemperature
        this.lidDuration = lidDuration
        this.mode = mode
        this.enhanced = enhanced
        this.advanced = advanced
        this.synced = synced
        this.userOwned = true
    }

    copyOf(): Schedule {
        let weekdaysCopy: Weekday[] = []
        this.weekdays.forEach(wd => {
            weekdaysCopy.push(new Weekday(wd.day, wd.enabled))
        })
        return new Schedule(
            this.id,
            this.enabled,
            new Date(this.startDate.getTime()),
            new Date(this.endDate.getTime()),
            new Time(this.startTime.hours, this.startTime.minutes),
            new Time(this.endTime.hours, this.endTime.minutes),
            weekdaysCopy,
            this.targetTemperature,
            this.stonesTemperature,
            this.lidDuration,
            this.mode,
            this.enhanced,
            this.advanced,
            this.synced)
    }

    equals(other: Schedule): boolean {
        if (this.enhanced) {
            return this.id === other.id
                && this.enabled === other.enabled
                && this.mode == other.mode
                && this.compareDatesWithoutTime(this.startDate, other.startDate)
                && this.startTime.equals(other.startTime)
                && this.compareDatesWithoutTime(this.endDate, other.endDate)
                && this.endTime.equals(other.endTime)
                && this.targetTemperature === other.targetTemperature
                && this.compareWeekdays(this.weekdays, other.weekdays)
        } else {
            return this.id === other.id
                && this.enabled === other.enabled
                && this.mode == other.mode
                && this.compareDatesWithoutTime(this.startDate, other.startDate)
                && this.startTime.equals(other.startTime)
                && this.compareDatesWithoutTime(this.endDate, other.endDate)
                && this.endTime.equals(other.endTime)
                && this.targetTemperature === other.targetTemperature
                && this.stonesTemperature === other.stonesTemperature
                && this.lidDuration === other.lidDuration
                && this.compareWeekdays(this.weekdays, other.weekdays)
        }
    }

    overlaps(other: Schedule): boolean {
        if (!this.enabled || !other.enabled) {
            return false
        }
        let thisStartDate: Date = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate(), 0, 0, 0, 0)
        let thisEndDate: Date = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate(), 0, 0, 0, 0)
        let otherStartDate: Date = new Date(other.startDate.getFullYear(), other.startDate.getMonth(), other.startDate.getDate(), 0, 0, 0, 0)
        let otherEndDate: Date = new Date(other.endDate.getFullYear(), other.endDate.getMonth(), other.endDate.getDate(), 0, 0, 0, 0)
        let overlaps: boolean = ((otherStartDate <= thisEndDate) && (otherEndDate >= thisStartDate))
        if (overlaps) {
            for (let d = 0; d < 7; d++) {
                if (this.weekdays[d].enabled && other.weekdays[d].enabled) {
                    let otherStartTime = other.startTime.toMinutes()
                    let thisEndTime = this.endTime.toMinutes()
                    let otherEndTime = other.endTime.toMinutes()
                    let thisStartTime = this.startTime.toMinutes()

                    // Handle case where day changes between start and end time
                    if (thisEndTime < thisStartTime) {
                        thisEndTime = thisEndTime + (24 * 60 * 60)
                    }
                    if (otherEndTime < otherStartTime) {
                        otherEndTime = otherEndTime + (24 * 60 * 60)
                    }

                    let overlaps: boolean = ((otherStartTime < thisEndTime) && (otherEndTime > thisStartTime))
                    if (overlaps) {
                        return true;
                    }
                }
            }
        }
        return false
    }

    private compareDatesWithoutTime(a: Date, b: Date) {
        return (a.getFullYear() === b.getFullYear()) &&
            (a.getMonth() === b.getMonth()) &&
            (a.getDate() === b.getDate())
    }

    private compareWeekdays(a: Weekday[], b: Weekday[]): boolean {
        if (a.length != b.length) {
            return false
        }
        let sorter = function (a: Weekday, b: Weekday): number {
            let result: number = a.day - b.day
            if (result === 0) {
                result = (a.enabled ? 1 : 0) - (b.enabled ? 1 : 0)
            }
            return result
        }
        let aSorted: Weekday[] = a.slice().sort(sorter)
        let bSorted: Weekday[] = b.slice().sort(sorter)
        for (let i = 0; i < aSorted.length; i++) {
            if (!aSorted[i].equals(bSorted[i])) {
                return false
            }
        }
        return true
    }

    endsInSameDay(): boolean {
        if (!this.startDate || !this.endDate) {
            return false
        }
        return this.endDate.getFullYear() === this.startDate.getFullYear() &&
            this.endDate.getMonth() === this.startDate.getMonth() &&
            this.endDate.getDate() === this.startDate.getDate()
    }

    toShadow(): string {
        // “2017-10-01_2017-11-01_18:00-20:00_54_46"
        let weekdayBinary: string = ''
        this.weekdays.forEach(wd => {
            weekdayBinary = (wd.enabled ? '1' : '0') + weekdayBinary
        })
        // Schedule enabled is bit number 7, bits 0-6 are weekdays. Example: 10000011 = schedule enabled on mondays and tuesdays
        weekdayBinary = (this.enabled ? '1' : '0') + weekdayBinary
        // Convert to binary to hex
        let weekdayHex = parseInt(weekdayBinary, 2).toString(16).toUpperCase()
        if (weekdayHex.length < 2) {
            weekdayHex = '0' + weekdayHex
        }

        let startDatetime: Date = new Date(
            this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate(),
            this.startTime.hours, this.startTime.minutes, 0, 0)
        let endDatetime: Date = new Date(
            this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate(),
            this.endTime.hours, this.endTime.minutes, 0, 0)

        let startDateStr: string = startDatetime.getFullYear() + '-' + padd(startDatetime.getMonth() + 1) + '-' + padd(startDatetime.getDate())
        let startTimeStr: string = padd(startDatetime.getHours()) + ':' + padd(startDatetime.getMinutes())
        let endDateStr: string = endDatetime.getFullYear() + '-' + padd(endDatetime.getMonth() + 1) + '-' + padd(endDatetime.getDate())
        let endTimeStr: string = padd(endDatetime.getHours()) + ':' + padd(endDatetime.getMinutes())
        let tempHex: string = this.targetTemperature.toString(16).toUpperCase()
        if (tempHex.length < 2) {
            tempHex = '0' + tempHex
        }
        if (this.enhanced) {
            let stonesTempHex: string = this.stonesTemperature.toString(16).toUpperCase()
            if (stonesTempHex.length < 2) {
                stonesTempHex = '00' + stonesTempHex
            } else if (stonesTempHex.length < 3) {
                stonesTempHex = '0' + stonesTempHex
            }
            let lidDurationHex: string = this.lidDuration.toString(16).toUpperCase()
            if (lidDurationHex.length < 2) {
                lidDurationHex = '0' + lidDurationHex
            }
            let s = startDateStr + '_' + endDateStr + '_' + startTimeStr + '-' + endTimeStr + '_' + weekdayHex + '_' + tempHex + '_' + stonesTempHex + '_' + lidDurationHex
            if (this.mode > 0) {
                let modeHex: string = this.mode.toString(16).toUpperCase()
                if (modeHex.length < 2) {
                    modeHex = '0' + modeHex
                }
                s = s + "_" + modeHex
            }
            return s
        } else if (this.advanced) {
            let modeHex: string = this.mode.toString(16).toUpperCase()
            if (modeHex.length < 2) {
                modeHex = '0' + modeHex
            }
            let s = startDateStr + '_' + endDateStr + '_' + startTimeStr + '-' + endTimeStr + '_' + weekdayHex + '_' + tempHex + '_000_00_' + modeHex
            return s
        } else {
            return startDateStr + '_' + endDateStr + '_' + startTimeStr + '-' + endTimeStr + '_' + weekdayHex + '_' + tempHex
        }
    }

    setScheduleDuration(duration : number)
    {
        let dt: Date = new Date(
            this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate(),
            this.startTime.hours, this.startTime.minutes, 0, 0)
            dt.setTime(dt.getTime() + duration * 60 * 1000)
        let endDate = new Date(dt.getTime())
        let endTime = new Time(dt.getHours(), dt.getMinutes())
        if (endDate.getDay != this.endDate.getDay || endTime.hours != this.endTime.hours || endTime.minutes != this.endTime.minutes) {
            this.endDate = endDate
            this.endTime = endTime
        }
    }

    getScheduleDuration(): number
    {
        let scheduleSingleDuration = this.endTime.hours * 60 + this.endTime.minutes
        scheduleSingleDuration -= this.startTime.hours * 60 + this.startTime.minutes
        if (scheduleSingleDuration < 0) {
            scheduleSingleDuration += 24 * 60;
        }
        return scheduleSingleDuration
    }

    static fromShadow(id: string, value: string, enhancedControls: boolean, advanced: boolean): Schedule {
        // “2017-10-01_2017-10-01_18:00-20:00_54_46_000"
        let splitted: string[] = value.split('_')
        let startDateSplitted: string[] = splitted[0].split('-')
        let endDateSplitted: string[] = splitted[1].split('-')
        let startTimeSplitted: string[] = splitted[2].split('-')[0].split(':')
        let endTimeSplitted: string[] = splitted[2].split('-')[1].split(':')
        let weekdayHex: string = splitted[3]
        let tempHex: string = splitted.length > 4 ? splitted[4] : '46'
        let stonesTempHex: string = splitted.length > 5 ? splitted[5] : '3c'
        let lidDurationHex: string = splitted.length > 6 ? splitted[6] : '78'
        let modeHex: string = splitted.length > 7 ? splitted[7] : '00'

        let startDate = new Date(
            parseInt(startDateSplitted[0]),
            parseInt(startDateSplitted[1]) - 1,
            parseInt(startDateSplitted[2]),
            parseInt(startTimeSplitted[0]), parseInt(startTimeSplitted[1]), 0, 0)
        let startTime: Time = new Time(startDate.getHours(), startDate.getMinutes())

        let endDate = new Date(
            parseInt(endDateSplitted[0]),
            parseInt(endDateSplitted[1]) - 1,
            parseInt(endDateSplitted[2]),
            parseInt(endTimeSplitted[0]), parseInt(endTimeSplitted[1]), 0, 0)
        let endTime: Time = new Time(endDate.getHours(), endDate.getMinutes())

        let scheduleValue: number = Number(parseInt(weekdayHex, 16).toString(10))
        let weekdays: Weekday[] = []
        for (let w = 0; w < 7; w++) {
            let weekdayBitmask: number = Math.pow(2, w)
            let weekdayEnabled = ((weekdayBitmask & scheduleValue) === weekdayBitmask) ? true : false
            weekdays.push(new Weekday(w, weekdayEnabled))
        }

        let scheduleEnabledBitmask: number = Math.pow(2, 7)
        let scheduleEnabled: boolean = ((scheduleEnabledBitmask & scheduleValue) === scheduleEnabledBitmask) ? true : false
        let targetTemperature: number = Number(parseInt(tempHex, 16).toString(10))
        let stonesTemperature: number = Number(parseInt(stonesTempHex, 16).toString(10))
        let lidDuration: number = Number(parseInt(lidDurationHex, 16).toString(10))
        let mode: number = Number(parseInt(modeHex, 16).toString(10))

        return new Schedule(id, scheduleEnabled, startDate, endDate, startTime, endTime, weekdays, targetTemperature, stonesTemperature, lidDuration, mode, enhancedControls, advanced, true)
    }

    static validate(scheduleString: string, enhancedControls : boolean, advanced : boolean): boolean {
        if (enhancedControls || advanced) {
            let regExp = new RegExp('^\\d{4}-\\d{2}-\\d{2}\\_\\d{4}-\\d{2}-\\d{2}\\_\\d{2}\\:\\d{2}\\-\\d{2}\\:\\d{2}\\_[0-9A-Za-z]{2}_[0-9A-Za-z]{2}(_[0-9A-Za-z]{3})(_[0-9A-Za-z]{2})(_[0-9A-Za-z]{2})?$')
            return scheduleString && scheduleString.length > 0 && regExp.test(scheduleString)
        } else {
            let regExp = new RegExp('^\\d{4}-\\d{2}-\\d{2}\\_\\d{4}-\\d{2}-\\d{2}\\_\\d{2}\\:\\d{2}\\-\\d{2}\\:\\d{2}\\_[0-9A-Za-z]{2}_[0-9A-Za-z]{2}(_[0-9A-Za-z]{3})?$')
            return scheduleString && scheduleString.length > 0 && regExp.test(scheduleString)
        }
    }
}

export class Weekday {
    day: number
    enabled: boolean

    constructor(day: number, enabled: boolean) {
        this.day = day
        this.enabled = enabled
    }

    equals(other: Weekday): boolean {
        return this.day === other.day && this.enabled === other.enabled
    }
}

export class Time {
    hours: number
    minutes: number

    constructor(hours: number, minutes: number) {
        this.hours = hours
        this.minutes = minutes
    }

    equals(other: Time): boolean {
        return this.hours === other.hours && this.minutes === other.minutes
    }

    toMinutes(): number {
        return this.hours * 60 + this.minutes
    }

    toString(): string {
        return padd(this.hours) + ':' + padd(this.minutes)
    }

    static format(input: Time): string {
        return padd(input.hours) + ':' + padd(input.minutes)
    }

    static parse(input: any): Time {
        if (!input) {
            return undefined
        }
        if (input.indexOf(':') >= 0) {
            let split: string[] = input.split(':')
            if (split.length === 1) {
                let hours: number = parseInt(split[0])
                let minutes: number = 0
                if (Number.isInteger(hours) && hours >= 0 && hours < 24) {
                    return new Time(hours, minutes)
                }
            } else if (split.length === 2) {
                let hours: number = parseInt(split[0])
                let minutes: number = parseInt(split[1])
                if (Number.isInteger(hours) && Number.isInteger(minutes)
                    && hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
                    return new Time(hours, minutes)
                }
            }
        } else {
            let value: number = parseInt(input)
            if (Number.isInteger(value) && value >= 0 && value < 24) {
                return new Time(value, 0)
            }
        }
        return undefined
    }
}

export function padd(value: number): string {
    if (value < 10) {
        return '0' + value
    }
    return '' + value
}

