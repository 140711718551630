import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/Observable'

import { environment } from '../../environments/environment';

declare var AWS: any;
declare var AWSCognito: any;

@Injectable()
export class AwsConfig {
    public static REGION: string = environment.REGION
    public static USER_POOL_ID: string = environment.USER_POOL_ID
    public static USER_POOL_CLIENT_ID: string = environment.USER_POOL_CLIENT_ID
    public static IDENTITY_POOL_ID: string = environment.IDENTITY_POOL_ID
    public static COGNITO_LOGIN_ID: string = environment.COGNITO_LOGIN_ID
    public static IOT_ADDRESS:string = environment.IOT_ADDRESS
    public static API_GATEWAY_INVOKE_URL: string = environment.API_GATEWAY_INVOKE_URL
}
