import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { AuthService } from '../auth/auth.service'
import { MessageService } from '../../messages/message.service'

@Component({
    selector: 'app-verify',
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
    public username: string
    public verificationCode: string
    public verifyType: string

    constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private messageService: MessageService) {
    }

    ngOnInit() {
        let email = this.route.snapshot.queryParams['email']
        this.username = email ? email : this.authService.email
        let verifyType = this.route.snapshot.queryParams['verifyType']
        this.verifyType = verifyType ? verifyType : "registration"
    }

    public verify() {
        if (!this.username || this.username.length < 2 || !this.verificationCode || this.verificationCode.length < 2) {
            return
        }

        switch (this.verifyType) {
            case "changeEmail":
                this.authService.confirmEmailModification(this.verificationCode)
                    .then((result) => {
                        this.router.navigate(['profile'])
                    })
                    .catch((error) => {
                        this.messageService.warning('verify.check_verification_code_failed')
                    })
                break;
        
            default:
                this.authService.confirmRegistration(this.username, this.verificationCode)
                    .then((result) => {
                        this.router.navigate(['login'], { queryParams: { email: this.username } })
                    })
                    .catch((error) => {
                        this.messageService.warning('verify.check_verification_code_failed')
                    })
                break;
        }

    }
}
